import _ from 'lodash';

(() => {
  angular
    .module('finance')
    .controller('finance.views.myInvois', MyInvoisController);

  MyInvoisController.$inject = [
    '$scope',
    'moment',
    '$state',
    '$stateParams',
    '$timeout',
    '$uibModal',
    'financeConstants',
    'abp.services.finance.commonLookup',
    'abp.services.finance.settings',
    'abp.services.finance.myInvois',
    'Hms.Finance.MyInvoisDocumentStatus',
    'abp.services.app.exportToExcel',
  ];

  function MyInvoisController(
    $scope,
    moment,
    $state,
    $stateParams,
    $timeout,
    $uibModal,
    financeConsts,
    commonLookupSvc,
    settingSvc,
    myInvoisSvc,
    enumMyInvoisDocumentStatus,
    exportToExcelSvc
  ) {
    const vm = this;

    vm.loading = 0;
    vm.enqueuing = 0;
    vm.dataExportRecordName = 'MyInvoisListing';
    vm.generateButtonType = App.localize('ExportToExcel');
    vm.lastSyncTime = 0;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.dateRangeModel = new DateRangeModel(
      $stateParams.startDate,
      $stateParams.endDate,
      getDocuments
    );
    vm.creationDateRangeModel = new DateRangeModel(
      $stateParams.creationStartDate,
      $stateParams.creationEndDate,
      getDocuments
    );
    vm.requestParams = new RequestParamsModel($stateParams);

    vm.enums = {
      status: enumMyInvoisDocumentStatus,
    };

    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSizes: financeConsts.grid.defaultPageSizes,
      paginationPageSize: financeConsts.grid.defaultPageSize,
      paginationCurrentPage: 0,
      useExternalPagination: true,
      useExternalSorting: true,
      columnDefs: getGridOptionsColumnDefs(),
      onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, (scope, callback) => {
          vm.requestParams.sorting =
            callback.length && callback[0].field
              ? `${callback[0].field} ${callback[0].sort.direction}`
              : null;
        });
        gridApi.pagination.on.paginationChanged(
          $scope,
          App.setPagination(vm.requestParams, getDocuments)
        );
      },
      data: [],
    };

    vm.resetParams = resetParams;
    vm.getDocuments = getDocuments;
    vm.localizeDocumentStatus = localizeDocumentStatus;
    vm.getDateTimeString = getDateTimeString;
    vm.getSettings = getSettings;
    vm.viewPayload = viewPayload;
    vm.getGridOptionsColumnDefs = getGridOptionsColumnDefs;
    vm.exportToExcel = exportToExcel;
    vm.getDateString = getDateString;

    init();

    // Init function.

    function init() {
      resetParams();
      getSettings();
      getAccountOwners();
      localizeDocumentStatus();
    }

    function getSettings() {
      vm.loading += 1;
      commonLookupSvc
        .getCommonSettings()
        .success((data) => {
          // Check if user has MyInvois enabled.

          vm.hasMyInvoisIntegration = data.myInvoisIntegration;

          if (vm.hasMyInvoisIntegration) {
            $timeout(() => {
              vm.gridOptions.paginationCurrentPage = Math.floor(
                vm.requestParams.skipCount / vm.requestParams.maxResultCount + 1
              );
            });
          } else {
            abp.message.warn('Cannot load because MyInvois integration is not enabled.');
          }

          // Check if user has MyInvois sandbox enabled and has permissions to access.

          vm.hasMyInvoisSubmission = data.myInvoisSubmission 
          && abp.auth.isGranted('HealthMetrics.Finance.Settings');
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getAccountOwners() {
      commonLookupSvc.getAccountOwners().success((data) => {
        if (data) {
          // Limit to Malaysian account owners only

          vm.accountOwners = data.items.filter((item) => item.countryCode === 'MY');
        }
      });
    }

    function localizeDocumentStatus() {
      _.forEach(vm.enums.status, (status) => {
        status.displayName = App.localize(status.name);
      });
    }

    function getDateTimeString(date, timeZoneId) {
      return App.getDateTimeString(date, timeZoneId);
    }

    function getDocuments() {
      registerStateParams();
      const input = vm.requestParams.toInputDto();

      vm.loading += 1;
      myInvoisSvc
        .getDocuments(input)
        .success((data) => {
          vm.gridOptions.totalItems = data.totalCount;
          vm.gridOptions.data = data.items;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function registerStateParams() {
      $state.transitionTo($state.current, vm.requestParams.toStateParams(), {
        notify: false,
      });
    }

    function viewPayload(transactionNumber) {
      const document = _.find(
        vm.gridOptions.data,
        (x) => x.internalId && x.internalId === transactionNumber
      );

      const responseBody = JSON.parse(document.documentJson);

      $uibModal.open({
        templateUrl: require('./viewPayloadModal.html'),
        controller: 'modules.finance.views.myInvois.viewPayloadModal as vm',
        backdrop: 'static',
        resolve: {
          responseBody: () => responseBody,
        }
      });
    }

    function resetParams() {
      vm.creationDateRangeModel = {
        startDate: null,
        endDate: null
      };
    }

    /* Models */

    function DateRangeModel(startDate, endDate, eventCallback) {
      this.options = App.createDateRangePickerOptions();
      this.startDate = startDate ? moment(startDate) : moment([moment().year(), 0, 1]);
      this.endDate = endDate ? moment(endDate) : moment(this.options.maxDate);
      this.options.eventHandlers = {
        'apply.daterangepicker': () => {
          eventCallback();
        },
      };
    }

    function RequestParamsModel(params) {
      this.accountOwnerId = params.accountOwnerId || null;
      this.transactionNumber = params.transactionNumber || null;
      this.status = params.status || null;

      this.skipCount = params.skipCount || 0;
      this.maxResultCount = params.maxResultCount || financeConsts.grid.defaultPageSize;
      this.sorting = params.sorting;

      this.pageNo = this.skipCount / this.maxResultCount + 1;
      this.pageSize = this.maxResultCount;

      this.toInputDto = () => {
        const input = $.extend({}, this);

        if (vm.dateRangeModel.startDate)
          input.startDate = moment(vm.dateRangeModel.startDate).toJSON();
        if (vm.dateRangeModel.endDate) 
          input.endDate = moment(vm.dateRangeModel.endDate).toJSON();
        if (vm.creationDateRangeModel.startDate)
          input.creationStartDate = moment(vm.creationDateRangeModel.startDate).toJSON();
        if (vm.creationDateRangeModel.endDate)
          input.creationEndDate = moment(vm.creationDateRangeModel.endDate).toJSON();

        return input;
      };

      this.toStateParams = () => {
        const startDate = vm.dateRangeModel.startDate
          ? vm.dateRangeModel.startDate.format('YYYY-MM-DD')
          : null;
        const endDate = vm.dateRangeModel.endDate
          ? vm.dateRangeModel.endDate.format('YYYY-MM-DD')
          : null;
        const creationStartDate = vm.creationDateRangeModel.startDate
          ? vm.creationDateRangeModel.startDate.format('YYYY-MM-DD')
          : null;
        const creationEndDate = vm.creationDateRangeModel.endDate
          ? vm.creationDateRangeModel.endDate.format('YYYY-MM-DD')
          : null;

        const stateParams = {
          accountOwnerId: this.accountOwnerId,
          transactionNumber: this.transactionNumber,
          startDate,
          endDate,
          creationStartDate,
          creationEndDate,
          skipCount: this.skipCount,
          maxResultCount: this.maxResultCount,
          sorting: this.sorting,
          status: this.status,
          pageNo: vm.gridOptions.paginationCurrentPage,
          pageSize: vm.gridOptions.paginationPageSize,
        };
        return stateParams;
      };
    }

    function exportToExcel() {
      const input = vm.requestParams.toInputDto();
      
      vm.enqueuing += 1;
      exportToExcelSvc
        .enqueueGenerateMyInvoisBatchExcelJob(input)
        .success(() => {
          vm.refresh();
        })
        .finally(() => {
          vm.enqueuing -= 1;
        });
    }

    function getDateString(date) {
      return App.getDateString(moment.utc(date));
    }

    function getGridOptionsColumnDefs() {
      const columnDefs = [
        {
          name: App.localize('Actions'),
          enableSorting: false,
          width: 100,
          headerCellTemplate: '<span></span>',
          cellTemplate: 'actionTemplate',
        },
        {
          displayName: App.localize('TransactionNumber'),
          enableSorting: false,
          field: 'internalId',
          cellTemplate: 'transactionNumberTemplate',
          minWidth: 100
        },
        {
          displayName: App.localize('SubmissionId'),
          enableSorting: false,
          field: 'submissionUid',
          minWidth: 100
        },
        {
          displayName: App.localize('DocumentId'),
          enableSorting: false,
          field: 'uuid',
          minWidth: 100
        },
        {
          displayName: App.localize('AccountOwner'),
          enableSorting: false,
          field: 'accountOwner',
          minWidth: 100
        },
        {
          displayName: App.localize('Status'),
          enableSorting: false,
          field: 'status',
          minWidth: 100,
          cellTemplate: 'statusTemplate'
        },
        {
          displayName: App.localize('Amount'),
          enableSorting: false,
          field: 'totalSales',
          cellFilter: 'currencyFormat: row.entity.currencyCode',
          minWidth: 100
        },
        {
          displayName: App.localize('CreationTime'),
          enableSorting: false,
          field: 'creationTimeOffset',
          cellTemplate: 'creationTimeTemplate',
          minWidth: 100,
        },
        {
          displayName: App.localize('TransactionDate'),
          enableSorting: false,
          field: 'transactionLocalDate',
          minWidth: 100,
          cellTemplate: 'transactionDateTemplate',
        },
        {
          displayName: App.localize('ValidationDate'),
          enableSorting: false,
          field: 'dateTimeValidated',
          minWidth: 100,
          cellTemplate: 'validationDateTemplate',
        }
      ];

      return columnDefs;
    }
  }
})();
