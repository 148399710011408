import swal from 'sweetalert';

(() => {
  angular.module('app').component('revenueSubscriptionsDetailReportsComponent', {
    templateUrl: require('./reports.component.html'),
    controller: ReportsController,
    controllerAs: 'vm',
    bindings: {
      subscription: '<',
    },
  });

  ReportsController.$inject = ['abp.services.revenue.subscription','$state'];

  function ReportsController(subscriptionSvc, $state) {
    const vm = this;

    vm.reports = [];
    vm.loading = 0;
    vm.selectedTab = 1;

    vm.permissions = {
      regenerateReports: abp.auth.isGranted('Hms.Revenue.Corporate.ManageSubscriptions'),
    };

    vm.downloadReport = downloadReport;
    vm.tabSelected = tabSelected;
    vm.regenerateSubscriptionReports = regenerateSubscriptionReports;

    init();

    function init() {
      getReports();
    }

    function getReports() {
      vm.loading += 1;
      subscriptionSvc
        .getReports({
          id: vm.subscription.id,
        })
        .success((data) => {
          vm.headcountChargesReports = data.headcountChargesReports;
          vm.movementChargesReports = data.movementChargesReports;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function downloadReport(report) {
      window.open(report, '_blank');
    }

    function tabSelected(i) {
      vm.selectedTab = i;
    }

    function regenerateSubscriptionReports() {
      swal(
        {
          title: App.localize('RegenerateSubscriptionReports'),
          text: App.localize('RegenerateReportsWarningMessage'),
          type: 'warning',
          confirmButtonColor: '#1ab394',
          confirmButtonText: App.localize('Confirm'),
          showCancelButton: true,
          closeOnConfirm: false,
        },
        (isConfirm) => {
          if (isConfirm) {
            vm.loading += 1;
            subscriptionSvc
              .regenerateReports({ subscriptionId: vm.subscription.id })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullySaved'));
              })
              .finally(() => {
                vm.loading -= 1;
                swal.close();
                $state.go('revenue.corporates', {
                  corporateId: vm.subscription.corporateId,
                  section: 'newSubscriptions',
                });
              });
          }
          swal.close();
        }
      );
    }
  }
})();
