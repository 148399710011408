(() => {
  angular
    .module('app')
    .controller('common.views.postHospitalizationClaims.details', ClaimDetailsPageController);

  ClaimDetailsPageController.$inject = [
    '$stateParams',
    'moment',
    'abp.services.app.postHospitalizationClaim',
  ];

  function ClaimDetailsPageController($stateParams, moment, postHospitalizationClaimSvc) {
    const vm = this;
    vm.loading = 0;
    vm.claimNumber = $stateParams.claimNumber;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.glNumber = $stateParams.glNumber;
    vm.newRepliedQueriesCount = 0;

    vm.isPanel = App.isClinic();

    vm.claim = {
      claimNumber: vm.claimNumber,
      timeZoneId: vm.timeZoneId,
      glNumber: vm.glNumber,
    };

    vm.permissions = {
      editClaim: vm.isHost
        ? abp.auth.isGranted('SpecialistClaims.Host.Edit')
        : abp.auth.isGranted('SpecialistClaims.Clinic.Edit'),
      approveClaim: abp.auth.isGranted('SpecialistClaims.Host.Edit'),
      viewTicket: abp.auth.isGranted('PatientTickets'),
    };

    vm.hasSpecialistWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SpecialistWorkflowEnhancement'
    );

    init();

    function init() {
      getClaim();
    }

    function getClaim() {
      vm.loading += 1;
      postHospitalizationClaimSvc
        .getPostHospitalizationClaimDetailAsync({ id: vm.claimNumber })
        .success((data) => {
          vm.claim = {
            claimNumber: data.claimNumber,
            requestType: data.latestRequestType,
            status: data.claimStatusId,
            glNumber: data.guaranteeLetterNumber,
            patientRefNo: data.hospitalBillReferenceNumber,
            finalBillAmount: data.totalBillAmount,
            finalGuaranteedAmount: data.currentGuaranteeAmount,
            exceededAmount: data.exceededAmount,
            submissionTime: App.getFullDateTimeString(data.claimDate, vm.timeZoneId),
            lastUpdated: App.getFullDateTimeString(data.lastModificationTime, vm.timeZoneId),
            guaranteeLetterUrl: data.guaranteeLetterUrl,
            admissionGuaranteeLetterUrl: data.admissionGuaranteeLetterUrl,
            documents: data.attachments,
            guaranteeLetterStatus: data.guaranteeLetterStatus,
            patientNonGlUrl: data.patientNonGlUrl,
            hospitalNonGlUrl: data.hospitalNonGlUrl,
            isEscalated: data.isEscalated,
            timeZoneId: data.ianaTimezoneId,
            claimRemarks: data.claimRemarks,
            ticketNumber: data.ticketNumber,
            canPickUpRequest: data.canPickUpRequest,
            isAssignee: data.isAssignee,
            canRequestAdjustment: data.canRequestAdjustment
          };

          vm.patient = {
            name: data.patientName,
            nationalId: data.nationalId,
            gender: data.gender,
            birthDate: data.dateOfBirth,
            joinDate: data.joinDate ? App.getDateString(moment.utc(data.joinDate)) : null,
            scheduledDeactivationDate: data.deactivationDate
              ? App.getDateString(moment.utc(data.deactivationDate))
              : null,

            patientActivationStatus: data.patientActivationStatus,
            patientDeletionStatus: data.patientDeletionStatus,
            patientTerminationStatus: data.patientTerminationStatus,
            specialNotes: data.specialNotes,
            age: data.patientAge,
            isActive: data.isActive,
            corporateName: data.corporateName,
          };

          vm.currencyCode = data.currencyCode;
          vm.letterNumber = data.guaranteeLetterNumber;
          vm.serviceType = data.serviceType;

          vm.admissionRecord = {
            guaranteeLetterNumber: data.admissionGLNumber,
            claimNumber: data.admissionClaimNumber,
            ticketNumber: data.admissionTicketNumber,
            diagnoses: data.finalDiagnosis,
            panelName: data.panelLocationName,
            doctors: data.doctorNames,
            admissionDate: data.admissionDate,
            dischargeDate: data.dischargeDate,
          };
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
