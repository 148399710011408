(() => {
  angular
    .module('app')
    .component('commonInpatientTicketsDetailEmergencyOutpatientTreatmentComponent', {
      templateUrl: require('./emergencyOutpatientTreatment.component.html'),
      controller: EmergencyOutpatientTreatmentController,
      controllerAs: 'vm',
      bindings: {
        ticket: '<',
        isEdit: '<',
        isSaved: '<',
        categories: '<',
        isCreate: '<',
        currencyCode: '<',
      },
    });

  EmergencyOutpatientTreatmentController.$inject = [];

  function EmergencyOutpatientTreatmentController() {
    const vm = this;

    vm.formattedCategories = [];
    vm.tempPlaceHolder = null;
    vm.loadedFlag = false;

    vm.$onChanges = onChanges;
    vm.addCategory = addCategory;
    vm.updateCategory = updateCategory;
    vm.updateSubCategory = updateSubCategory;
    vm.addTicketItem = addTicketItem;
    vm.removeTicketItem = removeTicketItem;
    vm.clearAllTicketItems = clearAllTicketItems;
    vm.manualEdit = manualEdit;
    vm.cancelManualEdit = cancelManualEdit;
    vm.saveManualEdit = saveManualEdit;
    vm.calculateAmount = calculateAmount;
    vm.calculateTotalAmount = calculateTotalAmount;
    vm.getAmount = getAmount;
    vm.getSubtotalAmount = getSubtotalAmount;
    vm.getTotalCoveredAmount = getTotalCoveredAmount;
    vm.getTotalUncoveredAmount = getTotalUncoveredAmount;

    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );

    const defaultTicketItem = {
      mainTreatment: '',
      isManualEdit: 0,
      ticketItems: [
        {
          amount: 0,
          uncoveredAmount: 0,
          totalCharges: 0,
          mainTreatment: '',
          category: '',
          subTreatment: '',
          procedure: '',
          section: '',
          uncoveredProcedure: '',
          remark: '',
          uncoveredRemark: '',
          subTreatments: [],
          procedures: [],
        },
      ],
    };

    init();
    function init() {
      // Initialise ticket items.

      if (!vm.ticket.emergencyOutpatientTreatments) {
        vm.ticket.emergencyOutpatientTreatments = [];
      }

      vm.ticket.emergencyOutpatientTreatments = _.chain(vm.ticket.emergencyOutpatientTreatments)
        .groupBy('category')
        .map((value, key) => ({
          category: key,
          ticketItems: value,
          name: _.first(value).categoryName,
          isManualEdit: 0,
        }))
        .value();

      if (vm.isCreate && vm.ticket.emergencyOutpatientTreatments.length < 1) {
        addCategory();
      }

      if (vm.hasInsuranceEnhancementModule) formatCategories();

      refreshOnTicketChange();

      // Map the filtered sub-categories and its procedures during edit

      if (vm.ticket.emergencyOutpatientTreatments.length > 0) {
        vm.ticket.emergencyOutpatientTreatments.forEach((x, i) => updateCategory(i, x));
      }
    }

    function refreshOnTicketChange() {
      if (!vm.ticket || !vm.ticket.emergencyOutpatientTreatments) return;
      if (vm.ticket.emergencyOutpatientTreatments.length === 0) {
        if (vm.isedit) addCategory();
      } else {
        _.each(vm.ticket.emergencyOutpatientTreatments, (x) => {
          _.each(x.ticketItems, (ticketItem) => {
            const ti = ticketItem;
            if (ti.amount !== undefined && ti.uncoveredAmount !== undefined)
              ti.totalCharges = ti.amount + ti.uncoveredAmount;
            ti.subTreatment = ti.subCategory;
            ti.isOverride = 0;
          });
        });
      }
    }

    function onChanges(changes) {
      if (changes.ticket && changes.ticket.currentValue) {
        refreshOnTicketChange();
      }

      // Format categories.

      if (!vm.loadedFlag) {
        formatCategories();

        vm.loadedFlag = true;
      }
    }

    function addCategory() {
      vm.ticket.emergencyOutpatientTreatments.push(JSON.parse(JSON.stringify(defaultTicketItem)));
    }

    function updateCategory(index, item) {
      if (item.category) {
        // Clear sub categories & procedures.

        _.forEach(item.ticketItems, (x) => {
          x.subTreatment = x.subCategory ?? '';
          x.procedure = x.procedure ?? '';
          x.uncoveredProcedure = x.uncoveredProcedure ?? '';
          x.subTreatments = [];
          x.procedures = [];
        });

        // Update each ticketItem in item.

        const category = _.filter(
          vm.formattedCategories,
          (f) => f.mainTreatmentCategoryName === item.category
        );

        _.forEach(item.ticketItems, (x) => {
          x.category = item.category;
          x.section = category[0].sectionType;

          // Add sub treatments items.

          x.subTreatments = category[0].subTreatmentCategories.map((s) => s.subTreatmentItemName);

          // Add procedures

          const selectedSubTreatmentCategory = category[0].subTreatmentCategories.find(
            (s) => s.subTreatmentItemName === x.subCategory
          );
          if (selectedSubTreatmentCategory) {
            x.procedures = selectedSubTreatmentCategory.procedures ?? [];
          } else if (x.subTreatments?.length) {
            // When selected main treatment category don't have sub-treatment category

            x.procedures = category[0].subTreatmentCategories.flatMap((s) => s.procedures);
          }
        });
      } else {
        clearAllTicketItems(item);
      }
    }

    function updateSubCategory(index, item, ticketItem) {
      // Clear procedures.

      ticketItem.procedure = '';
      ticketItem.uncoveredProcedure = '';
      ticketItem.procedures = [];

      // Add procedure items.

      const category = _.filter(
        vm.formattedCategories,
        (f) => f.mainTreatmentCategoryName === item.category
      );

      const subTreatment = _.filter(
        category[0].subTreatmentCategories,
        (s) => s.subTreatmentItemName === ticketItem.subTreatment
      );

      ticketItem.procedures = subTreatment[0]?.procedures ?? [];
    }

    function addTicketItem(item) {
      const newTicketItem = JSON.parse(JSON.stringify(defaultTicketItem.ticketItems[0]));

      // Format new ticket item.

      newTicketItem.category = item.category;

      // Add sub treatments items.

      if (item.category) {
        const mainTreatment = _.filter(
          vm.formattedCategories,
          (f) => f.mainTreatmentCategoryName === item.category
        );

        newTicketItem.subTreatments = mainTreatment[0].subTreatmentCategories.map(
          (s) => s.subTreatmentItemName
        );
        newTicketItem.section = mainTreatment[0].sectionType;
      }

      // Add new ticket item.

      item.ticketItems.push(newTicketItem);
    }

    function removeTicketItem(index, item) {
      if (index) {
        item.ticketItems.splice(index, 1);
      } else {
        clearAllTicketItems(item);
      }
    }

    function clearAllTicketItems(item) {
      item.ticketItems = [];
      addTicketItem(item);
    }

    function manualEdit(index) {
      vm.tempPlaceHolder = angular.copy(vm.ticket.emergencyOutpatientTreatments[index]);
      vm.ticket.emergencyOutpatientTreatments[index].isManualEdit = 1;
    }

    function cancelManualEdit(index) {
      vm.ticket.emergencyOutpatientTreatments[index] = angular.copy(vm.tempPlaceHolder);
      vm.ticket.emergencyOutpatientTreatments[index].isManualEdit = 0;
      vm.tempPlaceHolder = null;
    }

    function saveManualEdit(index) {
      vm.ticket.emergencyOutpatientTreatments[index].isManualEdit = 0;
      vm.tempPlaceHolder = null;

      _.each(vm.ticket.emergencyOutpatientTreatments[index].ticketItems, (ticketItem) => {
        const p = ticketItem;
        p.isOverride = 1;
      });
    }

    function calculateAmount(index, item) {
      let amount = item.totalCharges;
      amount = amount < 0 ? 0 : amount;
      item.amount = amount || 0;

      let uncoveredAmount = item.totalCharges - item.amount;
      uncoveredAmount = uncoveredAmount < 0 ? 0 : uncoveredAmount;
      item.uncoveredAmount = uncoveredAmount || 0;
    }

    function calculateTotalAmount(index, item) {
      let totalCharges = item.amount + item.uncoveredAmount;
      totalCharges = totalCharges < 0 ? 0 : totalCharges;
      item.totalCharges = totalCharges || 0;
    }

    function getAmount() {
      let amount = 0;
      let uncoveredAmount = 0;

      _.each(vm.ticket.emergencyOutpatientTreatments, (item) => {
        _.each(item.ticketItems, (ticketItem) => {
          amount += ticketItem.amount;
          uncoveredAmount += ticketItem.uncoveredAmount;
        });
      });

      return {
        subtotalAmount: App.roundAmount(amount + uncoveredAmount),
        totalCoveredAmount: App.roundAmount(amount),
        totalUncoveredAmount: App.roundAmount(uncoveredAmount),
      };
    }

    function formatCategories() {
      // Get main categories.

      _.forEach(vm.categories, (x) => {
        if (
          vm.formattedCategories
            .map((e) => e.mainTreatmentCategoryName)
            .indexOf(x.mainTreatmentCategoryName) < 0
        ) {
          vm.formattedCategories.push({
            mainTreatmentCategoryName: x.mainTreatmentCategoryName,
            sectionType: x.sectionType,
            subTreatmentCategories: [],
          });
        }
      });

      // Get sub categories.

      _.forEach(vm.formattedCategories, (x) => {
        const subTreatments = _.filter(
          vm.categories,
          (c) => c.mainTreatmentCategoryName === x.mainTreatmentCategoryName
        );

        _.forEach(subTreatments, (s) => {
          const keyValue = !s.subTreatmentItemName
            ? x.mainTreatmentCategoryName
            : s.subTreatmentItemName;
          const hasDuplicated = x.subTreatmentCategories.some(
            (subTreatmentCategory) => subTreatmentCategory.subTreatmentItemName === keyValue
          );

          if (!hasDuplicated) {
            x.subTreatmentCategories.push({
              subTreatmentItemName: keyValue,
              procedures: [],
            });
          }
        });
      });

      // Get procedures.

      _.forEach(vm.formattedCategories, (x) => {
        _.forEach(x.subTreatmentCategories, (s) => {
          const filteredMainTreatmentCategory = _.filter(vm.categories, (c) =>
            c.subTreatmentItemName
              ? c.subTreatmentItemName === s.subTreatmentItemName
              : c.mainTreatmentCategoryName === s.subTreatmentItemName
          );

          _.forEach(filteredMainTreatmentCategory, (mainCategory) => {
            const foundDuplicated = s.procedures.some((p) => p === mainCategory.procedures);
            if (mainCategory.procedures && !foundDuplicated) {
              s.procedures.push(mainCategory.procedures);
            }
          });
        });
      });
    }

    function getSubtotalAmount() {
      return getAmount().subtotalAmount;
    }

    function getTotalCoveredAmount() {
      return getAmount().totalCoveredAmount;
    }

    function getTotalUncoveredAmount() {
      return getAmount().totalUncoveredAmount;
    }
  }
})();
