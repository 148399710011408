(() => {
  angular.module('app').component('commonLayoutNavigation', {
    template: require('./navigation.eager.html').default,
    controller: ControllerController,
    controllerAs: 'vm',
    bindings: {},
  });

  ControllerController.$inject = [
    'authentication',
    'appSession',
    '$state',
    '$uibModal',
    '$timeout',
    'videoModal',
    'abp.services.app.commonLookup',
    'Hms.MultiTenancy.TenantClinicType',
    'abp.services.app.tenantSettings',
  ];

  function ControllerController(
    authentication,
    appSession,
    $state,
    $uibModal,
    $timeout,
    videoModal,
    commonLookupSvc,
    enumTenantClinicType,
    tenantSettingsSvc,
  ) {
    const vm = this;

    vm.hasDebugTools = abp.auth.isGranted('Host.DebugTools');
    vm.hasMailingModule = abp.auth.isGranted('HealthMetrics.Mailing');
    vm.isClinic = App.isClinic();
    vm.isSpecialist = vm.isClinic && App.isSpecialist();
    vm.isHospital = App.isHospital();
    vm.isCorporate = App.isCorporate();
    vm.isImpersonatedLogin = abp.session.impersonatorUserId || authentication.isImpersonatedLogin();
    vm.userFullName = null;
    vm.userBgColor = `user_bgcolor_${((abp.session.userId || 0) % 8) + 1}`;
    const hasInpatientFlag = abp.setting.getBoolean('Hms.Feature.InpatientModule');
    vm.isInpatient = App.isInpatient();
    const hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.isHealthScreening = App.isHealthScreening();
    vm.isGP = App.isGPPanel();
    vm.panelType = null;
    vm.isHost = App.isHost();
    const hasFinanceService = abp.setting.getBoolean('Hms.Feature.FinanceService');
    vm.hasSmsService = abp.setting.getBoolean('Hms.Feature.SmsService');
    vm.hasSecurityEnhancement1 = abp.setting.getBoolean('Hms.Feature.SecurityEnhancement1');

    const hasAccessFinanceTransactionData =
      App.isHost() || abp.setting.getBoolean('Hms.Feature.AccessFinanceTransactionData');
    vm.enumTenantClinicType = enumTenantClinicType;

    // Get support links, emails, phone numbers

    vm.gpManualUrl = abp.setting.get('Hms.General.GpManualUrl');
    vm.dentalManualUrl = abp.setting.get('Hms.General.DentalManualUrl');
    vm.opticalManualUrl = abp.setting.get('Hms.General.OpticalManualUrl');
    vm.corporateManualUrl = abp.setting.get('Hms.General.CorporateManualUrl');
    vm.specialistManualUrl = abp.setting.get('Hms.General.SpecialistManualUrl');
    vm.inpatientManualUrl = abp.setting.get('Hms.General.InpatientManualUrl');
    vm.memberOnboardingManualUrl = abp.setting.get('Hms.General.MemberOnboardingManualUrl');
    vm.gpManualBMUrl = abp.setting.get('Hms.General.GpManualBMUrl');
    vm.dentalManualBMUrl = abp.setting.get('Hms.General.DentalManualBMUrl');
    vm.opticalManualBMUrl = abp.setting.get('Hms.General.OpticalManualBMUrl');

    vm.checkActiveState = checkActiveState;
    vm.playVideo = playVideo;
    vm.changePassword = changePassword;
    vm.manageMyAccount = manageMyAccount;
    vm.changePicture = changePicture;
    vm.backToMyAccount = backToMyAccount;
    vm.toggleCollapsible = toggleCollapsible;
    vm.logout = logout;
    vm.openMemberOnboardingGuide = openMemberOnboardingGuide;

    init();

    function init() {
      initializeNavigationBar();
      if (vm.isHospital || vm.isClinic) getPanelInfoFormLink();
    }

    function initializeNavigationBar() {
      if (!appSession.user) return;
      vm.userFullName = appSession.user.name;
      // Only show new hospital menu if hospital has inpatient(maternity/hospitalization) service.

      const menu =
        (vm.isInpatient && hasInpatientFlag) || vm.isHealthScreening
          ? abp.nav.menus.HospitalMenu
          : abp.nav.menus.MainMenu;

      // To check if current clinic location has outpatient or inpatient services.

      if (
        appSession.user.clinicLocationId !== null &&
        appSession.user &&
        appSession.user.panelLocationServiceTypes &&
        appSession.user.panelLocationServiceTypes.length > 0
      ) {
        vm.hasInpatientService = _.some(
          appSession.user.panelLocationServiceTypes,
          (type) => type === 7 || type === 8
        );

        vm.hasOutpatientSpecialistService = _.includes(
          appSession.user.panelLocationServiceTypes,
          5
        );
        vm.hasHealthScreeningService = _.includes(appSession.user.panelLocationServiceTypes, 9);
      }
      // Else if current user's default clinic location ID is null (admin),
      // check panel location service types by tenant.
      else if (
        appSession.user.clinicLocationId === null &&
        appSession.tenant &&
        appSession.tenant.tenantServiceTypes &&
        appSession.tenant.tenantServiceTypes.length > 0
      ) {
        vm.hasInpatientService = _.some(
          appSession.tenant.tenantServiceTypes,
          (type) => type === 7 || type === 8
        );

        vm.hasOutpatientSpecialistService = _.includes(appSession.tenant.tenantServiceTypes, 5);
        vm.hasHealthScreeningService = _.includes(appSession.tenant.tenantServiceTypes, 9);
      }

      // To remove healthscreening or specialist or inpatient menu item
      // if user default panel location doesn't have the service.

      if ((vm.isInpatient && hasInpatientFlag) || vm.isHealthScreening) {
        // Checking for outpatient service.

        if (!vm.hasOutpatientSpecialistService) {
          // Remove if user default panel location no outpatient service.

          _.remove(menu.items, (item) => item.name === 'clinic.outpatientSpecialist');
        }

        if (!vm.hasInpatientService) {
          // Remove if user default panel location no inpatient service.

          _.remove(menu.items, (item) => item.name === 'clinic.inpatient');
        }

        if (!vm.hasHealthScreeningService) {
          // Remove if user default panel location no health screening service.

          _.remove(menu.items, (item) => item.name === 'clinic.healthScreening');
        }
      }

      // Remove account owner menu if regional system support feature is not turned on.

      if (!hasRegionalSystemSupport) {
        _.each(menu.items, (item) => {
          if (item.name === 'administration') {
            _.remove(item.items, (d) => d.name === 'accountOwners');
          }
        });
      }

      // Remove finance menu if finance service is not turned on.

      if (!hasFinanceService || !hasAccessFinanceTransactionData) {
        _.remove(
          menu.items,
          (item) => item.name === 'finance' || item.name === 'finance.saleTransactions'
        );
      }

      // For all non host, replace the old sale transaction tab with the new finance sale
      // transaction tab (Hospital tenant only).

      if (!vm.isHost && hasFinanceService && hasAccessFinanceTransactionData && vm.isHospital) {
        _.remove(
          _.first(_.filter(menu.items, (item) => item.name === 'general'))?.items,
          (item) => item.name === 'saleTransactions'
        );
      }

      // For all non host, replace the old sale transaction tab with the new finance sale
      // transaction tab (Other non host tenants).

      if (!vm.isHost && hasFinanceService && hasAccessFinanceTransactionData) {
        _.remove(menu.items, (item) => item.name === 'finance' || item.name === 'saleTransactions');
      }

      // For host, remove the extra tab.

      if (vm.isHost && hasFinanceService && hasAccessFinanceTransactionData) {
        _.remove(menu.items, (item) => item.name === 'finance.saleTransactions');
      }

      // Setup menu for SMS service when feature flag is turned on.

      if (vm.hasSmsService && vm.isHost) {
        _.each(menu.items, (item) => {
          if (item.name === 'administration') {
            _.remove(item.items, (d) => d.name === 'host.smsLogs');
          }
        });
      } else {
        _.remove(menu.items, (item) => item.name === 'sms');
      }

      vm.menuItems = _.chain(menu.items)
        .forEach((menu1) => {
          const m = menu1;
          if (m.items && m.items.length) {
            m.hasChildren = 1;
            m.items = _.filter(m.items, (menuItem) => {
              const mi = menuItem;
              if (mi.items && mi.items.length) {
                mi.items = _.filter(
                  mi.items,
                  (si) => $state.get(si.name) || si.name === 'printFormAB'
                );
                return mi.items;
              }
              return $state.get(mi.name);
            });
          }
        })
        .filter((m) => (m.hasChildren && m.items.length) || $state.get(m.name))
        .value();

      $timeout(() => {
        if ($('body').hasClass('fixed-sidebar')) {
          $('.sidebar-collapse').slimScroll({
            height: '100%',
            railOpacity: 0.9,
          });
        }
      });

      vm.userInitial = getUserInitial();
      vm.tenantName = getTenantName();

      if (appSession.tenant) {
        vm.tenantCode = appSession.tenant.tenancyName;
      }

      processMenuCustomData();
      initVideos();

      if (vm.isCorporate || vm.isClinic) {
        commonLookupSvc.getAccountOwnerContactNumber().success((data) => {
          vm.accountOwnerContactNumber = data;
          vm.accountOwnerContactNumberClean = vm.accountOwnerContactNumber
            .replace(/\s/g, '')
            .replace('-', '');
        });
      }

      if (vm.isCorporate || vm.isClinic) {
        commonLookupSvc.getSupportEmailSettings().success((data) => {
          vm.getSupportEmailSettings = data;
          vm.panelType = Number(abp.features.getValue('ClinicModule.ClinicType'));
          getAccountOwnerSupportEmail(vm.getSupportEmailSettings, vm.panelType);
        });
      }
    }

    /* Functions */

    function checkActiveState(state) {
      if (state === 'corporate.insurances') return $state.includes('corporate.dashboard');

      return $state.includes(state);
    }

    function processMenuCustomData() {
      _.each(vm.menuItems, (item) => {
        const i = item;

        // Rename Patient Tickets to Claims for panels.
        // Feature dependency for host is not working in HmsNavigationProvider.

        if (i.name === 'patientTickets' && vm.isClinic) {
          i.displayName = App.localize('Claims');
        }
        mapLabelIcon(i);
      });

      function mapLabelIcon(menuItem) {
        const mi = menuItem;
        const key = 'LabelIcon.';
        const values = {
          RocketLaunch: 'far fa-rocket-launch',
        };
        if (!_.startsWith(mi.customData, key)) return;

        mi.labelIcon = values[mi.customData.substring(key.length)];
      }
    }

    function getTenantName() {
      if (appSession.user) {
        if (appSession.user.subsidiaryId && appSession.user.subsidiaryName) {
          return appSession.user.subsidiaryName;
        }
        if (appSession.user.clinicLocationId && appSession.user.clinicLocationName) {
          return appSession.user.clinicLocationName;
        }
      }

      if (appSession.tenant) {
        return appSession.tenant.name;
      }

      return '[Hms Host]';
    }

    function getUserInitial() {
      const fullName = vm.userFullName.split(' ');
      let initial = '';
      for (let i = 0; i < fullName.length; i += 1) {
        if (fullName[i].length) {
          initial += fullName[i][0];
        }
      }
      if (initial && initial.length > 3) initial = `${initial[0]}`;
      return initial;
    }

    function playVideo(videoId) {
      videoModal.open(videoId);
    }

    function changePassword() {
      $uibModal.open({
        templateUrl: require('../profile/changePassword.html'),
        controller: 'common.views.profile.changePassword as vm',
        backdrop: 'static',
      });
    }

    function manageMyAccount() {
      $uibModal.open({
        templateUrl: require('../profile/manageMyAccount.html'),
        controller: 'common.views.profile.manageMyAccount as vm',
        backdrop: 'static',
      });
    }

    function changePicture() {
      // TODO: Missing HTML page. Commented out for webpack to build.
      // $uibModal.open({
      //   templateUrl: require('/App/common/views/profile/changePicture.html'),
      //   controller: 'common.views.profile.changePicture as vm',
      //   backdrop: 'static'
      // });
    }

    function backToMyAccount() {
      authentication.unimpersonate();
    }

    function initVideos() {
      if (vm.isClinic) {
        vm.clinicVideos = vm.isGP ? getPanelVideosForNewCheckIn() : null;
      } else if (vm.isCorporate) vm.corporateVideos = getCorporateVideos();

      function getPanelVideosForNewCheckIn() {
        return [
          {
            title: App.localize('Video_ClaimSubmission'),
            src: 'https://www.youtube.com/embed/luubmm3pYz0',
          },
          {
            title: App.localize('Video_ViewClaims'),
            src: 'https://www.youtube.com/embed/uhDBFhZcYJw',
          },
          {
            title: App.localize('Video_DoctorsNameListing'),
            src: 'https://www.youtube.com/embed/NeopvIxvw6w',
          },
          {
            title: App.localize('Video_Transaction'),
            src: 'https://www.youtube.com/embed/9fyrMxTSjGE',
          },
          {
            title: App.localize('Video_CheckInReport'),
            src: 'https://www.youtube.com/embed/AjOzl47EXzg',
          },
          {
            title: App.localize('Video_Administration'),
            src: 'https://www.youtube.com/embed/6O97uNyX9mc',
          },
        ];
      }

      function getCorporateVideos() {
        return [
          {
            title: App.localize('Video_Dashboard'),
            src: 'https://www.youtube.com/embed/dje7pLkDMeI',
          },
          {
            title: App.localize('Video_Ticket'),
            src: 'https://www.youtube.com/embed/8m7TwV_pD1s',
          },
          {
            title: App.localize('Video_Transaction'),
            src: 'https://www.youtube.com/embed/C6_IZXkxvTc',
          },
          {
            title: App.localize('Video_BenefitGroup'),
            src: 'https://www.youtube.com/embed/R4YuOlkOzcQ',
          },
          {
            title: App.localize('Employees'),
            videos: [
              {
                title: App.localize('Video_ViewEmployeeProfile'),
                src: 'https://www.youtube.com/embed/EiMsFMAjEVE',
              },
              {
                title: App.localize('Video_CreateEmployeeProfile'),
                src: 'https://www.youtube.com/embed/65Kb0b08BK4',
              },
              {
                title: App.localize('Video_DeleteEmployeeProfile'),
                src: 'https://www.youtube.com/embed/A0gU3AcPHQU',
              },
            ],
          },
          {
            title: App.localize('Video_Department'),
            src: 'https://www.youtube.com/embed/Um3e0fTwL6M',
          },
          {
            title: App.localize('Video_PanelAccess'),
            src: 'https://www.youtube.com/embed/11OoiZ2V_24',
          },
          {
            title: App.localize('Video_Report'),
            src: 'https://www.youtube.com/embed/SaJdVWGWNio',
          },
        ];
      }
    }

    function toggleCollapsible(elementId) {
      const collapsibleElements = document.getElementsByClassName('collapsible');
      _.forEach(collapsibleElements, (element) => {
        if (!_.isEmpty(element.id) && element.id !== elementId) {
          element.setAttribute('aria-expanded', false);
        }
      });
    }

    // Get account owner support emails.

    function getAccountOwnerSupportEmail(supportEmails, panelType) {
      const emails = {
        0: supportEmails.corporateSupportEmails,
        1: supportEmails.panelGPSupportEmails,
        2: supportEmails.panelDentalSupportEmails,
        4: supportEmails.panelOpticalSupportEmails,
        5: supportEmails.panelSpecialistSupportEmails,
        6: supportEmails.panelPharmacySupportEmail,
        7: supportEmails.panelHospitalSupportEmails,
      };

      vm.supportEmail = emails[Number(panelType).toString()] ?? '';
    }

    function logout() {
      authentication.logout();
    }

    function openMemberOnboardingGuide() {
      $uibModal.open({
        templateUrl: require('../modals/memberOnboardingGuide/memberOnboardingGuideModal.html'),
        controller: 'common.views.modals.memberOnboardingGuide as vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          redirectUrl: () => vm.memberOnboardingManualUrl,
        },
      });
    }

    function getPanelInfoFormLink() {
      vm.loading += 1;
      tenantSettingsSvc
        .getPanelInfoFormLink()
        .success((data) => {
          vm.requestFormLink = data;
        })
        .finally(() => {
          vm.loading -= 1;
        })
    }

    /* End of functions */
  }
})();
