import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('finance.views.saleTransactions.invoiceDetail.page', InvoiceDetailController);

  InvoiceDetailController.$inject = [
    '$stateParams',
    'abp.services.finance.invoices',
    'Hms.Payments.PaymentMethod',
    'abp.services.app.commonLookup',
    'abp.services.app.financeCommon',
    '$state',
    'abp.services.finance.saleTransactions',
  ];

  function InvoiceDetailController(
    $stateParams,
    invoicesSvc,
    enumPaymentMethod,
    commonLookupSvc,
    portalfinanceCommonSvc,
    $state,
    saleTransactionSvc) {
    const vm = this;

    vm.loading = 0;
    vm.transactionNumber = $stateParams.transactionNumber;
    vm.invoice = null;
    vm.invoiceFrom = null;
    vm.invoiceTo = null;
    vm.message = 'Placeholder for message';
    vm.payments = [];
    vm.latestPayableAmount = null;
    vm.invoiceSaleTransactionItems = [];
    vm.currentIndex = 0;
    vm.rowNumber = 0;
    vm.serviceTypeNameDict = {};
    vm.hasTaxWorkflow = false;
    vm.commentsAndHistory = [];
    vm.paymentsReceived = [];
    vm.creditsApplied = [];
    vm.creditsAvailable = 0;
    
    vm.permissions = {
      voidTransaction: abp.auth.isGranted('HealthMetrics.Finance.SaleTransactions.VoidTransaction')
    };


    vm.sendEmail = sendEmail;
    vm.redirectToClassicView = redirectToClassicView;
    vm.getDateString = getDateString;
    vm.voidTransaction = voidTransaction;

    init();

    function init() {
      getInvoice();
    }

    function getInvoice() {
      const input = {
        invoiceNumber: vm.transactionNumber,
      };
      vm.loading += 1;
      invoicesSvc
        .getInvoice(input)
        .success((data) => {
          vm.currencyCode = data.invoice.currencyCode;
          vm.countryCode = data.invoiceFrom.countryCode;
          vm.invoice = data.invoice;
          vm.invoiceFrom = data.invoiceFrom;
          vm.invoiceTo = data.invoiceTo;
          vm.payments = data.payments;
          vm.creditMemos = data.creditMemos;
          vm.message = data.message;
          vm.isManual = data.invoice.termsAndConditionsId != null;
          vm.invoiceType = vm.isManual 
            ? 'Manual'
            : `${vm.invoice.tenantType}.${vm.invoice.category}`;

          vm.isAddressInvoiceFrom =
            vm.invoiceFrom.stringifiedAddress.replaceAll(',', '').trim() === '';
          vm.isAddressInvoiceTo = vm.invoiceTo.stringifiedAddress.replaceAll(',', '').trim() === '';
          localizePaymentListingItems(vm.payments);

          const accountOwnerId = vm.invoice.isCreditor ? vm.invoiceTo.id : vm.invoiceFrom.id;

          commonLookupSvc.getAccountOwnerById({id: accountOwnerId}).success((accOwner) => {
            vm.invoice.accountOwnerLogo = accOwner.dataUri;

            if(!vm.isManual)
              getMessage(vm.invoice, accOwner);

            if (vm.invoice.isCreditor) {
              vm.invoiceTo.contactNumber = accOwner.accountOwner.contactNumber;
            } else {
              vm.invoiceFrom.contactNumber = accOwner.accountOwner.contactNumber;
            }
          });
        })
        .finally(() => {
          vm.loading = 0;
        });
    }

    function localizePaymentListingItems(payments) {
      _.forEach(payments, (payment) => {
        const transactionType = _.find(
          enumPaymentMethod,
          (paymentMethod) => paymentMethod.id === payment.paymentMethod
        );

        payment.paymentMethod = App.localize(`PaymentMethod_${transactionType.name}`);
      });
    }

    function sendEmail() {
      portalfinanceCommonSvc
        .getEmailAddresses({
          billingAccountKey: vm.invoice.billingAccountKey,
        })
        .success((data) => {
          swal(
            {
              title: App.localize('SendEmailForX', vm.invoice.transactionNumber),
              text: App.localize('EmailAddresses'),
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              confirmButtonColor: '#1ab394',
              inputPlaceholder: App.localize('EmailAddressesNarrative'),
              inputValue: data.emailAddresses,
              showLoaderOnConfirm: true,
            },
            (inputValue) => {
              if (inputValue === false) return false;
              if (inputValue === '') {
                swal.showInputError(App.localize('InvalidEmailAddress'));
                return false;
              }
              portalfinanceCommonSvc
                .sendInvoiceEmail({
                  emailAddresses: inputValue,
                  billingAccountName: vm.invoice.billingAccountName,
                  transactionNumber: vm.invoice.transactionNumber,
                  date: vm.invoice.transactionLocalDate,
                  currencyCode: vm.invoice.currencyCode,
                  amount: vm.invoice.outstandingAmount,
                  accountOwnerId: vm.invoice.accountOwnerId
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  swal.close();
                });
              return false;
            }
          );
        });
    }

    function redirectToClassicView() {
      switch (vm.invoiceType) {
        case 'Organization.OutpatientUtilization':
        case 'Organization.InpatientUtilization':
          if (vm.countryCode === 'SG') {
            $state.go('singaporeInvoice', { transactionNumber: vm.transactionNumber });
          } else {
            $state.go('invoiceDetailV2', { transactionNumber: vm.transactionNumber });
          }
          break;
        case 'Organization.Subscription':
          $state.go('subscriptionInvoice', { transactionNumber: vm.transactionNumber });
          break;
        case 'Insurer.InpatientUtilization':
          $state.go('insurerInvoice', { transactionNumber: vm.transactionNumber });
          break;
        case 'Member.Reimbursement':
          if (vm.countryCode === 'SG') {
            $state.go('singaporeReimbursementInvoice', { transactionNumber: vm.transactionNumber });
          } else {
            $state.go('invoiceDetail', { transactionNumber: vm.transactionNumber });
          }
          break;
        case 'Panel.OutpatientClaims':
        case 'Panel.InpatientClaims':
        case 'Panel.OutpatientLicense':
          if (vm.countryCode === 'SG') {
            $state.go('singaporePanelInvoice', { transactionNumber: vm.transactionNumber });
          } else {
            $state.go('invoiceDetail', { transactionNumber: vm.transactionNumber });
          }
          break;
        default:
          $state.go('invoiceDetail', { transactionNumber: vm.transactionNumber });
          break;
      }
    }

    function getMessage(invoice, accOwner) {
      switch (invoice.tenantType) {
        case 'Organization':
          vm.message = accOwner.accountOwner.corporateInvoiceDisplayMessage;
          break;
        case 'Panel':
          if (
            invoice.category === 'OutpatientLicense' ||
            invoice.category === 'InpatientLicense'
          ) {
            vm.message = accOwner.accountOwner.serviceInvoiceDisplayMessage;
            break;
          }
          vm.message = accOwner.accountOwner.panelInvoiceDisplayMessage;
          break;
        case 'Insurer':
          vm.message = accOwner.accountOwner.insurerInvoiceDisplayMessage;
          break;
        default:
          break;
      }
    }

    function getDateString(date) {
      return App.getDateString(moment.utc(date));
    }

    function voidTransaction(transactionNumber) {
      swal(
        {
          title: App.localize('VoidTransaction'),
          text: App.localize('WhyVoidTransaction'),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          inputPlaceholder: App.localize('PleaseExplain'),
          showLoaderOnConfirm: false,
        },
        (inputValue) => {
          if (inputValue === false) return false;
          if (_.trim(inputValue || '') === '') {
            swal.showInputError(App.localize('InvalidInput'));
            return false;
          }

          abp.message.confirm(
            App.localize('VoidTransactionWarningMessage', transactionNumber),
            App.localize('AreYouSure'),
            (d) => {
              if (d) {
                vm.loading += 1;
                saleTransactionSvc
                  .voidSaleTransaction({
                    transactionNumber,
                    reason: inputValue
                  })
                  .success(() => {
                    abp.notify.info(App.localize('SuccessfullyVoided'));
                    swal.close();
                  })
                  .finally(() => {
                    vm.loading -= 1;
                  });
              }
            }
          );

          return true;
        });
    }
  }
})();
