(() => {
  angular.module('app').controller('common.views.users.createOrEditModal', UserEditController);

  UserEditController.$inject = [
    '$scope',
    '$uibModalInstance',
    'abp.services.app.user',
    'userId',
    'Hms.Users.User',
    'appConstants',
  ];

  function UserEditController(
    $scope,
    $uibModalInstance,
    userSvc,
    userId,
    constsUser,
    appConstants
  ) {
    const vm = this;

    vm.constsUser = constsUser;
    vm.loading = 0;
    vm.saving = 0;
    vm.user = null;
    vm.profilePictureId = null;
    vm.roles = [];
    vm.clinicLocations = [];
    vm.isClinic = App.isClinic();
    vm.isCorporate = App.isCorporate();
    vm.subsidiariesDepartments = [];
    vm.canChangeUserName = true;
    vm.accessibleCountries = [];
    vm.errors = [];

    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');
    vm.hasSecurityEnhancement1 = abp.setting.getBoolean('Hms.Feature.SecurityEnhancement1');

    vm.accessibleCountriesRequired = vm.hasRegionalSystemSupport && !vm.isClinic && !vm.isCorporate;

    vm.getClinicLocationValue = getClinicLocationValue;
    vm.save = save;
    vm.cancel = cancel;
    vm.getAssignedRoleCount = getAssignedRoleCount;
    vm.validateForm = validateForm;
    vm.isHmsPersonnelRole = isHmsPersonnelRole;

    init();

    function init() {
      vm.loading += 1;
      userSvc
        .getUserForEdit({
          id: userId,
        })
        .success((data) => {
          vm.user = data.user;
          vm.profilePictureId = data.profilePictureId;
          vm.roles = data.roles;
          vm.clinicLocations = data.clinicLocations;
          vm.subsidiariesDepartments = data.subsidiaryDepartments;
          vm.accessibleCountries = data.accessibleCountries;
          vm.isHmsteamUserExist = data.isHmsteamUserExist;
          const isAdminOrHmsPersonnel =
            vm.user.userName === appConstants.userManagement.defaultAdminUserName ||
            vm.user.userName === appConstants.userManagement.defaultHmsPersonnelUserName;
          vm.canChangeUserName = !isAdminOrHmsPersonnel;
          vm.canChangeActive = !isAdminOrHmsPersonnel;

          vm.emailRequired = vm.hasSecurityEnhancement1 || data.emailRequired;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getClinicLocationValue(item) {
      return parseInt(item.value, 10);
    }

    function save() {
      const assignedRoleNames = _.map(
        _.filter(vm.roles, {
          isAssigned: true,
        }),
        (data) => data.roleName
      );

      if (!vm.user.id && vm.user.autoGeneratePassword) {
        vm.user.password = null;
      }

      const accessibleCountries = _.filter(vm.accessibleCountries, (d) => d.countryCode);

      const defaultCountries = _.filter(accessibleCountries, (d) => d.isDefault);

      refreshErrors();

      if (!vm.validateForm()) return;

      if (
        !vm.isHmsteamUserExist &&
        _.includes(assignedRoleNames, appConstants.roleNames.hmsPersonnel) &&
        vm.user.userName !== appConstants.userManagement.defaultHmsPersonnelUserName
      ) {
        abp.message.error(App.localize('HmsPersonnelRoleAssignmentError'));
        return;
      }

      vm.saving += 1;
      userSvc
        .createOrUpdateUser({
          user: vm.user,
          assignedRoleNames,
          accessibleCountries,
        })
        .success((data) => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          if (data) {
            abp.message.success(App.localize('PasswordForUser', vm.user.userName), data);
          }

          $uibModalInstance.close();
        })
        .finally(() => {
          vm.saving -= 1;
        });

      function refreshErrors() {
        const errors = [];

        if (!vm.user.name) {
          errors.push(App.localize('InvalidName'));
        }

        if (!vm.user.userName) {
          errors.push(App.localize('InvalidUserName'));
        }

        if (
          vm.accessibleCountriesRequired &&
          (accessibleCountries.length === 0 || defaultCountries.length !== 1)
        ) {
          errors.push(App.localize('MissingDefaultUserAccessibleCountry'));
        }

        vm.errors = errors;
      }
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }

    function getAssignedRoleCount() {
      return _.filter(vm.roles, {
        isAssigned: true,
      }).length;
    }

    function validateForm() {
      return App.isFormValid($scope.userCreateOrEditForm);
    }

    function isHmsPersonnelRole(roleName) {
      return roleName === appConstants.roleNames.hmsPersonnel;
    }
  }
})();
