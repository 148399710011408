(() => {
  angular.module('finance').component('myInvoisEInvoiceTransactionViewComponent', {
    templateUrl: require('./myInvoisEInvoiceTransactionView.component.html'),
    controller: MyInvoisEInvoiceTransactionViewComponent,
    controllerAs: 'vm',
    bindings: {
      currencyCode: '<',
      transaction: '<',
      transactionTo: '<',
      transactionType: '<',
      buyerTaxIdentifiers: '<',
      supplierTaxIdentifiers: '<',
      getDateString: '<',
    },
  });

  MyInvoisEInvoiceTransactionViewComponent.$inject = [];

  function MyInvoisEInvoiceTransactionViewComponent() {
    const vm = this;

    vm.loading = 0;

    init();

    function init() {}
  }
})();
