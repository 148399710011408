(() => {
  angular
    .module('app')
    .controller(
      'host.views.employees.inpatientGlHistory.inpatientGlHistoryModal',
      InpatientGlHistoryController
    );

  InpatientGlHistoryController.$inject = [
    '$uibModalInstance',
    'abp.services.app.hostEmployee',
    'employeeId',
    'serviceType',
    'Hms.GuaranteeLetters.GuaranteeLetterStatus',
    'Hms.ServiceRequests.ServiceRequestStatus',
    'Hms.GuaranteeLetters.GuaranteeLetterState',
    'Hms.ServiceRequests.ServiceRequestQueryStatus',
    'Hms.ServiceRequests.ServiceRequestType',
    'Hms.Services.ServiceType'
  ];

  function InpatientGlHistoryController(
    $uibModalInstance,
    hostEmployeeSvc,
    employeeId,
    serviceType,
    enumGuaranteeLetterStatus,
    enumServiceRequestStatus,
    enumGuaranteeLetterState,
    enumServiceRequestQueryStatus,
    enumServiceRequestType,
    enumServiceType
  ) {
    const vm = this;
    vm.loading = 0;
    vm.letters = [];

    vm.loadMoreLetters = loadMoreLetters;
    vm.cancel = cancel;

    vm.enums = {};
    vm.enums.guaranteeLetterStatus = enumGuaranteeLetterStatus;
    vm.enums.serviceRequestStatus = enumServiceRequestStatus;
    vm.enums.guaranteeLetterState = enumGuaranteeLetterState;
    vm.enums.serviceRequestQueryStatus = enumServiceRequestQueryStatus;
    vm.enums.serviceRequestType = enumServiceRequestType;
    vm.enums.serviceType = enumServiceType;

    init();

    function init() {
      getInpatientAndPostHospitalizationGuaranteeLetters(10, serviceType);
    }

    function getInpatientAndPostHospitalizationGuaranteeLetters(maxResultCount) {
      vm.loading += 1;
      hostEmployeeSvc
        .getRecentInpatientAndPostHospitalizationGuaranteeLettersByEmployee({
          filter: employeeId,
          maxResultCount,
          serviceType,
        })
        .success((data) => {
          vm.totalCount = data.totalCount;
          vm.letters = data.items;
          vm.hasMoreLetters = data.totalCount > vm.letters.length;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function loadMoreLetters() {
      getInpatientAndPostHospitalizationGuaranteeLetters(vm.letters.length + 10, serviceType);
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
