(() => {
  angular
    .module('app')
    .controller('common.views.inpatientTickets.detail', InpatientTicketDetailPageController);

  InpatientTicketDetailPageController.$inject = [
    '$state',
    '$stateParams',
    'abp.services.app.inpatientPatientTicket',
    '$uibModal',
    'Hms.PatientTickets.PatientTicketType',
  ];

  function InpatientTicketDetailPageController(
    $state,
    $stateParams,
    inpatientPatientTicketSvc,
    $uibModal,
    enumPatientTicketType
  ) {
    const vm = this;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.loading = 0;
    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.ticketNumber = $stateParams.ticketNumber;
    vm.isEdit = false;
    vm.isCancel = false;
    vm.isSave = false;
    vm.scheduledPanelInvoiceGeneration = false;
    vm.hasMemberBenefitUtilizationEnabled = abp.setting.getBoolean(
      'Hms.Feature.MemberBenefitUtilization'
    );
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );
    vm.isHost = App.isHost();
    vm.enums = {
      patientTicketType: enumPatientTicketType,
    };
    vm.permissions = {
      edit: true,
      editManualPayment: abp.auth.isGranted('PatientTickets.EditManualPayment'),
      generateSaleTransactions: abp.auth.isGranted('Host.Clinics.GenerateSaleTransactions'),
    };
    vm.coPay = {
      isRoomAndBoardExceedEntitlement: false,
      isCoInsurance: false,
      isPercentage: false,
      coPayExclusions: [],
      value: 0,
    };
    vm.roomAndBoardCharges = 0;

    vm.checkSaveMode = checkSaveMode;
    vm.editManualPayment = editManualPayment;
    vm.generateSaleTransactions = generateSaleTransactions;
    init();

    function init() {
      getTicket();
    }

    function getTicket() {
      vm.loading += 1;
      inpatientPatientTicketSvc
        .getTicketDetail({
          id: vm.ticketNumber,
        })
        .success((data) => {
          vm.ticket = data.ticket;
          vm.patient = data.patient;
          vm.panel = data.panel;
          vm.organization = data.organization;
          vm.isMaternity = data.ticket.serviceType === 8;
          vm.isReimbursementTicket = data.ticket.ticketType === 2;
          vm.categories = data.ticket.categories;
          vm.claimNumber = data.ticket.inpatientClaimNumber;
          vm.scheduledPanelInvoiceGeneration = !!vm.ticket.panelInvoiceId;
          vm.ticket.diseaseClassifications = _.map(vm.ticket.diseaseClassifications, (d) => ({
            id: d.id,
            code: d.code,
            description: `(${d.code}) ${d.description}`,
          }));
          vm.diseaseClassifications = vm.ticket.diseaseClassifications;
          vm.treatments = data.treatments;
          vm.hasInsurerClaim = data.hasInsurerClaim;
          vm.isVoided = data.isVoided;

          // Update permission

          if (vm.ticket.ticketType === 0) {
            vm.permissions.edit =
              abp.auth.isGranted('Host.Claims.Inpatient.Manage') ||
              abp.auth.isGranted('Claims.Inpatient.Host.ManageAdjustment');
          } else if (vm.ticket.ticketType === 2) {
            vm.permissions.edit = abp.auth.isGranted('Host.Claims.Hospital.Manage');
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function checkSaveMode(isSave) {
      vm.isSave = isSave;
    }

    function editManualPayment() {
      $uibModal
        .open({
          templateUrl: require('../../patientTickets/editManualPaymentModal.html'),
          controller: 'common.views.patientTickets.editManualPaymentModal as vm',
          backdrop: 'static',
          resolve: {
            ticket() {
              return vm.ticket;
            },
            hasInsurerClaim: vm.hasInsurerClaim,
            currencyCode() {
              return vm.currencyCode;
            }          
          },
        })
        .result.then(() => {
          $state.reload();
        });
    }

    function generateSaleTransactions(ticket) {
      abp.message.confirm(
        App.localize('GenerateSaleTransactionsWarningMessage', ticket.ticketNumber),
        App.localize('AreYouSure'),
        (result) => {
          if (result) {
            vm.loading += 1;
            inpatientPatientTicketSvc
              .generateSaleTransactionForInpatientClaim({
                id: ticket.id,
              })
              .success(() => {
                vm.scheduledPanelInvoiceGeneration = true;
                abp.notify.success(App.localize('SaleTransactionsGenerated'));
              })
              .finally(() => {
                vm.loading -= 1;
              });
          }
        }
      );
    }
  }
})();
