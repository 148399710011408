(() => {
  angular
    .module('app')
    .controller('common.views.postHospitalizationClaims.requestAdjustment', RequestAdjustmentController);

  RequestAdjustmentController.$inject = [
    '$state',
    '$stateParams',
    'abp.services.app.specialistPanelServiceRequest',
  ];

  function RequestAdjustmentController($state, $stateParams, specialistPanelServiceRequestSvc) {
    const vm = this;

    vm.loading = 0;
    vm.saving = 0;

    vm.employeeId = null;
    vm.claimNumber = $stateParams.claimNumber;
    vm.timeZoneId = $stateParams.timeZoneId;

    vm.adjustment = {
      attachments: [],
    };

    vm.postHospitalizationAdjustmentObjectType = 'PostHospitalizationAdjustmentAttachment';

    vm.save = save;
    vm.back = back;

    init();

    function init() {
      specialistPanelServiceRequestSvc
        .getAdjustmentDetails({
          id: vm.claimNumber,
        })
        .success((data) => {
          vm.adjustment = {
            panelLocationId: data.panelLocationId,
            employeeId: data.employeeId,
            checklistItems: data.documentChecklistItems,
            newAttachments: [],
          };

          _.each(vm.adjustment.checklistItems, (item) => {
            const i = item;
            i.required = i.isMandatory;
            i.isChecked = i.isSelected;
          });
        });
    }

    function save() {
      if (vm.saving) return;

      vm.loading += 1;

      const checklistItems = [];
      _.each(vm.adjustment.checklistItems, (item) => {
        checklistItems.push({
          text: item.text,
          isSelected: item.isChecked,
          isMandatory: item.required,
        });
      });

      const input = {
        claimNumber: vm.claimNumber,
        adjustmentReason: tidyReason(),
        adjustedFinalBillAmount: vm.adjustment.amount,
        documentChecklistItems: checklistItems,
        newAttachments: vm.adjustment.newAttachments,
        isPostHospitalization: true
      };

      vm.saving = true;
      specialistPanelServiceRequestSvc
        .requestAdjustment(input)
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          back();
        })
        .finally(() => {
          vm.saving = false;
          vm.loading -= 1;
        });
    }

    function tidyReason() {
      let reason = _.trim(vm.adjustment.reason);
      if (reason === 'Others') {
        const remarks = _.trim(vm.adjustment.remarks);
        if (remarks) {
          reason += `: ${remarks}`;
        } else {
          reason = '';
        }
      }
      return reason;
    }

    function back() {
      $state.go('postHospitalizationClaimDetail', { claimNumber: vm.claimNumber, timeZoneId: vm.timeZoneId });
    }
  }
})();
