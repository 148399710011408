(() => {
  angular
    .module('app')
    .controller('common.views.inpatientTickets.detail.auditHistory', AuditHistoryController);

  AuditHistoryController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    'abp.services.app.inpatientPatientTicket',
    'moment',
  ];

  function AuditHistoryController($state, $stateParams, $modal, inpatientPatientTicketSvc, moment) {
    const vm = this;

    vm.loading = 0;
    vm.hasMemberBenefitUtilizationEnabled = abp.setting.getBoolean(
      'Hms.Feature.MemberBenefitUtilization'
    );
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.ticketNumber = $stateParams.ticketNumber;
    vm.isClinic = App.isClinic();
    vm.selectedUtilizationAuditLogs = [];
    vm.selectedModifications = [];
    vm.selectedTab = null;

    vm.tabSelected = tabSelected;
    vm.checkGeneralCheckBox = checkGeneralCheckBox;
    vm.checkUtilizationCheckBox = checkUtilizationCheckBox;
    vm.goBack = goBack;
    vm.compareAuditLogs = compareAuditLogs;

    init();

    function init() {
      vm.loading += 1;
      inpatientPatientTicketSvc
        .getInpatientTicketAuditHistory({ id: $stateParams.ticketNumber })
        .success((data) => {
          vm.ticketNumber = data.ticketNumber;
          vm.ticketType = data.ticketType;
          vm.modifications = data.modifications;
          vm.utilizationAdjustments = data.utilizationAdjustments;

          _.each(vm.modifications, (mod) => {
            const m = mod;
            m.items = processSnapshot(m.snapshot);
            m.formattedSnapshot = formatSnapshot(m.snapshot);
          });
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function tabSelected(i) {
      vm.selectedTab = i;
    }

    function formatSnapshot(snapshot) {
      const data = JSON.parse(snapshot);
      return JSON.stringify(data, null, 4);
    }

    function processSnapshot(snapshot) {
      const data = JSON.parse(snapshot);
      const output = {
        basics: {},
        arrays: {},
        objects: {},
      };

      // Preprocess value for formatting and localization.

      function preprocessValue(value, key) {
        const momentObj = moment(value, moment.ISO_8601);
        if (momentObj.isValid()) return momentObj.format('L');
        if (key === 'ClinicType') {
          switch (value) {
            case 7:
              return App.localize('Hospitalization');
            case 8:
              return App.localize('Maternity');
            default:
              return App.localize('Unknown');
          }
        }
        return value;
      }

      // Preprocess object to uppercase first character and preprocess its value.

      function preprocessObject(v) {
        const obj = {};
        _.forIn(v, (objV, objK) => {
          const key = _.upperFirst(objK);
          obj[key] = preprocessValue(objV, key);
        });
        return obj;
      }

      // Traverse, process and organize every property.

      _.forIn(data, (value, key) => {
        const upperKey = _.upperFirst(key);

        if (_.isArrayLikeObject(value)) {
          const orderedValues = _(value).map(JSON.stringify).sort().map(JSON.parse).value();

          _.forEach(orderedValues, (v, k) => {
            orderedValues[k] = preprocessObject(v, k);
          });
          output.arrays[upperKey] = orderedValues;
        } else if (_.isObjectLike(value)) output.objects[upperKey] = preprocessObject(value);
        else output.basics[upperKey] = preprocessValue(value, upperKey);
      });

      return output;
    }

    function checkGeneralCheckBox() {
      vm.selectedModifications = _.filter(vm.modifications, 'isChecked');
    }

    function checkUtilizationCheckBox() {
      vm.selectedUtilizationAuditLogs = _.filter(vm.utilizationAdjustments, 'isChecked');
    }

    function compareAuditLogs() {
      if (vm.selectedTab === 1) {
        $modal.open({
          templateUrl: require('./compareAuditHistory.modal.html'),
          controller:
            'common.views.inpatientTickets.detail.auditHistory.compareAuditLogsModal as vm',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            auditlogs() {
              return vm.selectedModifications;
            },
          },
        });
      } else if (vm.selectedTab === 2) {
        $modal.open({
          templateUrl: require('./compareUtilization.modal.html'),
          controller:
            'common.views.inpatientTickets.detail.auditHistory.compareUtilizationModal as vm',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            auditlogs() {
              return vm.selectedUtilizationAuditLogs;
            },
          },
        });
      }
    }

    function goBack() {
      $state.go('patientTicketDetail', {
        ticketNumber: vm.ticketNumber,
      });
    }
  }
})();
