(() => {
  angular
    .module('app')
    .controller('finance.views.saleTransactions.myInvoisEInvoiceDetail.page', MyInvoisEInvoiceDetailController);

  MyInvoisEInvoiceDetailController.$inject = [
    '$stateParams',
    'abp.services.finance.invoices',
    'abp.services.finance.creditMemos',
    'abp.services.app.commonLookup',
  ];

  function MyInvoisEInvoiceDetailController(
    $stateParams,
    invoicesSvc,
    creditMemosSvc,
    commonLookupSvc
  ) {
    const vm = this;

    vm.loading = 0;

    vm.transactionNumber = $stateParams.transactionNumber;
    vm.transactionType = getTransactionType();
    vm.transaction = null;
    vm.transactionFrom = null;
    vm.transactionTo = null;
    vm.billingAccountId = null;
    vm.getDateString = getDateString;

    init();

    function init() {
      getTransaction();
    }

    function isValid(data) {
      if (data == null) {
        swal({
          title: "Invalid e-invoice",
          type: 'error'
        });

        return false;
      }

      return true;
    }

    function getTransaction() {
      if (vm.transactionType == 1) {
        vm.loading += 1;
        invoicesSvc
          .getInvoice({
            invoiceNumber: vm.transactionNumber,
          })
          .success((data) => {
            if (!isValid(data.myInvoisEInvoice))
              return;

            vm.currencyCode = data.invoice.currencyCode;
            vm.countryCode = data.invoiceFrom.countryCode;
            vm.transaction = data.invoice;
            vm.transactionFrom = data.invoiceFrom;
            vm.transactionTo = data.invoiceTo;

            setCommonData(data);
            getTransactionFromContactNumber();
          })
          .finally(() => {
            vm.loading -= 1;
          });
      } else {
        vm.loading += 1;
        creditMemosSvc
          .getCreditMemo({
            creditMemoNumber: vm.transactionNumber,
          })
          .success((data) => {
            if (!isValid(data.myInvoisEInvoice))
              return;

            vm.currencyCode = data.creditMemo.currencyCode;
            vm.countryCode = data.creditMemoFrom.countryCode;
            vm.transaction = data.creditMemo;
            vm.transactionFrom = data.creditMemoFrom;
            vm.transactionTo = data.creditMemoTo;

            setCommonData(data);
            getTransactionFromContactNumber();
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function setCommonData(data) {
      vm.supplierTaxIdentifiers = data.myInvoisEInvoice.taxIdentifiers.supplierTaxIdentifiers;
      vm.buyerTaxIdentifiers = data.myInvoisEInvoice.taxIdentifiers.buyerTaxIdentifiers;
      vm.validationDate = data.myInvoisEInvoice.validationDate;
      vm.documentUuid = data.myInvoisEInvoice.documentUid;
      vm.qrCode = data.myInvoisEInvoice.qrCode;
    }

    function getTransactionFromContactNumber() {
      commonLookupSvc.getAccountOwnerById({ id: vm.transactionFrom.id }).success((accOwner) => {
        vm.transactionFrom.contactNumber = accOwner.accountOwner.contactNumber;
      });
    }

    function getDateString(date) {
      return App.getDateString(moment.utc(date));
    }

    function getTransactionType() {
      var transactionDiscriminator = vm.transactionNumber.substr(0, 2);
      var transactionType = 0;

      switch (transactionDiscriminator) {
        case 'IN':
          transactionType = 1;
          break;
        case 'CR':
          transactionType = 2;
          break;
      }

      return transactionType;
    }
  }
})();
