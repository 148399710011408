(() => {
  angular.module('app').controller('clinic.views.administration.panelInfoUpdate.index', PanelInfoUpdateController);

  PanelInfoUpdateController.$inject = [
    '$scope',
    '$sce',
    'abp.services.app.tenantSettings',
  ];

  function PanelInfoUpdateController(
    $scope,
    $sce,
    tenantSettingsSvc,
  ) {
    const vm = this;

    init();

    function init() {
      getPanelInfoFormLink();
    }

    function getPanelInfoFormLink() {
      vm.loading += 1;
      tenantSettingsSvc
        .getPanelInfoFormLink()
        .success((data) => {
          vm.requestFormLink = data;
          vm.requestFormLinkUrl = $sce.trustAsResourceUrl(data);
        })
        .finally(() => {
          vm.loading -= 1;
        })
    }

  }
})();
