(function () {
    var controllerId = 'host.views.corporates.masterPolicies.insurancePlansListing.component';
    var app = angular.module('app');

    app.controller(controllerId, [
        '$scope', '$state', '$stateParams', 'abp.services.app.benefitGroup', 'abp.services.app.masterPolicy',
        function ($scope, $state, $stateParams, benefitGroupSvc, masterPolicySvc) {
            function init() {
                vm.getInsurancePlans(vm.masterPolicyId, vm.organizationId);

                // Retrieve master policy number and master policy's insurer name.

                vm.loading++;
                masterPolicySvc.getMasterPolicy({
                    organizationId: vm.organizationId,
                    masterPolicyId: vm.masterPolicyId
                }).success(function (data) {
                    vm.masterPolicyNumber = data.masterPolicy.masterPolicyNumber;
                    vm.masterPolicyInsurer = data.masterPolicy.insurerName;
                })
                    .finally(function () {
                        vm.loading--;
                    })
            }

            var vm = this;
            vm.loading = false;
            vm.masterPolicyId = $stateParams.masterPolicyId;
            vm.masterPolicyNumber = $stateParams.masterPolicyNumber;
            vm.masterPolicyInsurer = $stateParams.masterPolicyInsurer;
            vm.organizationId = $stateParams.organizationId;

            vm.insurancePlanGridOptions = {
                appScopeProvider: vm,
                columnDefs: [{
                    enableSorting: false,
                    name: App.localize('Actions'),
                    width: 96,
                    headerCellTemplate: '<span></span>',
                    cellTemplate: 'actionTemplate'
                }, {
                    displayName: App.localize('PlanName'),
                    field: 'name',
                    minWidth: 120,
                }, {
                    displayName: App.localize('DisplayName'),
                    field: 'displayName',
                    minWidth: 65
                }, {
                    displayName: App.localize('CreationDate'),
                    field: 'creationTime',
                    cellFilter: 'momentFormat: \'L\'',
                    minWidth: 100
                }],
                data: []
            };

            vm.cloneInsurancePlan = cloneInsurancePlan;

            // Get insurance Plans listing
            vm.getInsurancePlans = function (masterPolicyId, organizationId) {
                vm.loading++;
                benefitGroupSvc.getInpatientBenefitGroups({
                    id: organizationId,
                    masterPolicyId: masterPolicyId
                }).success(function (data) {
                    vm.insurancePlanGridOptions.data = _.map(data.items, function (d) {
                        return $.extend(d, {
                            organizationId: vm.organizationId
                        });
                    });
                }).finally(function (e) {
                    vm.loading--;
                });
            }

            vm.editInsurancePlan = function (insurancePlan) {
                $state.go('host.createEditInsurancePlan', { organizationId: vm.organizationId, masterPolicyId: vm.masterPolicyId, insurancePlanId: insurancePlan.id })
            }

            function cloneInsurancePlan(insurancePlan) {
                $state.go(
                    'host.createEditInsurancePlan', 
                    { 
                        organizationId: vm.organizationId, 
                        masterPolicyId: vm.masterPolicyId, 
                        insurancePlanId: insurancePlan.id,
                        isCopy: true
                    });
            }

            vm.deleteInsurancePlan = function (insurancePlan) {
                abp.message.confirm(App.localize('InsurancePlanDeleteWarningMessage', insurancePlan.name), App.localize('AreYouSure'), function (d) {
                    if (d) {
                        benefitGroupSvc.deleteInpatientBenefitGroup({
                            id: insurancePlan.id,
                            organizationId: vm.organizationId
                        }).success(function (data) {
                            vm.getInsurancePlans(vm.masterPolicyId, vm.organizationId);
                            abp.notify.success(App.localize('SuccessfullyDeleted'));
                        });
                    }
                });
            };

            init();
        }
    ]);
})();
