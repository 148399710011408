(() => {
  angular
    .module('app')
    .controller(
      'host.views.corporates.supportPlan.createEdit',
      CorporatesSupportPlanCreateEditController
    );

  CorporatesSupportPlanCreateEditController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$filter',
    'abp.services.app.benefitGroup',
    'abp.services.app.commonLookup',
  ];

  function CorporatesSupportPlanCreateEditController(
    $scope,
    $state,
    $stateParams,
    $filter,
    benefitGroupSvc,
    commonLookupSvc
  ) {
    const vm = this;

    const ccyFilter = $filter('currencyFormat');
    const hospitalizationDetails = [
      new TreatmentModel(7, App.localize('Hospitalization'), 'fas fa-bed', true),
    ];
    const maternityDetails = [new TreatmentModel(8, App.localize('Maternity'), 'fas fa-bed', true)];
    const isCopyAction = $stateParams.isCopy;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.countryCode = null;
    vm.hasScheduleOfBenefitEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
    vm.hasInpatientRegionalExpansion = abp.setting.getBoolean(
      'Hms.Feature.RegionalExpansionForInpatient'
    );

    vm.inpatientBenefitGroupId = $stateParams.supportPlanId;
    vm.organizationId = $stateParams.organizationId;
    vm.serviceType = $stateParams.serviceType;
    vm.isHospitalization = $stateParams.serviceType && $stateParams.serviceType === 7;
    vm.loading = 0;
    vm.saving = 0;
    vm.isCreate = !vm.inpatientBenefitGroupId || isCopyAction;
    vm.buttonLabel = vm.isCreate ? App.localize('Save') : App.localize('Update');
    vm.optionalExclusions = [];
    vm.categories = [];
    vm.costSharingExample = App.localize('CostSharingExample', vm.currencyCode);
    vm.showCostSharingExample = false;

    vm.beneficiary = {
      principal: 1,
      dependent: 2,
    };

    vm.submit = submit;

    /* eslint max-classes-per-file: ["error", 3] */
    class CoPay {
      constructor(bgModel, dto) {
        this.parent = bgModel;

        // Basic properties

        this.beneficiary = 3;
        this.isPercentage = true;
        this.value = null;

        // View properties

        this.show = true;

        // Treatment type mapping

        this.principalMap = _.keyBy(bgModel.principalBenefits, 'id');
        this.dependentMap = _.keyBy(bgModel.dependentBenefits, 'id');

        // Applicable treatment types

        const treatments = [];
        _.each(this.parent.principalBenefits, (e) => {
          const isValid = this.checkValid(e.id);
          treatments.push(
            new TreatmentApplyModel(new TreatmentModel(e.id, e.name, e.icon, isValid), isValid)
          );
        });
        this.treatments = treatments;

        // Initialization

        if (dto) {
          automap(dto, this);
          this.refreshTreatmentValidity();
        }
      }

      // Private functions

      checkValid(id) {
        switch (this.beneficiary) {
          case 3:
            return (this.principalMap[id].valid 
              || (this.parent.hasDependentCovered === 1 && this.dependentMap[id].valid));
          case 1:
            return this.principalMap[id].valid;
          case 2:
            return this.dependentMap[id].valid;
          default:
            return false;
        }
      }

      // Public functions

      changed() {
        this.refreshTreatmentValidity();
      }

      refreshTreatmentValidity() {
        _.each(this.treatments, (e) => {
          e.treatment.valid = this.checkValid(e.treatment.id);
        });
      }

      toDto() {
        if (vm.isHospitalization) {
          return {
            beneficiary: this.beneficiary,
            isPercentage: this.isPercentage,
            value: this.value,
            isAffectingAdmission: this.isAffectingAdmission,
            isAffectingPreHospitalization: this.isAffectingPreHospitalization,
            isAffectingPostHospitalization: this.isAffectingPostHospitalization,
            isAffectingSelfPayHospitalization: this.isAffectingSelfPayHospitalization,
          };
        }

        return {
          beneficiary: this.beneficiary,
          isPercentage: this.isPercentage,
          value: this.value,
          isAffectingNormalDelivery: this.isAffectingNormalDelivery,
          isAffectingCaesareanDelivery: this.isAffectingCaesareanDelivery,
          isAffectingPreNatalCheckUp: this.isAffectingPreNatalCheckUp,
          isAffectingPostNatalFollowUp: this.isAffectingPostNatalFollowUp,
          isAffectingNeoNatal: this.isAffectingNeoNatal,
        };
      }
    }

    class Modifier {
      constructor(bgModel, dto) {
        this.parent = bgModel;

        // Basic properties

        this.type = 0;
        this.cycle = 1;
        this.beneficiary = 3;
        this.poolMode = 0;
        this.value = null;
        this.interval = 2;

        // View properties

        this.show = true;
        this.description = '';

        // Treatment type mapping

        this.principalMap = _.keyBy(bgModel.principal, 'id');
        this.dependentMap = _.keyBy(bgModel.dependent, 'id');

        // Applicable treatment types

        const treatments = [];
        _.each(this.parent.principal, (e) => {
          const isValid = this.checkValid(e.id);
          treatments.push(
            new TreatmentApplyModel(new TreatmentModel(e.id, e.name, e.icon, isValid), isValid)
          );
        });
        this.treatments = treatments;

        // Initialization

        if (dto) {
          automap(dto, this);
          this.refreshTreatmentValidity();
        }

        this.refreshDescription();
      }

      // Private functions

      checkValid(id) {
        switch (this.beneficiary) {
          case 3:
            return (
              this.principalMap[id].valid ||
              (this.parent.hasDependentCovered === 1 && this.dependentMap[id].valid)
            );
          case 1:
            return this.principalMap[id].valid;
          case 2:
            return this.dependentMap[id].valid;
          default:
            return false;
        }
      }

      validateModifierRules() {
        // If modifier is not budget limit, cycle cannot be Per Visit.
        // Default to annually.

        if (this.type !== 0 && this.cycle === 4) {
          this.cycle = 1;
        }

        // If beneficiary is principal only, there is no pool to share.
        // If beneficiary is dependant only, split pool cannot be selected.
        // Default to individual pool.

        if (this.beneficiary === 1 || (this.beneficiary === 2 && this.poolMode === 2)) {
          this.poolMode = 1;
        }
      }

      // Public functions

      changed() {
        this.validateModifierRules();
        this.refreshTreatmentValidity();
        this.refreshDescription();
      }

      refreshTreatmentValidity() {
        _.each(this.treatments, (e) => {
          e.treatment.valid = this.checkValid(e.treatment.id);
        });
      }

      // Post method

      toDto() {
        return {
          type: this.type,
          cycle: this.cycle,
          beneficiary: this.beneficiary,
          poolMode: this.poolMode,
          value: this.value,
          interval: this.interval,
        };
      }

      refreshDescription() {
        let type = this.type;
        let cycle = this.cycle;
        let interval = this.interval;
        let beneficiary = this.beneficiary;
        let poolMode = this.poolMode;
        let value = this.value;
        let treatmentTypes = this.treatmentTypes;
        let desc = '';
        let ttNames = [];

        // eslint-disable-next-line default-case
        switch (cycle) {
          case 0:
            desc += `Every ${interval} year(s)`;
            break;
          case 1:
            desc += 'Every year';
            break;
          case 2:
            desc += 'Every month';
            break;
          case 3:
            desc += 'Everyday';
            break;
          case 4:
            desc += 'Every visit';
            break;
          // no default
        }

        // eslint-disable-next-line default-case
        switch (type) {
          case 0:
          case 1:
            desc += ' limits ';
            break;
          case 2:
          case 3:
            desc += ' allows overdraft of ';
            break;
          // no default
        }

        // eslint-disable-next-line default-case
        switch (type) {
          case 0:
          case 2:
            desc += `${vm.currencyCode} ${value}`;
            break;
          case 1:
          case 3:
            desc += `${value} visits`;
            break;
          // no default
        }

        // eslint-disable-next-line default-case
        switch (beneficiary) {
          case 1:
            desc += ' for principal';
            break;
          case 2:
            desc += ' for dependants';
            break;
          case 3:
            desc += ' for principal & dependants';
            break;
          // no default
        }

        // eslint-disable-next-line default-case
        switch (poolMode) {
          case 0:
            desc += ' where everyone share';
            break;
          case 1:
            desc += ' individually';
            break;
          case 2:
            desc += ' where dependents share';
            break;
          // no default
        }

        if (treatmentTypes) {
          desc += ' on ';

          _.each(treatmentTypes, (isApplied, idStr) => {
            const id = parseInt(idStr, 10);
            if (isApplied) {
              ttNames.push(_.find(vm.treatmentTypes, (a) => a.id === id).name);
            }
          });

          desc += ttNames.join(', ');
        }

        this.description = ttNames.length > 0 ? desc : 'Not applicable';
      }
    }

    class BenefitGroupModel {
      constructor(dto) {
        // Basic properties

        this.id = 0;
        this.organizationId = vm.organizationId;
        this.name = '';
        this.displayName = '';
        this.insurerId = '';

        // Benefits

        this.principal = vm.isHospitalization ? hospitalizationDetails : maternityDetails;
        this.dependent = vm.isHospitalization ? hospitalizationDetails : maternityDetails;

        // Benefit Modifiers

        this.modifiers = [];

        // Benefit summaries

        this.principalSummaries = [];
        this.showPrincipalFootnote = false;
        this.dependentSummaries = [];
        this.showDependentFootnote = false;

        if (dto) {
          automap(dto, this);

          if (!this.insurerId) this.insurerId = '';

          // Inpatient or maternity settings.

          if (this.serviceType === 7) {
            // Basics

            this.hospitalizationSetting.hasDependentCovered = dto.hospitalizationSetting
              .hasDependentCovered
              ? 1
              : 0;
            this.hospitalizationSetting.isAccidentAdmission =
              dto.hospitalizationSetting.admissionWaitingAccidentDays > 0 ? 1 : 0;
            this.hospitalizationSetting.isIllnessAdmission =
              dto.hospitalizationSetting.admissionWaitingIllnessDays > 0 ? 1 : 0;
            this.hospitalizationSetting.isPreExisting =
              dto.hospitalizationSetting.admissionWaitingPreExistingDays > 0 ? 1 : 0;
            this.hospitalizationSetting.isNonAccidentalIllness =
              dto.hospitalizationSetting.admissionWaitingNonAccidentalIllnessDays > 0 ? 1 : 0;
            this.hospitalizationSetting.isDefaultExclusionsCovered = dto.hospitalizationSetting
              .isDefaultExclusionsCovered
              ? 1
              : 0;

            this.hospitalizationSetting.coveredOptionalExclusions = buildExclusionTypeList(
              vm.optionalExclusions,
              dto.hospitalizationSetting.coveredOptionalExclusions
            );

            // Benefits

            const clonedHospitalizationModifiers = dto.hospitalizationSetting.modifiers.slice(0);
            this.hospitalizationSetting.modifiers = [];
            _.each(clonedHospitalizationModifiers, (x) => {
              this.hospitalizationSetting.modifiers.push(new Modifier(this, x));
            });

            // Cost sharing
            this.hospitalizationSetting.isCoInsurance = dto.hospitalizationSetting.isCoInsurance;
            this.hospitalizationSetting.isRoomAndBoardExceedEntitlement =
              dto.hospitalizationSetting.isRoomAndBoardExceedEntitlement;
            this.hospitalizationSetting.coPayExclusions =
              dto.hospitalizationSetting.coPayExclusions;
            this.hospitalizationSetting.coPayType = dto.hospitalizationSetting.coPayType;
            this.hospitalizationSetting.coPayExclusionCategories = [];

            if (dto.hospitalizationSetting.coPays) {
              const clonedHospitalizationCoPays = dto.hospitalizationSetting.coPays.slice(0);
              this.hospitalizationSetting.coPays = [];
              _.each(clonedHospitalizationCoPays, (x) => {
                this.hospitalizationSetting.coPays.push(new CoPay(this, x));
              });
            }

            // Schedule of benefit

            this.hospitalizationSetting.enableDependentSetting = 0;

            this.hospitalizationSetting.principalScheduleOfBenefit =
              dto.hospitalizationSetting.principalScheduleOfBenefit;
            this.hospitalizationSetting.dependentScheduleOfBenefit =
              dto.hospitalizationSetting.dependentScheduleOfBenefit;
          } else {
            // Basics

            this.maternitySetting.isSpouseCovered = 
              dto.maternitySetting.isSpouseCovered ? 1 : 0;
            this.maternitySetting.isHasMotherAgeLimit =
              dto.maternitySetting.motherMaxAge > 0 ? 1 : 0;
            this.maternitySetting.isMaternitybenefits =
              dto.maternitySetting.admissionWaitingDays > 0 ? 1 : 0;
            this.maternitySetting.isUnlimitedDelivery =
              dto.maternitySetting.deliveryLimit === 0 ? 1 : 0;

            // Benefits

            const clonedMaternityModifiers = dto.maternitySetting.modifiers.slice(0);
            this.maternitySetting.modifiers = [];
            _.each(clonedMaternityModifiers, (x) => {
              this.maternitySetting.modifiers.push(new Modifier(this, x));
            });

            // Cost sharing

            this.maternitySetting.isCoInsurance = dto.maternitySetting.isCoInsurance;
            this.maternitySetting.coPayExclusions = dto.maternitySetting.coPayExclusions;
            this.maternitySetting.coPayType = dto.maternitySetting.coPayType;
            this.maternitySetting.coPayExclusionCategories = [];

            if (dto.maternitySetting.coPays) {
              const clonedMaternityCoPays = dto.maternitySetting.coPays.slice(0);
              this.maternitySetting.coPays = [];
              _.each(clonedMaternityCoPays, (x) => {
                this.maternitySetting.coPays.push(new CoPay(this, x));
              });
            }

            // Normal delivery

            this.maternitySetting.normalDelivery.amountType = dto.maternitySetting.normalDelivery
              .isAsCharged
              ? 0
              : 1;
            if (!this.maternitySetting.normalDelivery.isCovered) {
              this.maternitySetting.normalDelivery.amountType = 2;
            }
            this.maternitySetting.normalDelivery.maxDaysTimesType = dto.maternitySetting
              .normalDelivery.isUnlimited
              ? 1
              : 0;

            // Caesarean delivery

            this.maternitySetting.caesareanDelivery.amountType = dto.maternitySetting
              .caesareanDelivery.isAsCharged
              ? 0
              : 1;
            if (!this.maternitySetting.caesareanDelivery.isCovered) {
              this.maternitySetting.caesareanDelivery.amountType = 2;
            }
            this.maternitySetting.caesareanDelivery.maxDaysTimesType = dto.maternitySetting
              .caesareanDelivery.isUnlimited
              ? 1
              : 0;

            // Normal delivery for twins and above

            this.maternitySetting.normalDeliveryTwinsAndAbove.amountType = dto.maternitySetting
              .normalDeliveryTwinsAndAbove.isAsCharged
              ? 0
              : 1;
            if (!this.maternitySetting.normalDeliveryTwinsAndAbove.isCovered) {
              this.maternitySetting.normalDeliveryTwinsAndAbove.amountType = 2;
            }
            this.maternitySetting.normalDeliveryTwinsAndAbove.maxDaysTimesType = dto
              .maternitySetting.normalDeliveryTwinsAndAbove.isUnlimited
              ? 1
              : 0;

            // Caesarean delivery for twins and above

            this.maternitySetting.caesareanDeliveryTwinsAndAbove.amountType = dto.maternitySetting
              .caesareanDeliveryTwinsAndAbove.isAsCharged
              ? 0
              : 1;
            if (!this.maternitySetting.caesareanDeliveryTwinsAndAbove.isCovered) {
              this.maternitySetting.caesareanDeliveryTwinsAndAbove.amountType = 2;
            }
            this.maternitySetting.caesareanDeliveryTwinsAndAbove.maxDaysTimesType = dto
              .maternitySetting.caesareanDeliveryTwinsAndAbove.isUnlimited
              ? 1
              : 0;

            // Pre-natal check-up

            this.maternitySetting.preNatalCheckUp.isCovered = dto.maternitySetting.preNatalCheckUp
              .isCovered
              ? 1
              : 0;
            this.maternitySetting.preNatalCheckUp.amountType = dto.maternitySetting.preNatalCheckUp
              .isAsCharged
              ? 0
              : 1;
            this.maternitySetting.preNatalCheckUp.maxDaysTimesType = dto.maternitySetting
              .preNatalCheckUp.isUnlimited
              ? 1
              : 0;

            // Post-natal follow-up

            this.maternitySetting.postNatalFollowUp.isCovered = dto.maternitySetting
              .postNatalFollowUp.isCovered
              ? 1
              : 0;
            this.maternitySetting.postNatalFollowUp.amountType = dto.maternitySetting
              .postNatalFollowUp.isAsCharged
              ? 0
              : 1;
            this.maternitySetting.postNatalFollowUp.maxDaysTimesType = dto.maternitySetting
              .postNatalFollowUp.isUnlimited
              ? 1
              : 0;

            // Miscarriage

            if (dto.maternitySetting.miscarriage.isAsCharged) {
              this.maternitySetting.miscarriage.amountType = 0;
            } else if (dto.maternitySetting.miscarriage.isCovered) {
              this.maternitySetting.miscarriage.amountType = 1;
            } else {
              this.maternitySetting.miscarriage.amountType = 2;
            }

            this.maternitySetting.miscarriage.maxDaysTimesType = dto.maternitySetting.miscarriage
              .isUnlimited
              ? 1
              : 0;

            // Covers pregnancy related complications

            this.maternitySetting.isDefaultExclusionsCovered = 
              dto.maternitySetting.isDefaultExclusionsCovered 
                ? 1
                : 0;
            this.maternitySetting.isOptionalExclusionsCovered =
              dto.maternitySetting.coveredOptionalExclusions &&
              dto.maternitySetting.coveredOptionalExclusions.length > 0
                ? 1
                : 0;
            this.maternitySetting.coveredOptionalExclusions = buildExclusionTypeList(
              vm.optionalExclusions,
              dto.maternitySetting.coveredOptionalExclusions
            );

            // Neo-natal

            this.maternitySetting.neoNatal.isCovered = dto.maternitySetting.neoNatal.isCovered
              ? 1
              : 0;
          }
        }

        this.refreshResult();
      }

      refreshResult() {
        let principalSummaries = [];
        let dependentSummaries = [];
        let showPrincipalFootnote = false;
        let showDependentFootnote = false;

        let coverDependent =
          (this.hospitalizationSetting && !!this.hospitalizationSetting.hasDependentCovered) ||
          (this.maternitySetting && !!this.maternitySetting.isSpouseCovered);
        const principal = _.map(
          _.filter(this.principal, 'valid'),
          (x) => new BenefitResult(x, true, true)
        );
        const dependent = coverDependent
          ? _.map(_.filter(this.dependent, 'valid'), (x) => new BenefitResult(x, true, true))
          : [];
        if (!dependent.length) coverDependent = false;
        const coverPrincipal = principal.length > 0;

        if (vm.isHospitalization) {
          if (coverDependent) {
            if (vm.dependentSettingPlaceHolder) {
              this.hospitalizationSetting.dependentScheduleOfBenefit = angular.copy(
                vm.dependentSettingPlaceHolder
              );
            } else if (
              vm.isCreate ||
              (!vm.isCreate && !this.hospitalizationSetting.dependentScheduleOfBenefit)
            ) {
              this.hospitalizationSetting.dependentScheduleOfBenefit = angular.copy(
                this.hospitalizationSetting.principalScheduleOfBenefit
              );
            }
          } else {
            if (this.hospitalizationSetting.dependentScheduleOfBenefit) {
              vm.dependentSettingPlaceHolder = angular.copy(
                this.hospitalizationSetting.dependentScheduleOfBenefit
              );
            }

            this.hospitalizationSetting.enableDependentSetting = 0;
            this.hospitalizationSetting.dependentScheduleOfBenefit = null;
          }
        }

        // Group result into stacks

        let modifiers = [];
        if (typeof vm.isHospitalization !== 'undefined' && vm.isHospitalization !== null) {
          modifiers = vm.isHospitalization
            ? this.hospitalizationSetting.modifiers
            : this.maternitySetting.modifiers;
        }

        _.each(modifiers, (mod) => {
          if (mod.cycle === 4 && mod.type !== 0) {
            mod.cycle = 3;
          }
        });

        _.each(
          _.groupBy(modifiers, (x) => {
            const interval = x.cycle === 0 ? x.interval : 1;
            return `${x.type}:${interval}:${x.cycle}`;
          }),
          (values) => {
            _.each(values, (modifier) => {
              let principalPortion = [];
              let dependentPortion = [];

              const beneficiary = coverDependent ? modifier.beneficiary : 1;
              const poolMode = coverDependent ? modifier.poolMode : 2;

              _.each(modifier.treatments, (t) => {
                let benefit;
                if (coverPrincipal && (beneficiary && 1) > 0) {
                  benefit = _.find(principal, (x) => x.treatment.id === t.treatment.id);
                  if (benefit) {
                    if (modifier.type === 0) {
                      benefit.unlimitedBudget = false;
                    } else if (modifier.type === 1) {
                      benefit.unlimitedVisit = false;
                    }
                    principalPortion.push(t.treatment);
                  }
                }
                if (coverDependent && (beneficiary && 2) > 0) {
                  benefit = _.find(dependent, (x) => x.treatment.id === t.treatment.id);
                  if (benefit) {
                    if (modifier.type === 0) {
                      benefit.unlimitedBudget = false;
                    } else if (modifier.type === 1) {
                      benefit.unlimitedVisit = false;
                    }
                    dependentPortion.push(t.treatment);
                  }
                }
              });

              if (principalPortion.length || dependentPortion.length) {
                const principalTreatmentNames = _.join(_.map(principalPortion, 'name'), '+');
                const dependentTreatmentNames = _.join(_.map(dependentPortion, 'name'), '+');

                if (poolMode === 0 && (!principalPortion.length || !dependentPortion.length)) {
                  poolMode = 2;
                }

                let principalSummaryRecord = null;
                if (principalTreatmentNames) {
                  principalSummaryRecord = _.find(
                    principalSummaries,
                    (x) => x.key === principalTreatmentNames
                  );

                  if (!principalSummaryRecord) {
                    principalSummaryRecord = new BenefitSummary(principalTreatmentNames);
                    principalSummaries.push(principalSummaryRecord);
                  }
                }

                let dependentSummaryRecord = null;
                if (dependentTreatmentNames) {
                  dependentSummaryRecord = _.find(
                    dependentSummaries,
                    (x) => x.key === dependentTreatmentNames
                  );

                  if (!dependentSummaryRecord) {
                    dependentSummaryRecord = new BenefitSummary(dependentTreatmentNames);
                    dependentSummaries.push(dependentSummaryRecord);
                  }
                }

                // Get modifier description

                let str = '';
                const strValue = _.isNil(modifier.value) ? '?' : modifier.value;
                // eslint-disable-next-line default-case
                switch (modifier.type) {
                  case 0:
                    str += ` ${ccyFilter(modifier.value, vm.currencyCode)}`;
                    break;
                  case 1:
                    str += ` ${strValue} ${App.localize('VisitLimit').toLowerCase()}`;
                    break;
                  case 2:
                    str += ` ${ccyFilter(modifier.value, vm.currencyCode)} (${App.localize(
                      'BudgetOverdraft'
                    ).toLowerCase()})`;
                    break;
                  case 3:
                    str += ` ${strValue}${App.localize('VisitOverdraft').toLowerCase()}`;
                    break;
                  // no default
                }

                let cycle = '';
                // eslint-disable-next-line default-case
                switch (modifier.cycle) {
                  case 0:
                    cycle = App.localize('XYears', modifier.interval).toLowerCase();
                    break;
                  case 1:
                    cycle = App.localize('Yearly').toLowerCase();
                    break;
                  case 2:
                    cycle = App.localize('PerEmploymentContract').toLowerCase();
                    break;
                  // no default
                }
                str += ` ${cycle}`;

                let allStr;

                if (poolMode === 0) {
                  allStr = str;
                  if (principalTreatmentNames !== dependentTreatmentNames) {
                    if (principalPortion.length) {
                      allStr += ` (${App.localize('Principal')}: ${principalTreatmentNames})`;
                    }
                    if (dependentPortion.length) {
                      allStr += ` (${App.localize('Dependent')}: ${dependentTreatmentNames})`;
                    }
                  }

                  if (principalSummaryRecord) {
                    principalSummaryRecord.addItem(
                      modifier.type,
                      cycle,
                      '[1]',
                      modifier.value || 0
                    );
                    showPrincipalFootnote = true;
                  }
                  if (dependentSummaryRecord) {
                    dependentSummaryRecord.addItem(
                      modifier.type,
                      cycle,
                      '[1]',
                      modifier.value || 0
                    );
                    showDependentFootnote = true;
                  }
                } else if (poolMode === 1) {
                  if (principalSummaryRecord) {
                    principalSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                  }
                  if (dependentSummaryRecord) {
                    dependentSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                  }
                } else if (poolMode === 2) {
                  if (principalSummaryRecord) {
                    principalSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                  }
                  if (dependentSummaryRecord) {
                    dependentSummaryRecord.addItem(
                      modifier.type,
                      cycle,
                      '[2]',
                      modifier.value || 0
                    );
                    showDependentFootnote = true;
                  }
                }
              }
            });
          }
        );

        // Print summary

        principal.forEach((x) => {
          if (x.unlimitedVisit || x.unlimitedBudget) {
            let summary = _.find(principalSummaries, (y) => y.key === x.treatment.name);
            if (!summary) {
              summary = new BenefitSummary(x.treatment.name);
              principalSummaries.push(summary);
            }

            if (x.unlimitedBudget && x.unlimitedVisit) {
              summary.addText(App.localize('Unlimited'));
            } else {
              if (x.unlimitedBudget) {
                summary.addText(App.localize('UnlimitedBudget'));
              }
              if (x.unlimitedVisit) {
                summary.addText(App.localize('UnlimitedVisit'));
              }
            }
          }
        });
        this.principalSummaries = principalSummaries;
        this.showPrincipalFootnote = showPrincipalFootnote;

        dependent.forEach((x) => {
          if (x.unlimitedVisit || x.unlimitedBudget) {
            let summary = _.find(dependentSummaries, (y) => y.key === x.treatment.name);
            if (!summary) {
              summary = new BenefitSummary(x.treatment.name);
              dependentSummaries.push(summary);
            }
            if (x.unlimitedBudget && x.unlimitedVisit) {
              summary.addText(App.localize('Unlimited'));
            } else {
              if (x.unlimitedBudget) {
                summary.addText(App.localize('UnlimitedBudget'));
              }
              if (x.unlimitedVisit) {
                summary.addText(App.localize('UnlimitedVisit'));
              }
            }
          }
        });
        this.dependentSummaries = dependentSummaries;
        this.showDependentFootnote = showDependentFootnote;
      }

      addModifier() {
        const modifier = new Modifier(this);
        if (vm.isHospitalization) {
          vm.supportPlan.hospitalizationSetting.modifiers.push(modifier);
        } else {
          vm.supportPlan.maternitySetting.modifiers.push(modifier);
        }
      }

      removeModifier(index) {
        abp.message.confirm(
          App.localize('DeleteBenefitModifierWarningMessage'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              $scope.$apply(() => {
                if (vm.isHospitalization) {
                  vm.supportPlan.hospitalizationSetting.modifiers.splice(index, 1);
                } else {
                  vm.supportPlan.maternitySetting.modifiers.splice(index, 1);
                }
                this.refreshResult();
              });
              return true;
            }

            return false;
          }
        );
      }

      addCoPay() {
        const coPay = new CoPay(this);
        if (vm.isHospitalization) {
          if (this.hospitalizationSetting.coPays == null) this.hospitalizationSetting.coPays = [];

          this.hospitalizationSetting.coPays.push(coPay);
        } else {
          if (this.maternitySetting.coPays == null) this.maternitySetting.coPays = [];

          this.maternitySetting.coPays.push(coPay);
        }
      }

      removeCoPay(index) {
        abp.message.confirm(
          App.localize('DeleteBenefitModifierWarningMessage'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              $scope.$apply(() => {
                if (vm.isHospitalization) {
                  this.hospitalizationSetting.coPays.splice(index, 1);
                  vm.hospitalizationCopayForm.$setDirty();
                } else {
                  this.maternitySetting.coPays.splice(index, 1);
                  vm.maternityCopayForm.$setDirty();
                }
                this.refreshResult();
              });
              return true;
            }

            return false;
          }
        );
      }

      // Post method

      toDto() {
        function getOptionalExclusionIds(exclusions) {
          return _(exclusions).filter('valid').map('id').value();
        }

        function getModifierDto(modifiers) {
          return _(modifiers)
            .map((m) => m.toDto())
            .value();
        }

        function getCoPayDto(coPays) {
          return _(coPays)
            .map((m) => m.toDto())
            .value();
        }

        function getMaternityBenefitSettingsDto(settings) {
          return {
            remarks: settings.remarks,
            isSpouseCovered: settings.isSpouseCovered === 1,
            admissionWaitingDays:
              settings.isMaternitybenefits === 1 ? settings.admissionWaitingDays : 0,
            motherMaxAge: settings.isHasMotherAgeLimit === 1 ? settings.motherMaxAge : 0,
            isUnlimitedDelivery: settings.isUnlimitedDelivery === 1,
            deliveryLimit: settings.isUnlimitedDelivery === 0 ? settings.deliveryLimit : 0,
            isDefaultExclusionsCovered: settings.isDefaultExclusionsCovered === 1,
            exclusionVersionId: settings.exclusionVersionId,
            coveredOptionalExclusions:
              settings.isOptionalExclusionsCovered === 1
                ? getOptionalExclusionIds(settings.coveredOptionalExclusions)
                : [],
            copays: getCoPayDto(settings.coPays),
            coPayExclusions: settings.coPayExclusions,
            coPayType: settings.coPayType,
            modifiers: getModifierDto(settings.modifiers),
            normalDelivery: {
              isAsCharged: settings.normalDelivery.amountType === 0,
              amount: settings.normalDelivery.amount,
              isCovered: settings.normalDelivery.amountType !== 2,
              isUnlimited: settings.normalDelivery.maxDaysTimesType === 1,
              coveredTimes: settings.normalDelivery.coveredTimes,
              coveredCycle: settings.normalDelivery.coveredCycle,
            },
            caesareanDelivery: {
              isAsCharged: settings.caesareanDelivery.amountType === 0,
              amount: settings.caesareanDelivery.amount,
              isCovered: settings.caesareanDelivery.amountType !== 2,
              isUnlimited: settings.caesareanDelivery.maxDaysTimesType === 1,
              coveredTimes: settings.caesareanDelivery.coveredTimes,
              coveredCycle: settings.caesareanDelivery.coveredCycle,
            },
            normalDeliveryTwinsAndAbove: {
              isAsCharged: settings.normalDeliveryTwinsAndAbove.amountType === 0,
              amount: settings.normalDeliveryTwinsAndAbove.amount,
              isCovered: settings.normalDeliveryTwinsAndAbove.amountType !== 2,
              isUnlimited: settings.normalDeliveryTwinsAndAbove.maxDaysTimesType === 1,
              coveredTimes: settings.normalDeliveryTwinsAndAbove.coveredTimes,
              coveredCycle: settings.normalDeliveryTwinsAndAbove.coveredCycle,
            },
            caesareanDeliveryTwinsAndAbove: {
              isAsCharged: settings.caesareanDeliveryTwinsAndAbove.amountType === 0,
              amount: settings.caesareanDeliveryTwinsAndAbove.amount,
              isCovered: settings.caesareanDeliveryTwinsAndAbove.amountType !== 2,
              isUnlimited: settings.caesareanDeliveryTwinsAndAbove.maxDaysTimesType === 1,
              coveredTimes: settings.caesareanDeliveryTwinsAndAbove.coveredTimes,
              coveredCycle: settings.caesareanDeliveryTwinsAndAbove.coveredCycle,
            },
            preNatalCheckUp: {
              isCovered: settings.preNatalCheckUp.isCovered === 1,
              coveredDays: settings.preNatalCheckUp.coveredDays,
              isAsCharged: settings.preNatalCheckUp.amountType === 0,
              amount: settings.preNatalCheckUp.amount,
              isUnlimited: settings.preNatalCheckUp.maxDaysTimesType === 1,
              coveredTimes: settings.preNatalCheckUp.coveredTimes,
              coveredCycle: settings.preNatalCheckUp.coveredCycle,
            },
            postNatalFollowUp: {
              isCovered: settings.postNatalFollowUp.isCovered === 1,
              coveredDays: settings.postNatalFollowUp.coveredDays,
              isAsCharged: settings.postNatalFollowUp.amountType === 0,
              amount: settings.postNatalFollowUp.amount,
              isUnlimited: settings.postNatalFollowUp.maxDaysTimesType === 1,
              coveredTimes: settings.postNatalFollowUp.coveredTimes,
            },
            miscarriage: {
              isAsCharged: settings.miscarriage.amountType === 0,
              amount: settings.miscarriage.amount,
              isCovered: settings.miscarriage.amountType !== 2,
            },
            neoNatal: {
              isCovered: settings.neoNatal.isCovered === 1,
              coveredDays: settings.neoNatal.coveredDays,
              newBornNicuAmount: settings.neoNatal.newBornNicuAmount,
              newBornIncubationAmount: settings.neoNatal.newBornIncubationAmount,
              newBornPhotoTherapyAmount: settings.neoNatal.newBornPhotoTherapyAmount,
            },
            isCoInsurance: settings.isCoInsurance,
          };
        }

        function getHospitalizationBenefitSettingsDto(settings) {
          let dependentSettings =
            settings.hasDependentCovered === 0 ? null : settings.dependentScheduleOfBenefit;
          if (
            vm.isCreate &&
            settings.hasDependentCovered === 1 &&
            settings.enableDependentSetting === 0
          ) {
            dependentSettings = settings.principalScheduleOfBenefit;
          }

          return {
            remarks: settings.remarks,
            hasDependentCovered:
              settings.hasDependentCovered === 1 ? settings.hasDependentCovered : 0,
            admissionWaitingAccidentDays:
              settings.isAccidentAdmission === 1 ? settings.admissionWaitingAccidentDays : 0,
            admissionWaitingIllnessDays:
              settings.isIllnessAdmission === 1 ? settings.admissionWaitingIllnessDays : 0,
            admissionWaitingPreExistingDays:
              settings.isPreExisting === 1 ? settings.admissionWaitingPreExistingDays : 0,
            admissionWaitingNonAccidentalIllnessDays:
              settings.isNonAccidentalIllness === 1
                ? settings.admissionWaitingNonAccidentalIllnessDays
                : 0,
            exclusionVersionId: settings.exclusionVersionId,
            isDefaultExclusionsCovered: settings.isDefaultExclusionsCovered === 1,
            coveredOptionalExclusions: getOptionalExclusionIds(settings.coveredOptionalExclusions),
            isCoInsurance: settings.isCoInsurance,
            isRoomAndBoardExceedEntitlement: settings.isRoomAndBoardExceedEntitlement,
            copays: getCoPayDto(settings.coPays),
            coPayExclusions: settings.coPayExclusions,
            coPayType: settings.coPayType,
            modifiers: getModifierDto(settings.modifiers),
            principalScheduleOfBenefit: settings.principalScheduleOfBenefit,
            dependentScheduleOfBenefit: dependentSettings,
          };
        }

        let hospitalizationSetting = null;
        let maternitySettings = null;
        if (vm.isHospitalization) {
          hospitalizationSetting = getHospitalizationBenefitSettingsDto(
            this.hospitalizationSetting
          );
        } else {
          maternitySettings = getMaternityBenefitSettingsDto(this.maternitySetting);
        }

        return {
          id: this.id,
          tenantId: this.tenantId,
          serviceType: this.serviceType,
          name: this.name,
          displayName: this.displayName,
          hospitalizationSetting,
          maternitySetting: maternitySettings,
        };
      }
    }

    init();

    function init() {
      if (vm.hasInpatientRegionalExpansion) getCountryAndCurrency();
      getSupportPlan();
    }

    function getCountryAndCurrency() {
      vm.loading += 1;
      commonLookupSvc
        .getCountryAndCurrencyByTenantId({ tenantId: vm.organizationId })
        .success((data) => {
          if (data.currency.code) vm.currencyCode = data.currency.code;
          if (data.countryCode) vm.countryCode = data.countryCode;
          vm.costSharingExample = App.localize('CostSharingExample', vm.currencyCode);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getSupportPlan() {
      vm.loading += 1;
      benefitGroupSvc
        .getInpatientBenefitGroupForEdit({
          id: vm.inpatientBenefitGroupId,
          organizationId: vm.organizationId,
          serviceType: vm.serviceType,
        })
        .success((data) => {
          vm.isHospitalization = data.benefitGroup.serviceType === 7;
          
          if (isCopyAction) {
            data.benefitGroup.id = 0;
            data.benefitGroup.name = null;
            data.benefitGroup.displayName = null;
            if (vm.isHospitalization)
              data.benefitGroup.hospitalizationSetting.remarks = null;
            else
              data.benefitGroup.maternitySetting.remarks = null;
          }

          vm.optionalExclusions = data.optionalExclusions;
          vm.supportPlan = new BenefitGroupModel(data.benefitGroup);
          vm.categories = data.categories;
          vm.insurers = data.insurers;
          if (vm.isHospitalization) {
            vm.enableCostSharingHospitalization =
              vm.supportPlan.hospitalizationSetting.isCoInsurance == null ? 0 : 1;
            vm.dependentSettingPlaceHolder = angular.copy(
              vm.supportPlan.hospitalizationSetting.dependentScheduleOfBenefit
            );

            if (vm.hasTxtFileSupport) {
              // Filter SOB claim items

              const hospitalizationItems = [
                'RoomAndBoard',
                'IcuRoomAndBoard',
                'ChildGuardianBenefit',
                'InHospitalDoctorVisit',
                'SurgicalFee',
                'AnaesthetistFee',
                'OrganTransplant',
                'DaySurgery',
                'OperatingTheatre',
                'HospitalSuppliesAndServices',
                'AmbulanceFee',
                'MedicalReport',
              ];

              vm.categories = _.filter(vm.categories, (x) =>
                hospitalizationItems.includes(x.value)
              );

              if (vm.enableCostSharingHospitalization === 1) {
                // Copy coPayExclusions to coPayExclusionCategories

                const clonedHospitalizationCoPayExclusions =
                  vm.supportPlan.hospitalizationSetting.coPayExclusions.slice(0);
                vm.supportPlan.hospitalizationSetting.coPayExclusionCategories = [];
                _.each(clonedHospitalizationCoPayExclusions, (x) => {
                  const category = _.find(vm.categories, (c) => c.value === x);
                  vm.supportPlan.hospitalizationSetting.coPayExclusionCategories.push(category);
                });

                // Set cost-sharing type if editing previous copay setting.

                if (
                  !_.isNil(vm.supportPlan.hospitalizationSetting.isCoInsurance) &&
                  _.isNil(vm.supportPlan.hospitalizationSetting.coPayType)
                )
                  vm.supportPlan.hospitalizationSetting.coPayType = vm.supportPlan
                    .hospitalizationSetting.isCoInsurance
                    ? 1
                    : 2;
              }
            }
          } else {
            vm.enableCostSharingMaternity =
              vm.supportPlan.maternitySetting.isCoInsurance == null ? 0 : 1;

            if (vm.hasTxtFileSupport) {
              // Filter SOB claim items

              vm.categories = _.filter(
                vm.categories,
                (x) =>
                  x.value === 'RoomAndBoard' ||
                  x.value === 'IcuRoomAndBoard' ||
                  x.value === 'ChildGuardianBenefit' ||
                  x.value === 'NormalDelivery' ||
                  x.value === 'CaesareanDelivery' ||
                  x.value === 'NormalDeliveryTwinsAndAbove' ||
                  x.value === 'CaesareanDeliveryTwinsAndAbove' ||
                  x.value === 'SurgicalFee' ||
                  x.value === 'AnaesthetistFee' ||
                  x.value === 'OperatingTheatre' ||
                  x.value === 'HospitalSuppliesAndServices'
              );

              if (vm.enableCostSharingMaternity === 1) {
                // Copy coPayExclusions to coPayExclusionCategories

                const clonedMaternityCoPayExclusions =
                  vm.supportPlan.maternitySetting.coPayExclusions.slice(0);
                vm.supportPlan.maternitySetting.coPayExclusionCategories = [];
                _.each(clonedMaternityCoPayExclusions, (x) => {
                  const category = _.find(vm.categories, (c) => c.value === x);
                  vm.supportPlan.maternitySetting.coPayExclusionCategories.push(category);
                });

                // Set cost-sharing type if editing previous copay setting.

                if (
                  vm.supportPlan.maternitySetting.isCoInsurance &&
                  _.isNil(vm.supportPlan.maternitySetting.coPayType)
                )
                  vm.supportPlan.maternitySetting.coPayType = 1;
              }
            }
          }
          vm.supportPlan.refreshResult();
          vm.supportPlan.hasMultipleVersion = data.hasMultipleVersion;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function submit() {
      if (!App.isFormValid($scope.supportPlanCreateEditForm)) {
        abp.notify.error(App.localize('GeneralInvalidFormInputError'));
        return;
      }

      if (vm.enableCostSharingHospitalization === 0) {
        vm.supportPlan.hospitalizationSetting.isCoInsurance = null;
        vm.supportPlan.hospitalizationSetting.isRoomAndBoardExceedEntitlement = null;
        vm.supportPlan.hospitalizationSetting.coPays = [];
        vm.supportPlan.hospitalizationSetting.coPayExclusions = [];
        vm.supportPlan.hospitalizationSetting.coPayType = null;
      } else if (vm.hasTxtFileSupport && vm.enableCostSharingHospitalization === 1) {
        // Copy coPayExclusionCategories to coPayExclusions

        const clonedHospitalizationCoPayExclusionCategories =
          vm.supportPlan.hospitalizationSetting.coPayExclusionCategories.slice(0);
        vm.supportPlan.hospitalizationSetting.coPayExclusions = [];
        vm.supportPlan.hospitalizationSetting.coPayExclusions = _.map(
          clonedHospitalizationCoPayExclusionCategories,
          (x) => x.value
        );

        // Set value for isRoomAndBoardExceedEntitlement if null.

        if (vm.supportPlan.hospitalizationSetting.isRoomAndBoardExceedEntitlement == null)
          vm.supportPlan.hospitalizationSetting.isRoomAndBoardExceedEntitlement = false;
      }

      if (vm.enableCostSharingMaternity === 0) {
        vm.supportPlan.maternitySetting.isCoInsurance = null;
        vm.supportPlan.maternitySetting.coPays = [];
        vm.supportPlan.maternitySetting.coPayExclusions = [];
        vm.supportPlan.maternitySetting.coPayType = null;
      } else if (vm.hasTxtFileSupport && vm.enableCostSharingMaternity === 1) {
        // Copy coPayExclusionCategories to coPayExclusions

        const clonedMaternityCoPayExclusionCategories =
          vm.supportPlan.maternitySetting.coPayExclusionCategories.slice(0);
        vm.supportPlan.maternitySetting.coPayExclusions = [];
        vm.supportPlan.maternitySetting.coPayExclusions = _.map(
          clonedMaternityCoPayExclusionCategories,
          (x) => x.value
        );
      }

      vm.saving += 1;
      benefitGroupSvc
        .createOrUpdateInpatientBenefitGroup(vm.supportPlan.toDto())
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $state.go('host.corporateInpatientSupportPlan', { id: vm.organizationId });
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function automap(source, target) {
      _.forIn(source, (v, k) => {
        target[k] = source[k];
      });
    }

    function buildExclusionTypeList(optionalExclusions, coveredOptionalExclusions) {
      const exclusions = [];
      let coveredExclusions = coveredOptionalExclusions;
      if (!coveredOptionalExclusions) coveredExclusions = [];

      _.each(optionalExclusions, (exc) => {
        exclusions.push(
          new ExclusionModel(
            exc.value,
            exc.name,
            _.indexOf(coveredExclusions, exc.value) >= 0,
            exc.isLatestVersion
          )
        );
      });

      return exclusions;
    }

    function ExclusionModel(id, name, valid, isLatestVersion) {
      this.id = id;
      this.name = name;
      this.valid = valid;
      this.isLatestVersion = isLatestVersion;
    }

    function TreatmentModel(id, name, icon, valid) {
      this.id = id;
      this.name = name;
      this.icon = icon;
      this.valid = valid;
    }

    function TreatmentApplyModel(treatment, applicable) {
      this.treatment = treatment;
      this.applicable = applicable;
    }

    function BenefitResult(treatment, unlimitedBudget, unlimitedVisit) {
      this.treatment = treatment;
      this.unlimitedBudget = unlimitedBudget;
      this.unlimitedVisit = unlimitedVisit;
    }

    function BenefitSummaryItem(type, cycle, poolMode, value) {
      this.type = type;
      this.cycle = cycle;
      this.poolMode = poolMode;
      this.value = value;

      this.toString = () => {
        let str = '';
        const strValue = _.isNil(this.value) ? '?' : this.value;
        // eslint-disable-next-line default-case
        switch (this.type) {
          case 0:
            str += vm.currencyCode + strValue;
            break;
          case 1:
            str += `${strValue} ${this.value > 1 ? 'visits' : 'visit'}`;
            break;
          case 2:
            str += `${vm.currencyCode + strValue} overdraft`;
            break;
          case 3:
            str += `${strValue} overdraft ${this.value > 1 ? 'visits' : 'visit'}`;
            break;
          // no default
        }

        str += ` ${this.cycle} ${this.poolMode}`;
        return str;
      };
    }

    function BenefitSummary(key, value) {
      this.items = [];
      this.texts = [];

      this.key = key;
      this.value = value;

      this.addItem = (type, cycle, poolMode, itemValue) => {
        const item = _.find(
          this.items,
          (x) => x.type === type && x.cycle === cycle && x.poolMode === poolMode
        );

        if (!item) {
          this.items.push(new BenefitSummaryItem(type, cycle, poolMode, itemValue));
        } else {
          item.value += itemValue;
        }
      };

      this.addText = (text) => {
        this.texts.push(text);
      };

      this.toString = () =>
        _.concat(
          _.map(this.items, (x) => x.toString()),
          this.texts
        ).join(', ');
    }
  }
})();