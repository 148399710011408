(() => {
  angular.module('app').component('commonInpatientTicketsDetailDoctorProcedureChargesComponent', {
    templateUrl: require('./doctorProcedureCharges.component.html'),
    controller: DoctorProcedureChargesController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      doctors: '<',
      isEdit: '<',
      isSaved: '<',
      categories: '<',
      isNpr: '<',
      panelLocationDoctors: '<',
      benefits: '<',
      fixedLimitBalance: '=',
      isCreate: '<',
      admissionDate: '@',
      dischargeDate: '@',
      currencyCode: '<',
      isClaim: '<',
    },
  });

  DoctorProcedureChargesController.$inject = [
    'abp.services.app.commonLookup',
    'Hms.ServiceRequests.ServiceRequestType',
    'moment',
  ];

  function DoctorProcedureChargesController(commonLookupSvc, enumServiceRequestType, moment) {
    const vm = this;

    vm.loading = 0;
    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
    vm.hasInpatientClaimProcessingEnhancement = abp.setting.getBoolean(
      'Hms.Feature.InpatientClaimProcessingEnhancement'
    );
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );
    vm.categoryItemOptions = [];
    const limitSize = 25;
    vm.formattedCategories = [];
    const defaultTicketItem = {
      category: '',
      isManualEdit: 0,
      ticketItems: [
        {
          subTreatments: [],
          procedures: [],
          visitDate: moment(vm.ticket.admissionDate),
        },
      ],
    };
    vm.isInpatientTicketV2 = vm.hasInsuranceEnhancementModule && vm.ticket.version === 'V2';

    vm.enums = {
      serviceRequestType: enumServiceRequestType,
    };

    vm.$onChanges = onChanges;
    vm.addCategory = addCategory;
    vm.addProcedures = addProcedures;
    vm.clearProcedures = clearProcedures;
    vm.deleteItem = deleteItem;
    vm.updateProcedures = updateProcedures;
    vm.getCategoryName = getCategoryName;
    vm.getDoctorName = getDoctorName;
    vm.calculateTotal = calculateTotal;
    vm.calculateAmount = calculateAmount;
    vm.getAmount = getAmount;
    vm.manualEdit = manualEdit;
    vm.cancelManualEdit = cancelManualEdit;
    vm.saveManualEdit = saveManualEdit;
    vm.lookup13thScheduleProcedureItems = lookup13thScheduleProcedureItems;
    vm.updateVisit = updateVisit;
    vm.updateCategory = updateCategory;
    vm.updateSubCategory = updateSubCategory;
    vm.fulfillV2ViewCondition = fulfillV2ViewCondition;
    vm.getSubtotalAmount = getSubtotalAmount;
    vm.getTotalCoveredAmount = getTotalCoveredAmount;
    vm.getTotalUncoveredAmount = getTotalUncoveredAmount;

    init();

    function init() {
      vm.ticket.doctorProcedures = _.chain(vm.ticket.doctorProcedures)
        .groupBy('category')
        .map((value, key) => ({
          category: key,
          ticketItems: value,
          name: _.first(value).categoryName,
          isManualEdit: 0,
        }))
        .value();
      refreshOnTicketChange();

      getMasterBillingCodeItems();
      get13thScheduleProcedureItems();

      if (vm.hasInsuranceEnhancementModule) formatCategories();
      
      // Map the filtered sub-categories and its procedures during edit

      if (vm.ticket.doctorProcedures.length > 0) {
        vm.ticket.doctorProcedures.forEach((x, i) => updateCategory(i, x));
      }
    }

    function refreshOnTicketChange() {
      if (!vm.ticket || !vm.ticket.doctorProcedures) return;
      if (vm.ticket.doctorProcedures.length === 0) {
        if (vm.isEdit) addCategory();
      } else {
        _.each(vm.ticket.doctorProcedures, (x) => {
          _.each(x.ticketItems, (ticketItem) => {
            const ti = ticketItem;
            if (ti.amount !== undefined && ti.uncoveredAmount !== undefined)
              ti.totalCharges = ti.amount + ti.uncoveredAmount;

            ti.subTreatment = ti.subCategory;
            ti.isOverride = 0;
          });
        });
      }
    }

    function onChanges(changes) {
      if (changes.ticket && changes.ticket.currentValue) {
        refreshOnTicketChange();
      }

      if (changes.isSaved && changes.isSaved.currentValue) {
        tidyProcedures();
      }

      if (changes.isEdit && changes.isEdit.currentValue) {
        if (vm.ticket && vm.ticket.doctorProcedures.length < 1) {
          addCategory();
        }
      }

      if (vm.ticket.doctorProcedures.length > 0) {
        enrichTicketItem();
      }
    }

    function enrichTicketItem() {
      _.forEach(vm.ticket.doctorProcedures, (procedure) => {
        _.forEach(procedure.ticketItems, (item) => {
          const i = item;
          const visitDate = i.startDate != null ? i.startDate : vm.ticket.admissionDate;
          i.visitDate = moment(visitDate);
          i.totalCharges = i.amount + i.uncoveredAmount;
          i.isOverride = 0;
        });
      });
    }

    function addCategory(type) {
      const categoryItem = vm.hasInsuranceEnhancementModule
        ? JSON.parse(JSON.stringify(defaultTicketItem))
        : {
            category: type || null,
            ticketItems: [
              {
                category: type || null,
                categoryName: type ? getCategoryName(type) : null,
                isOverride: 0,
              },
            ],
            isManualEdit: 0,
          };
      vm.ticket.doctorProcedures.push(categoryItem);
    }

    function addProcedures(record) {
      if (vm.hasInsuranceEnhancementModule) {
        const newTicketItem = JSON.parse(JSON.stringify(defaultTicketItem.ticketItems[0]));

        // Format new ticket item.

        newTicketItem.category = record.category;

        // Add sub treatments items.

        if (record.category) {
          const category = _.filter(
            vm.formattedCategories,
            (f) => f.mainTreatmentCategoryName === record.category
          );

          newTicketItem.subTreatments = category[0].subTreatmentCategories.map(
            (s) => s.subTreatmentItemName
          );
          newTicketItem.section = category[0].sectionType;
        }

        // Add new ticket item.

        record.ticketItems.push(newTicketItem);
      } else {
        const r = record;
        if (typeof r.category === 'undefined' || r.category === null) return;

        if (!r.ticketItems) {
          r.ticketItems = [];
        }

        r.ticketItems.push({
          category: r.category,
          categoryName: getCategoryName(r.category),
          isOverride: 0,
          masterBillingCode: r.category === 'OrganTransplant' ? 'OrganTransplant' : null,
        });
      }
    }

    function clearProcedures(category) {
      const c = category;
      c.ticketItems = [];
      addProcedures(c);
    }

    function tidyProcedures() {
      vm.ticket.doctorProcedures = _.filter(vm.ticket.doctorProcedures, (item) =>
        _.filter(item.ticketItems, (k) => !_.isNil(k.amount))
      );
    }

    function calculateTotal(index, item) {
      const i = item;
      let uncoveredAmount = 0;
      let amount = 0;
      if (i[index].amount > 0) {
        amount = i[index].amount;
      }
      if (i[index].uncoveredAmount > 0) {
        uncoveredAmount = i[index].uncoveredAmount;
      }
      const totalCharges = amount + uncoveredAmount;
      i[index].totalCharges = totalCharges;
    }

    function calculateAmount(index, item, parentIndex) {
      const i = item;
      vm.ticket.doctorProcedures[parentIndex].ticketItems[index].isOverride = 0;

      let category = '';

      if (i[index].category) category = i[index].category;

      i[index].hasExceeded = false;
      i[index].isUncovered = false;

      // Validate amount.

      validateTotalAmountByCategory(category);
    }

    function validateTotalAmountByCategory(category) {
      const oldCategories = ['SurgicalFee', 'AnaesthetistFee', 'OrganTransplant', 'DaySurgery'];

      let totalAmount = 0;
      let fixedLimitTotalAmount = 0;
      let hasFixedLimitItems = false;

      _.each(vm.ticket.doctorProcedures, (x) => {
        _.each(x.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          let balanceAmount = 0;
          let totalCharges = 0;
          let coveredTotalCharges = 0;
          let uncoveredTotalCharges = 0;
          let limitAmount = 0;
          let isFixedLimit = false;
          let isAsCharged = false;

          if (ti.totalCharges > 0) totalCharges = ti.totalCharges;

          if (vm.hasInsuranceEnhancementModule && !oldCategories.includes(ti.category)) {
            ti.amount = totalCharges;
            ti.uncoveredAmount = 0;
            return;
          }

          const benefitByLimit = _.first(
            _.filter(vm.benefits, (b) => b.category === ti.category && b.type === 0)
          );

          if (ti.isOverride === 1) return;

          let isCovered;

          switch (ti.category) {
            case 'SurgicalFee':
            case 'AnaesthetistFee':
              isFixedLimit = (benefitByLimit && benefitByLimit.isFixedLimit) || false;
              hasFixedLimitItems = isFixedLimit;
              limitAmount = (benefitByLimit && benefitByLimit.limitAmount) || 0;

              if (isFixedLimit)
                balanceAmount =
                  fixedLimitTotalAmount < limitAmount ? limitAmount - fixedLimitTotalAmount : 0;
              else balanceAmount = totalAmount < limitAmount ? limitAmount - totalAmount : 0;

              isAsCharged = (benefitByLimit && benefitByLimit.isAsCharged) || false;
              if (!isAsCharged) {
                if (totalCharges > balanceAmount) {
                  coveredTotalCharges = balanceAmount;
                  uncoveredTotalCharges = totalCharges - balanceAmount;

                  ti.amount = coveredTotalCharges;
                  ti.uncoveredAmount = uncoveredTotalCharges;

                  if (totalCharges) ti.hasExceeded = true;
                } else {
                  coveredTotalCharges = totalCharges;

                  ti.amount = coveredTotalCharges;
                  ti.uncoveredAmount = uncoveredTotalCharges;
                }
              } else {
                coveredTotalCharges = totalCharges;

                ti.amount = coveredTotalCharges;
                ti.uncoveredAmount = uncoveredTotalCharges;
              }

              break;

            case 'OrganTransplant':
            case 'DaySurgery':
              isCovered = !_.isNil(benefitByLimit);
              if (isCovered) {
                limitAmount = (benefitByLimit && benefitByLimit.limitAmount) || 0;
                balanceAmount = totalAmount < limitAmount ? limitAmount - totalAmount : 0;

                isAsCharged = (benefitByLimit && benefitByLimit.isAsCharged) || false;
                if (!isAsCharged) {
                  if (totalCharges > balanceAmount) {
                    coveredTotalCharges = balanceAmount;
                    uncoveredTotalCharges = totalCharges - balanceAmount;

                    ti.amount = coveredTotalCharges;
                    ti.uncoveredAmount = uncoveredTotalCharges;

                    if (totalCharges) ti.hasExceeded = true;
                  } else {
                    coveredTotalCharges = totalCharges;

                    ti.amount = coveredTotalCharges;
                    ti.uncoveredAmount = uncoveredTotalCharges;
                  }
                } else {
                  coveredTotalCharges = totalCharges;

                  ti.amount = coveredTotalCharges;
                  ti.uncoveredAmount = uncoveredTotalCharges;
                }
              } else {
                uncoveredTotalCharges = totalCharges;

                ti.amount = coveredTotalCharges;
                ti.uncoveredAmount = uncoveredTotalCharges;

                if (totalCharges) ti.isUncovered = true;
              }

              break;

            default:
              break;
          }

          if (isFixedLimit) {
            fixedLimitTotalAmount += totalCharges;
            vm.fixedLimitBalance =
              totalAmount < fixedLimitTotalAmount ? limitAmount - fixedLimitTotalAmount : 0;
          } else if (ti.category === category) {
            totalAmount += totalCharges;
          }
        });
      });

      if (hasFixedLimitItems) calculateFixedLimitItems();
    }

    function calculateFixedLimitItems() {
      let fixedLimitTotalAmount = 0;

      _.each(vm.ticket.hospitalCharges, (x) => {
        _.each(x.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          let balanceAmount = 0;
          let totalCharges = 0;
          let coveredTotalCharges = 0;
          let uncoveredTotalCharges = 0;
          let limitAmount = 0;

          ti.hasExceeded = false;
          ti.isUncovered = false;

          if (ti.totalCharges > 0) totalCharges = ti.totalCharges;

          if (ti.isOverride === 1) return;

          switch (ti.category) {
            case 'OperatingTheatre':
            case 'HospitalSuppliesAndServices':
              limitAmount = vm.fixedLimitBalance;
              balanceAmount =
                fixedLimitTotalAmount < limitAmount ? limitAmount - fixedLimitTotalAmount : 0;

              if (totalCharges > balanceAmount) {
                coveredTotalCharges = balanceAmount;
                uncoveredTotalCharges = totalCharges - balanceAmount;

                ti.amount = coveredTotalCharges;
                ti.uncoveredAmount = uncoveredTotalCharges;

                if (totalCharges) ti.hasExceeded = true;
              } else {
                coveredTotalCharges = totalCharges;

                ti.amount = coveredTotalCharges;
                ti.uncoveredAmount = uncoveredTotalCharges;
              }

              break;

            default:
              break;
          }

          fixedLimitTotalAmount += totalCharges;
        });
      });
    }

    function getAmount() {
      let amount = 0;
      let uncoveredAmount = 0;

      _.each(vm.ticket.doctorProcedures, (procedure) => {
        const x = procedure;
        x.showExceededMessage = false;
        x.showUncoveredMessage = false;

        _.each(x.ticketItems, (p) => {
          if (p.amount) amount += p.amount;

          if (p.uncoveredAmount) uncoveredAmount += p.uncoveredAmount;

          if (p.hasExceeded) x.showExceededMessage = true;

          if (p.isUncovered) x.showUncoveredMessage = true;
        });
      });

      return {
        subtotalAmount: App.roundAmount(amount + uncoveredAmount),
        totalCoveredAmount: App.roundAmount(amount),
        totalUncoveredAmount: App.roundAmount(uncoveredAmount),
      };
    }

    function updateProcedures(index, item) {
      const i = item;
      // Update item dropdown options based on selected category.

      i.categoryItemOptions = getItemOptionsByCategory(i.category);

      // Update procedures when category change.

      if (typeof i.category === 'undefined' || i.category === null) {
        if (vm.ticket.doctorProcedures.length > 1) {
          vm.ticket.doctorProcedures.splice(index, 1);
        } else {
          vm.ticket.doctorProcedures = [];
          addCategory();
        }
      } else {
        const hospitalizationDates = getHospitalizationDates();

        if (hospitalizationDates.length > 0 && i.ticketItems.length < hospitalizationDates.length) {
          i.ticketItems = [];

          _.forEach(hospitalizationDates, (date) => {
            i.ticketItems.push(
              {
                visitDate: date,
                visit: 1,
              },
              {
                visitDate: date,
                visit: 2,
              }
            );
          });
        }

        if (i.ticketItems) {
          _.each(i.ticketItems, (ticketItem) => {
            const ti = ticketItem;
            ti.category = i.category;
            ti.categoryName = getCategoryName(i.category);
            ti.masterBillingCode = i.category === 'OrganTransplant' ? 'OrganTransplant' : null;
            ti.totalVisit = 1;
          });
        }
      }
    }

    function deleteItem(index, item) {
      const i = item;
      if (i.ticketItems.length > 1) {
        i.ticketItems.splice(index, 1);
      } else {
        i.ticketItems = [];
        addProcedures(i);
      }
    }

    function getDoctorName(id) {
      const idToMatch = parseInt(id, 10);
      if (vm.panelLocationDoctors) {
        const selectedDoctor = _.find(vm.panelLocationDoctors, (d) => d.id === idToMatch);

        if (selectedDoctor) {
          return selectedDoctor.name;
        }
      }
      return null;
    }

    function manualEdit(index) {
      vm.tempPlaceHolder = angular.copy(vm.ticket.doctorProcedures[index]);
      vm.ticket.doctorProcedures[index].isManualEdit = 1;
    }

    function cancelManualEdit(index) {
      vm.ticket.doctorProcedures[index] = angular.copy(vm.tempPlaceHolder);
      vm.ticket.doctorProcedures[index].isManualEdit = 0;
      vm.tempPlaceHolder = null;
    }

    function saveManualEdit(index) {
      vm.ticket.doctorProcedures[index].isManualEdit = 0;
      vm.tempPlaceHolder = null;

      _.each(vm.ticket.doctorProcedures[index].ticketItems, (ticketItem) => {
        const p = ticketItem;
        p.isOverride = 1;
      });
    }

    function getCategoryName(category) {
      const selectedCategory = _.find(vm.categories, { id: category });
      return selectedCategory ? selectedCategory.displayName : null;
    }

    // Get master billing codes based on given parent code or code.

    function getMasterBillingCodeItems() {
      const parentCodes = [
        'AnaesthetistFee',
        'SurgicalFee',
        'DaySurgery',
        'OperatingTheatre',
        'OrganTransplant',
        'DoctorProcedures',
      ];

      vm.loading += 1;
      commonLookupSvc
        .getMasterBillingCodesByCodeList(parentCodes)
        .success((data) => {
          vm.itemOptions = data;

          _.each(vm.ticket.doctorProcedures, (procedure) => {
            const x = procedure;
            x.categoryItemOptions = getItemOptionsByCategory(x.category);
          });

          // To get master billing code description

          if (vm.ticket.doctorProcedures.length > 0 && vm.ticket.doctorProcedures[0].category) {
            _.each(vm.ticket.doctorProcedures, (procedure) => {
              _.each(procedure.ticketItems, (ticketItem) => {
                const i = ticketItem;
                const procedureItem = _.find(vm.itemOptions, (x) => x.code === i.masterBillingCode);

                if (!procedureItem) {
                  i.unmatchedMasterBillingCode = i.masterBillingCode;
                  i.masterBillingCode = null;
                }

                if (procedureItem) i.description = procedureItem.description;
              });
            });
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Filter master billing codes list based on given category.

    function getItemOptionsByCategory(category) {
      if (vm.hasInpatientClaimProcessingEnhancement) {
        return _.filter(vm.itemOptions, (o) => {
          if (category !== 'OrganTransplant') {
            return o.parentCode === category || o.parentCode === 'DoctorProcedures';
          }
          return (
            (o.code === category && o.parentCode === null) || o.parentCode === 'DoctorProcedures'
          );
        });
      }
      return _.filter(vm.itemOptions, (o) => {
        if (category !== 'OrganTransplant') {
          return o.parentCode === category;
        }
        return o.code === category && o.parentCode === null;
      });
    }

    // Get 13th schedule's procedure items.

    function get13thScheduleProcedureItems() {
      vm.loading += 1;
      commonLookupSvc
        .get13thScheduleProcedureItems()
        .success((data) => {
          vm.procedureItemOptions = data;
          vm.filteredProcedures = _.take(vm.procedureItemOptions, limitSize);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function lookup13thScheduleProcedureItems(filter) {
      const keyword = _.trim(filter || '');
      if (keyword) {
        // Include free-text entry in filtered procedure set.

        const filteredProcedures = [
          {
            item: keyword,

            // Set free-text entry with high negative score so that it stays as first choice.
            score: -100,
          },
        ];

        // Removes non-alphanumerical characters.
        const sanitizedKeyword = keyword.replace(/[^0-9a-zA-Z]/g, '');
        const regexes = _.chain(sanitizedKeyword.split(' '))
          .map((k) => _.trim(k.toUpperCase()))
          .filter((k) => k)
          .uniq()
          .map((k) => {
            _.escapeRegExp(k);
            return {
              token: k,
              len: k.length,
              pattern: new RegExp(`\\b${k}`, 'i'),
            };
          })
          .value();

        _.forEach(vm.procedureItemOptions, (procedure) => {
          let score = 0;

          _.forEach(regexes, (r) => {
            if (r.pattern.test(procedure)) {
              score -= r.len;
            }
          });

          if (score) {
            filteredProcedures.push({
              score,
              item: procedure,
            });
          }
        });

        vm.filteredProcedures = _.chain(filteredProcedures)
          .sortBy(['score'])
          .take(25)
          .map((k) => k.item)
          .value();
      } else {
        get13thScheduleProcedureItems();
      }
    }

    function updateVisit() {
      _.each(vm.ticket.procedures, (x) => {
        const dates = [];

        _.each(x.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          if (ti.visitDate) {
            const recordDate = moment(ti.visitDate).startOf('day').toString();
            dates.push(recordDate);

            const countVisit = _.filter(dates, (s) => recordDate === s).length;

            ti.visit = countVisit;
          }
        });
      });
    }

    function getHospitalizationDates() {
      const hospitalizationDates = [];
      let minDate = null;
      let maxDate = null;

      _.each(vm.ticket.rooms, (x) => {
        _.each(x.ticketItems, (p) => {
          if (p.roomChargeDateRangeModel) {
            const startDate = moment(p.roomChargeDateRangeModel.startDate);
            const endDate = moment(p.roomChargeDateRangeModel.endDate);

            for (
              let currentDate = moment(startDate);
              currentDate <= endDate;
              currentDate = currentDate.add(1, 'day')
            ) {
              let isNewRecord = true;
              const currentRecord = moment(currentDate);

              if (hospitalizationDates.length > 0) {
                _.each(hospitalizationDates, (date) => {
                  if (date.isSame(currentRecord)) {
                    isNewRecord = false;
                  }
                });
              }

              if (isNewRecord) {
                hospitalizationDates.push(currentRecord);
                if (currentDate < minDate || minDate === null) {
                  minDate = moment(currentRecord);
                }

                if (currentDate > maxDate || maxDate === null) {
                  maxDate = moment(currentRecord);
                }
              }
            }
          }
        });
      });

      return hospitalizationDates;
    }

    function formatCategories() {
      // Get main categories.

      _.forEach(vm.categories, (x) => {
        if (
          vm.formattedCategories
            .map((e) => e.mainTreatmentCategoryName)
            .indexOf(x.mainTreatmentCategoryName) < 0
        ) {
          vm.formattedCategories.push({
            mainTreatmentCategoryName: x.mainTreatmentCategoryName,
            sectionType: x.sectionType,
            subTreatmentCategories: [],
          });
        }
      });

      // Get sub categories.

      _.forEach(vm.formattedCategories, (x) => {
        const subTreatments = _.filter(
          vm.categories,
          (c) => c.mainTreatmentCategoryName === x.mainTreatmentCategoryName
        );

        _.forEach(subTreatments, (s) => {
          const keyValue = !s.subTreatmentItemName
            ? x.mainTreatmentCategoryName
            : s.subTreatmentItemName;
          const hasDuplicated = x.subTreatmentCategories.some(
            (subTreatmentCategory) => subTreatmentCategory.subTreatmentItemName === keyValue
          );

          if (!hasDuplicated) {
            x.subTreatmentCategories.push({
              subTreatmentItemName: keyValue,
              procedures: [],
            });
          }
        });
      });

      // Get procedures.

      _.forEach(vm.formattedCategories, (x) => {
        _.forEach(x.subTreatmentCategories, (s) => {
          const filteredMainTreatmentCategory = _.filter(vm.categories, (c) =>
            c.subTreatmentItemName
              ? c.subTreatmentItemName === s.subTreatmentItemName
              : c.mainTreatmentCategoryName === s.subTreatmentItemName
          );

          _.forEach(filteredMainTreatmentCategory, (mainCategory) => {
            const foundDuplicated = s.procedures.some((p) => p === mainCategory.procedures);
            if (mainCategory.procedures && !foundDuplicated) {
              s.procedures.push(mainCategory.procedures);
            }
          });
        });
      });
    }

    function updateCategory(index, item) {
      if (item.category) {
        // Clear sub categories & procedures.

        _.forEach(item.ticketItems, (x) => {
          x.subTreatment = x.subCategory ?? '';
          x.procedure = x.procedure ?? '';
          x.uncoveredProcedure = x.uncoveredProcedure ?? '';
          x.subTreatments = [];
          x.procedures = [];
        });

        // Update each ticketItem in item.

        const category = vm.formattedCategories.find((f) => 
          f.mainTreatmentCategoryName === item.category
        );

        _.forEach(item.ticketItems, (x) => {
          x.category = item.category;
          
          if (category == null) {
            return;
          }

          x.section = category.sectionType;

          // Add sub treatments items.

          x.subTreatments = category.subTreatmentCategories.map((s) => s.subTreatmentItemName);

          // Add procedures

          const selectedSubTreatmentCategory = category.subTreatmentCategories.find(
            (s) => s.subTreatmentItemName === x.subCategory
          );
          if (selectedSubTreatmentCategory) {
            x.procedures = selectedSubTreatmentCategory.procedures ?? [];
          } else if (x.subTreatments?.length) {
            // When selected main treatment category don't have sub-treatment category

            x.procedures = category.subTreatmentCategories.flatMap((s) => s.procedures);
          }
        });
      } else {
        clearProcedures(item);
      }
    }

    function updateSubCategory(index, item, ticketItem) {
      // Clear procedures.

      ticketItem.procedure = '';
      ticketItem.uncoveredProcedure = '';
      ticketItem.procedures = [];

      // Add procedure items.

      const category = _.filter(
        vm.formattedCategories,
        (f) => f.mainTreatmentCategoryName === item.category
      );

      const subTreatment = _.filter(
        category[0].subTreatmentCategories,
        (s) => s.subTreatmentItemName === ticketItem.subTreatment
      );

      ticketItem.procedures = subTreatment[0]?.procedures ?? [];
    }

    // Show V2 UI during create or edit when condition fulfilled.

    function fulfillV2ViewCondition() {
      const ticketVersion = !vm.isClaim ? vm.ticket.version : vm.ticket.ticketVersion;

      const isCreate = vm.isClaim
        ? vm.ticket.requestType !== vm.enums.serviceRequestType.Adjustment.name
        : vm.isCreate;

      return vm.hasInsuranceEnhancementModule && (isCreate || ticketVersion === 'V2');
    }

    function getSubtotalAmount() {
      return getAmount().subtotalAmount;
    }

    function getTotalCoveredAmount() {
      return getAmount().totalCoveredAmount;
    }

    function getTotalUncoveredAmount() {
      return getAmount().totalUncoveredAmount;
    }
  }
})();
