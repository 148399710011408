(() => {
  angular.module('finance').component('myInvoisEInvoiceSenderComponent', {
    templateUrl: require('./myInvoisEInvoiceSender.component.html'),
    controller: MyInvoisEInvoiceSenderComponent,
    controllerAs: 'vm',
    bindings: {
      transactionFrom: '<'
    },
  });

  MyInvoisEInvoiceSenderComponent.$inject = [];

  function MyInvoisEInvoiceSenderComponent() {
    const vm = this;

    vm.loading = 0;

    init();

    function init() {}
  }
})();