(() => {
  angular.module('finance').component('myInvoisEInvoiceDetailedChargesComponent', {
    templateUrl: require('./myInvoisEInvoiceDetailedCharges.component.html'),
    controller: MyInvoisEInvoiceDetailedChargesComponent,
    controllerAs: 'vm',
    bindings: {
      transaction: '<',
      getDateString: '<',
    },
  });

  MyInvoisEInvoiceDetailedChargesComponent.$inject = [];

  function MyInvoisEInvoiceDetailedChargesComponent() {
    const vm = this;

    vm.loading = 0;
    vm.isDate = function (item) {
      return App.isDate(item);
    };

    init();

    function init() {}
  }
})();
