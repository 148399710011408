(() => {
  angular
    .module('app')
    .controller(
      'host.views.inpatientTreatmentCategories.createEditModal',
      InpatientTreatmentCategoryCreateEditModalController
    );

  InpatientTreatmentCategoryCreateEditModalController.$inject = [
    '$uibModalInstance',
    'abp.services.app.inpatientTreatmentCategory',
    'inpatientTreatmentCategory',
    'abp.services.app.commonLookup',
    'Hms.Services.ServiceType',
    'Hms.InpatientTreatmentCategories.SectionType.Name',
    'Hms.InpatientTreatmentCategories.InpatientTreatmentCategory',
  ];

  function InpatientTreatmentCategoryCreateEditModalController(
    $uibModalInstance,
    inpatientTreatmentCategorySvc,
    inpatientTreatmentCategory,
    commonLookupSvc,
    enumServiceTypes,
    enumSectionTypeNames,
    constsInpatientTreatmentCategory
  ) {
    const vm = this;

    vm.constsInpatientTreatmentCategory = constsInpatientTreatmentCategory;

    vm.loading = 0;
    vm.saving = 0;

    vm.isEdit = false;
    vm.save = save;
    vm.cancel = cancel;

    init();

    function init() {
      if (inpatientTreatmentCategory != null && inpatientTreatmentCategory.id != null) {
        vm.isEdit = true;
      }
      vm.enums = {};
      vm.enums.serviceTypes = enumServiceTypes;
      vm.enums.sectionTypeNames = enumSectionTypeNames;

      initServiceTypes();
      initSectionTypes();
      getEligibleCountries();

      getInpatientTreatmentCategoryForCreateEdit();
    }

    function initServiceTypes() {
      vm.serviceTypes = [];
      vm.serviceTypes.push({
        value: vm.enums.serviceTypes.Hospitalization.id,
        name: App.localize(vm.enums.serviceTypes.Hospitalization.name),
      });
      vm.serviceTypes.push({
        value: vm.enums.serviceTypes.Maternity.id,
        name: App.localize(vm.enums.serviceTypes.Maternity.name),
      });
    }

    function initSectionTypes() {
      vm.sectionTypes = [];
      vm.sectionTypes.push({
        value: vm.enums.sectionTypeNames.AccommodationCharges.id,
        name: App.localize(vm.enums.sectionTypeNames.AccommodationCharges.name),
      });
      vm.sectionTypes.push({
        value: vm.enums.sectionTypeNames.InHospitalDoctorVisits.id,
        name: App.localize(vm.enums.sectionTypeNames.InHospitalDoctorVisits.name),
      });
      vm.sectionTypes.push({
        value: vm.enums.sectionTypeNames.DoctorProcedureCharges.id,
        name: App.localize(vm.enums.sectionTypeNames.DoctorProcedureCharges.name),
      });
      vm.sectionTypes.push({
        value: vm.enums.sectionTypeNames.HospitalCharges.id,
        name: App.localize(vm.enums.sectionTypeNames.HospitalCharges.name),
      });
      vm.sectionTypes.push({
        value: vm.enums.sectionTypeNames.EmergencyOutpatientTreatments.id,
        name: App.localize(vm.enums.sectionTypeNames.EmergencyOutpatientTreatments.name),
      });
      vm.sectionTypes.push({
        value: vm.enums.sectionTypeNames.Miscellaneous.id,
        name: App.localize(vm.enums.sectionTypeNames.Miscellaneous.name),
      });
    }

    function getEligibleCountries() {
      vm.loading += 1;
      commonLookupSvc
        .getEligibleCountries()
        .success((data) => {
          vm.countries = data;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Get inpatient treatement category for edit if inpatientTreatmentCategory.id is not null,
    // else return basic inpatient treatment category dto for creation process.
    function getInpatientTreatmentCategoryForCreateEdit() {
      if (inpatientTreatmentCategory == null) {
        vm.loading += 1;
        inpatientTreatmentCategorySvc
          .getInpatientTreatmentCategoryForEdit({
            id: null,
          })
          .success((data) => {
            vm.inpatientTreatmentCategory = data;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      } else {
        vm.loading += 1;
        inpatientTreatmentCategorySvc
          .getInpatientTreatmentCategoryForEdit({
            id: inpatientTreatmentCategory.id,
          })
          .success((data) => {
            vm.inpatientTreatmentCategory = data;
          })
          .finally(() => {
            vm.loading -= 1;
          });
      }
    }

    function save() {
      vm.saving += 1;
      inpatientTreatmentCategorySvc
        .createOrUpdateInpatientTreatmentCategory(vm.inpatientTreatmentCategory)
        .success((data) => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $uibModalInstance.close(data);
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
