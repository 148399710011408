import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller(
      'common.views.patientTickets.sg.detail.patientTicketSgDetail',
      PatientTicketSgDetailController
    );

  PatientTicketSgDetailController.$inject = [
    '$state',
    '$stateParams',
    '$filter',
    '$uibModal',
    '$window',
    'abp.services.app.patientTicket',
    'abp.services.app.latePatientTicketSubmission',
    'Hms.Employees.EmployeeState',
    'moment',
  ];

  function PatientTicketSgDetailController(
    $state,
    $stateParams,
    $filter,
    $modal,
    $window,
    patientTicketSvc,
    latePatientTicketSubmissionSvc,
    enumEmployeeState,
    moment
  ) {
    const vm = this;

    vm.loading = 0;
    vm.isClinic = App.isClinic();
    vm.isHost = App.isHost();
    vm.isCorporate = App.isCorporate();
    vm.ticket = null;
    vm.patient = null;
    vm.clinic = null;
    vm.lateSubmission = null;
    vm.ticketNumber = $stateParams.ticketNumber;
    vm.latePatientTicketSubmissionId = $stateParams.latePatientTicketSubmissionId;
    vm.elementId = 'uncoveredTreatmentTree';
    vm.isPossibleDuplicatedTicket = false;

    vm.enums = {
      employeeState: enumEmployeeState,
    };

    vm.permissions = {
      edit: false,
      editSpecialistDetail: false,
      editDate: false,
      editManualPayment: false,
      delete: false,
      void: false,
    };

    vm.getDependentDescription = getDependentDescription;
    vm.getMcDurationDescription = getMcDurationDescription;
    vm.getDoctorMmcRegistrationNumber = getDoctorMmcRegistrationNumber;
    vm.limitPeriod = limitPeriod;
    vm.editTicket = editTicket;
    vm.editDate = editDate;
    vm.editManualPayment = editManualPayment;
    vm.deleteManualTicket = deleteManualTicket;
    vm.void = voidPatientTicket;
    vm.openApproveModal = openApproveModal;
    vm.openRejectModal = openRejectModal;
    vm.openUpdateIssueNumberModal = openUpdateIssueNumberModal;
    vm.delegateToHr = delegateToHr;
    vm.resendResolutionEmail = resendResolutionEmail;
    vm.search = search;
    vm.getTaxAmount = getTaxAmount;
    vm.triggerBack = triggerBack;

    init();

    function init() {
      if (vm.latePatientTicketSubmissionId) getLateSubmissionTicketDraft();
      else getTicket();
    }

    function getTicket() {
      vm.loading += 1;
      patientTicketSvc
        .getTicketDetail({
          id: $stateParams.ticketNumber,
        })
        .success((data) => {
          vm.ticket = data.ticket;
          vm.patient = data.patient;
          vm.clinic = data.clinic;
          vm.hasInsurerClaim = data.hasInsurerClaim;
          vm.ticketNumber = data.ticket.ticketNumber;
          vm.currencyCode = data.currencyCode;
          vm.hasTax = data.clinic.hasTaxRegistrationNumber || vm.ticket.taxAmount > 0;
          vm.reportVersion = data.reportVersion;
          vm.report = vm.reportVersion === 'v0' ? data.reportV0 : data.reportV191018;
          vm.isPossibleDuplicatedTicket = data.isPossibleDuplicatedTicket;
          vm.isVoided = data.isVoided;

          // Calculate the covered and uncovered amount based on treatments only
          vm.coveredAmount = vm.ticket.originalCoveredAmount;
          vm.uncoveredAmount = vm.ticket.uncoveredAmount;
          // Calculate tax amount
          if (vm.ticket.taxPercentage > 0) {
            if (vm.clinic.isTaxInclusive) {
              vm.coveredTaxAmount =
                (vm.ticket.originalCoveredAmount / (100 + vm.ticket.taxPercentage)) *
                vm.ticket.taxPercentage;
              vm.uncoveredTaxAmount =
                (vm.ticket.uncoveredAmount / (100 + vm.ticket.taxPercentage)) *
                vm.ticket.taxPercentage;
            } else {
              vm.coveredTaxAmount =
                (vm.ticket.originalCoveredAmount * vm.ticket.taxPercentage) / 100;
              vm.uncoveredTaxAmount = (vm.ticket.uncoveredAmount * vm.ticket.taxPercentage) / 100;
              vm.coveredAmount += vm.coveredTaxAmount;
              vm.uncoveredAmount += vm.uncoveredTaxAmount;
            }
          }

          if (vm.ticket.coPayAmount) {
            vm.eligibleClaimAmount = vm.coveredAmount - vm.ticket.coPayAmount;
          } else {
            vm.eligibleClaimAmount = vm.coveredAmount;
          }
          vm.exceededAmount = Math.max(vm.eligibleClaimAmount - vm.patient.maxEntitlementAmount, 0);

          if (vm.patient.coPayValue) {
            vm.coPayValueDisplay = vm.patient.isCoPayPercentage
              ? `${vm.patient.coPayValue}%`
              : `${$filter('currencyFormat')(vm.patient.coPayValue, vm.currencyCode)}`;
          } else {
            vm.coPayValueDisplay = 'NotApplicable';
          }
          // If is pre-employment ticket, get the treatment that represent package.

          if (vm.ticket.ticketType === 3) {
            vm.package = _.first(vm.ticket.treatments);
            vm.packageTreatments = _.tail(vm.ticket.treatments);
          }

          // Format waive service fee reason.

          if (vm.ticket && vm.ticket.bankingDetails) {
            const { bankingDetails } = vm.ticket;
            vm.serviceFeePayorRemark = localizeServiceFeePayorRemark(
              bankingDetails.serviceFeePayor,
              bankingDetails.serviceFeePayorRemark
            );
          }

          vm.showTaxDetails = vm.ticket.taxAmount > 0;

          checkAttachments();
        })
        .finally(() => {
          vm.loading -= 1;

          if (vm.ticket) {
            if (vm.ticket.ticketType === 0 || vm.ticket.ticketType === 3) {
              vm.permissions.edit = !vm.isCorporate && abp.auth.isGranted('PatientTickets.Edit');
              vm.permissions.editDate = abp.auth.isGranted('PatientTickets.EditTicketDate');
              vm.permissions.editManualPayment = abp.auth.isGranted(
                'PatientTickets.EditManualPayment'
              );
              vm.permissions.delete = false;
              vm.permissions.void = abp.auth.isGranted('PatientTickets.Void');
              vm.permissions.voidDuplicateTicket = abp.auth.isGranted(
                'PatientTickets.Void.DuplicateTicket'
              );
            } else if (vm.ticket.ticketType === 1) {
              vm.permissions.edit =
                abp.auth.isGranted('PatientTickets.Manual.Edit') ||
                abp.auth.isGranted('PatientTickets.Host.Manual.Edit');
              vm.permissions.editDate = false;
              vm.permissions.delete = abp.auth.isGranted('PatientTickets.Manual.Delete');
              vm.permissions.editManualPayment = false;
              vm.permissions.void = false;
            } else if (vm.ticket.ticketType === 2) {
              vm.permissions.edit = abp.auth.isGranted('PatientTickets.Reimbursement.Edit');
              vm.permissions.editDate = false;
              vm.permissions.delete = false;
              vm.permissions.editManualPayment = abp.auth.isGranted(
                'PatientTickets.EditManualPayment'
              );
              vm.permissions.void = abp.auth.isGranted('PatientTickets.Void');
              vm.permissions.manageClaim =
                abp.auth.isGranted('Host.Claims.Manage') ||
                abp.auth.isGranted('Host.Claims.Inpatient.Manage');
            }
          }
        });
    }

    function getLateSubmissionTicketDraft() {
      vm.loading += 1;
      latePatientTicketSubmissionSvc
        .getPatientTicketDraft({
          id: $stateParams.latePatientTicketSubmissionId,
        })
        .success((data) => {
          vm.ticket = data.ticket;
          vm.patient = data.patient;
          vm.clinic = data.clinic;
          vm.ticketNumber = data.ticket.ticketNumber;
          vm.lateSubmission = data.lateSubmission;
          vm.uncoveredTreatments = data.lateSubmission.patientUncoveredTreatments;
          vm.currencyCode = data.currencyCode;
          vm.hasTax = vm.ticket.taxPercentage != null;
          vm.reportVersion = data.reportVersion;
          vm.report = vm.reportVersion === 'v0' ? data.reportV0 : data.reportV191018;

          // If is pre-employment ticket, get the treatment that represent package.

          if (vm.ticket.ticketType === 3) {
            vm.package = _.first(vm.ticket.treatments);
            vm.packageTreatments = _.tail(vm.ticket.treatments);
          }

          if (vm.lateSubmission.coPayValue) {
            vm.coPayValueDisplay = vm.lateSubmission.coPayValue.isPercentage
              ? `${vm.lateSubmission.coPayValue.value}%`
              : $filter('currencyFormat')(vm.lateSubmission.coPayValue.value, vm.currencyCode);
          }

          buildTreatmentTree(vm.elementId);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function buildTreatmentTree(elementId) {
      const element = $(`#${elementId}`);
      const ref = $.jstree.reference(element);
      if (ref) {
        ref.destroy();
      }

      const coveredNodeIcon = '<i class="fas fa-check text-navy"></i> ';
      const uncoveredNodeIcon = '<i class="fas fa-times text-danger"></i> ';

      function getNodeText(data) {
        return (
          (!data.isCovered || data.hasUncoveredChildren ? uncoveredNodeIcon : coveredNodeIcon) +
          data.displayName
        );
      }

      const treatments = _.map(vm.uncoveredTreatments, (record) => ({
        id: record.id,
        parent: record.parentId ? record.parentId : '#',
        displayName: record.displayName,
        text: getNodeText(record),
        state: {
          opened: record.hasUncoveredChildren,
        },
      }));

      element
        .jstree({
          core: {
            data: treatments,
          },
          types: {
            default: {
              icon: 'fas fa-folder tree-item-icon-color icon-lg',
            },
            file: {
              icon: 'fas fa-file tree-item-icon-color icon-lg',
            },
          },
          search: {
            fuzzy: false,
            show_only_matches: true,
            show_only_matches_children: true,
          },
          sort(itemA, itemB) {
            const nodeA = this.get_node(itemA);
            const nodeB = this.get_node(itemB);
            if (nodeB.original.id === -1) {
              return 1;
            }
            if (nodeA.children_d.length > 0 && nodeB.children_d.length === 0) {
              return -1;
            }

            return nodeB.original.displayName < nodeA.original.displayName ? 1 : -1;
          },
          plugins: ['types', 'search', 'sort'],
        })
        .bind('select_node.jstree', (e, data) => {
          const { href } = data.node.a_attr;
          if (href === '#') return '';

          window.open(href);
          return '';
        });
    }

    function getDependentDescription() {
      return App.localize(
        'DependentDescription',
        vm.patient.sponsorName,
        $filter('nationalIdFilter')(vm.patient.sponsorNationalId)
      );
    }

    function getMcDurationDescription() {
      if (vm.ticket.medicalCertificate) {
        if (vm.ticket.ticketType !== 0) {
          return App.localize('XDays', vm.ticket.medicalCertificate.effectiveMcDays);
        }
        const startDate = moment(vm.ticket.medicalCertificate.startDate);
        const endDate = moment(vm.ticket.medicalCertificate.endDate);
        const duration = endDate.diff(startDate, 'days') + 1;
        return App.localize('XDays', duration);
      }
      return '';
    }

    function getDoctorMmcRegistrationNumber() {
      if (vm.ticket.doctorMmcRegistrationNumber) {
        return `(${App.localize('MedicalCouncilRegistrationNumberShort')}: ${
          vm.ticket.doctorMmcRegistrationNumber
        })`;
      }
      return '';
    }

    function limitPeriod() {
      let cycle = '';
      if (vm.lateSubmission && vm.lateSubmission.allowancePeriod) {
        switch (vm.lateSubmission.allowancePeriod.cycle) {
          case 0:
            cycle = App.localize('EveryXYears', vm.lateSubmission.allowancePeriod.interval);
            break;
          case 1:
            cycle = App.localize('Yearly');
            break;
          case 2:
            cycle = App.localize('Monthly');
            break;
          case 3:
            cycle = App.localize('Daily');
            break;
          case 4:
            cycle = App.localize('PerVisit');
            break;
          case 5:
            cycle = App.localize('Every6Months');
            break;
          case 6:
            cycle = App.localize('Every4Months');
            break;
          case 7:
            cycle = App.localize('Every3Months');
            break;
          case 8:
            cycle = App.localize('Every2Months');
            break;

          // no default
        }
        cycle = cycle.toLowerCase();
      }
      return cycle;
    }

    function editTicket() {
      let key = 'createEditPatientTicket';

      if (vm.ticket.ticketFormat === 1) {
        key = 'clinic.createEditPatientTicket';
      } else if (vm.ticket.ticketType === 1) {
        key = 'createEditManualTicket';
      }

      const hasTaxAmount = vm.ticket.taxAmount > 0;

      $state.go(key, {
        ticketNumber: vm.ticketNumber,
        ticketType: vm.ticket.ticketType,
        hasTaxAmount,
      });
    }

    function editDate() {
      $modal
        .open({
          templateUrl: require('../../editDateModal.html'),
          controller: 'common.views.patientTickets.editDateModal as vm',
          backdrop: 'static',
          resolve: {
            ticket() {
              return vm.ticket;
            },
          },
        })
        .result.then(() => {
          getPatientTicket();
        });
    }

    function editManualPayment() {
      $modal
        .open({
          templateUrl: require('../../editManualPaymentModal.html'),
          controller: 'common.views.patientTickets.editManualPaymentModal as vm',
          backdrop: 'static',
          resolve: {
            ticket() {
              return vm.ticket;
            },
            hasInsurerClaim: vm.hasInsurerClaim,
            currencyCode() {
              return vm.currencyCode;
            },
          },
        })
        .result.then(() => {
          getPatientTicket();
        });
    }

    function deleteManualTicket() {
      abp.message.confirm(
        App.localize('ManualTicketDeleteWarningMessage', vm.ticketNumber),
        App.localize('AreYouSure'),
        (d) => {
          if (d) {
            patientTicketSvc
              .deleteManualTicket({
                id: vm.ticketNumber,
              })
              .success(() => {
                abp.notify.success(App.localize('SuccessfullyDeleted'));
                $window.history.back();
              });
          }
        }
      );
    }

    function voidPatientTicket() {
      swal(
        {
          title: App.localize('VoidPatientTicket'),
          text: App.localize('WhyVoidPatientTicket'),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          inputPlaceholder: App.localize('PleaseExplain'),
          showLoaderOnConfirm: false,
        },
        (inputValue) => {
          if (inputValue === false) return false;
          if (_.trim(inputValue || '') === '') {
            swal.showInputError(App.localize('InvalidInput'));
            return false;
          }
          voidTicket(_.trim(inputValue));
          return true;
        }
      );

      function voidTicket(modificationRemarks) {
        abp.message.confirm(
          App.localize('TicketVoidWarningMessage', vm.ticketNumber),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              patientTicketSvc
                .voidTicket({
                  id: vm.ticketNumber,
                  modificationRemarks,
                })
                .success(() => {
                  abp.notify.success(App.localize('SuccessfullyVoided'));
                  $window.history.back();
                  swal.close();
                });
            }
          }
        );
      }
    }

    function openApproveModal() {
      $modal
        .open({
          templateUrl: require('../../detail/approveLateSubmission/approveLateSubmission.modal.html'),
          controller: 'common.views.patientTickets.detail.approveLateSubmissionModal as vm',
          backdrop: 'static',
        })
        .result.then((reason) => {
          approveLateSubmission(reason);
        });
    }

    function openRejectModal() {
      $modal
        .open({
          templateUrl: require('../../detail/rejectLateSubmission/rejectLateSubmission.modal.html'),
          controller: 'common.views.patientTickets.detail.rejectLateSubmissionModal as vm',
          backdrop: 'static',
          resolve: {
            isDeletedEmployee() {
              return vm.lateSubmission.isDeletedEmployee;
            },
          },
        })
        .result.then((reason) => {
          if (reason) {
            rejectLateSubmission(reason);
          }
        });
    }

    function openUpdateIssueNumberModal() {
      $modal
        .open({
          templateUrl: require('../../detail/updateIssueNumber/updateIssueNumber.modal.html'),
          controller: 'common.views.patientTickets.detail.updateIssueNumberModal as vm',
          backdrop: 'static',
        })
        .result.then((issueNumber) => {
          if (issueNumber) {
            updateIssue(issueNumber);
          }
        });
    }

    function delegateToHr(latePatientTicketSubmissionId) {
      latePatientTicketSubmissionSvc
        .getOrganizationEmails({
          Id: latePatientTicketSubmissionId,
        })
        .success((data) => {
          $modal
            .open({
              templateUrl: require('../../../../../host/views/operationsDashboard/lateSubmissions/sendEmails.modal.html'),
              controller: 'host.views.operationsDashboard.lateSubmissions.sendEmailsModal as vm',
              backdrop: 'static',
              resolve: {
                title() {
                  return App.localize('DelegateToHR');
                },
                narrative() {
                  return App.localize('DelegateToHRNarrative');
                },
                emailAddresses() {
                  return data.emailAddresses;
                },
              },
            })
            .result.then((emailAddresses) => {
              latePatientTicketSubmissionSvc
                .sendPendingApprovalEmails({
                  id: latePatientTicketSubmissionId,
                  emailAddresses,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  getPatientTicket();
                });
            });
        });
    }

    function resendResolutionEmail(latePatientTicketSubmissionId, lastSentTime) {
      if (_.isNil(lastSentTime)) {
        return;
      }
      const formattedSentTime = moment(lastSentTime).format('Do MMMM YYYY');

      latePatientTicketSubmissionSvc
        .getOrganizationEmails({
          Id: latePatientTicketSubmissionId,
        })
        .success((data) => {
          $modal
            .open({
              templateUrl: require('../../../../../host/views/operationsDashboard/lateSubmissions/sendEmails.modal.html'),
              controller: 'host.views.operationsDashboard.lateSubmissions.sendEmailsModal as vm',
              backdrop: 'static',
              resolve: {
                title() {
                  return App.localize('ResendResolutionEmail');
                },
                narrative() {
                  return App.localize('ResendResolutionEmailNarrative', formattedSentTime);
                },
                emailAddresses() {
                  return data.emailAddresses;
                },
              },
            })
            .result.then((emailAddresses) => {
              latePatientTicketSubmissionSvc
                .sendPendingApprovalEmails({
                  Id: latePatientTicketSubmissionId,
                  emailAddresses,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  getPatientTicket();
                });
            });
        });
    }

    function approveLateSubmission(reason) {
      vm.loading += 1;
      latePatientTicketSubmissionSvc
        .approveSubmission({
          id: vm.latePatientTicketSubmissionId,
          coveredAmount: vm.ticket.coveredAmount,
          manualPayment: vm.ticket.manualPayment,
          remarks: reason,
        })
        .success(() => {
          $state.reload();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function rejectLateSubmission(reason) {
      vm.loading += 1;
      latePatientTicketSubmissionSvc
        .rejectSubmission({
          id: vm.latePatientTicketSubmissionId,
          remarks: reason,
        })
        .success(() => {
          $state.reload();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function updateIssue(issueNumber) {
      vm.loading += 1;
      latePatientTicketSubmissionSvc
        .updateIssue({
          id: vm.latePatientTicketSubmissionId,
          issueNumber,
        })
        .success(() => {
          $state.reload();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function search() {
      const element = $(`#${vm.elementId}`);
      element.jstree('search', _.trim(vm.filter));
    }

    function getTaxAmount() {
      if (vm.ticket && vm.ticket.preTaxAmount && vm.ticket.taxPercentage) {
        return (vm.ticket.preTaxAmount * vm.ticket.taxPercentage) / 100;
      }
      return 0;
    }

    function triggerBack() {
      $state.go('host.operationsDashboard', {
        targetWidget: 'lateSubmissions',
      });
    }

    function checkAttachments() {
      vm.hasSpecialistAttachments =
        vm.ticket.specialistAttachments && _.some(vm.ticket.specialistAttachments, (d) => d.length);
      vm.hasAttachments =
        (vm.ticket.attachments && vm.ticket.attachments.length) || vm.hasSpecialistAttachments;
    }

    function localizeServiceFeePayorRemark(payor, remark) {
      let output = '';
      let r = remark;
      if (!r) r = '-';

      switch (payor) {
        case 0:
          output = App.localize('ServiceFeeWaivedBecauseX', r);
          break;
        case 1:
          output = App.localize('ServiceFeePaidByCorporate');
          break;
        case 2:
          output = App.localize('ServiceFeePaidByClaimantBecauseX', r);
          break;

        // no default
      }

      return output;
    }
  }
})();
