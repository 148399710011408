import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('finance.views.saleTransactions.creditMemoDetail.page', CreditMemoDetailController);

  CreditMemoDetailController.$inject = [
    '$stateParams',
    'abp.services.finance.creditMemos',
    '$uibModal',
    'abp.services.app.commonLookup',
    'abp.services.app.financeCommon',
    '$state',
    'abp.services.finance.saleTransactions',
  ];

  function CreditMemoDetailController(
    $stateParams,
    creditMemosSvc,
    $modal,
    commonLookupSvc,
    portalfinanceCommonSvc,
    $state,
    saleTransactionSvc) {
    const vm = this;

    vm.loading = 0;
    vm.transactionNumber = $stateParams.transactionNumber;
    vm.creditMemo = null;
    vm.creditMemoFrom = null;
    vm.creditMemoTo = null;
    vm.message = 'Placeholder for message';
    vm.invoices = [];
    vm.payments = [];

    vm.permissions = {
      creditInvoice: abp.auth.isGranted('HealthMetrics.Finance.SaleTransactions.CreditInvoice'),
      voidTransaction: abp.auth.isGranted('HealthMetrics.Finance.SaleTransactions.VoidTransaction')
    };


    vm.sendEmail = sendEmail;
    vm.redirectToClassicView = redirectToClassicView;
    vm.getDateString = getDateString;
    vm.voidTransaction = voidTransaction;

    init();

    function init() {
      getCreditMemo();
    }

    function getCreditMemo() {
      const input = {
        creditMemoNumber: vm.transactionNumber,
      };
      vm.loading += 1;

      creditMemosSvc
        .getCreditMemo(input)
        .success((data) => {
          vm.currencyCode = data.creditMemo.currencyCode;
          vm.countryCode = data.creditMemoFrom.countryCode;
          vm.creditMemo = data.creditMemo;
          vm.creditMemoFrom = data.creditMemoFrom;
          vm.creditMemoTo = data.creditMemoTo;
          vm.invoices = data.invoices;
          vm.message = data.message;
          vm.categoryNames = data.categoryNames;

          vm.isManual = data.creditMemo.termsAndConditionsId != null;

          vm.isAddressCreditMemoFrom =
            vm.creditMemoFrom.stringifiedAddress.replaceAll(',', '').trim() === '';
          vm.isAddressCreditMemoTo =
            vm.creditMemoTo.stringifiedAddress.replaceAll(',', '').trim() === '';

          const accountOwnerId = vm.creditMemo.isCreditor
            ? vm.creditMemoTo.id
            : vm.creditMemoFrom.id;

          commonLookupSvc.getAccountOwnerById({id: accountOwnerId}).success((accOwner) => {
            vm.creditMemo.accountOwnerLogo = accOwner.dataUri;

            if (vm.creditMemo.isCreditor) {
              vm.creditMemoTo.contactNumber = accOwner.accountOwner.contactNumber;
            } else {
              vm.creditMemoFrom.contactNumber = accOwner.accountOwner.contactNumber;
            }
          });
        })
        .finally(() => {
          vm.loading = 0;
        });
    }

    function sendEmail() {
      portalfinanceCommonSvc
        .getEmailAddresses({
          billingAccountKey: vm.creditMemo.billingAccountKey,
        })
        .success((data) => {
          swal(
            {
              title: App.localize('SendEmailForX', vm.creditMemo.transactionNumber),
              text: App.localize('EmailAddresses'),
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              confirmButtonColor: '#1ab394',
              inputPlaceholder: App.localize('EmailAddressesNarrative'),
              inputValue: data.emailAddresses,
              showLoaderOnConfirm: true,
            },
            (inputValue) => {
              if (inputValue === false) return false;
              if (inputValue === '') {
                swal.showInputError(App.localize('InvalidEmailAddress'));
                return false;
              }
              portalfinanceCommonSvc
                .sendCreditMemoEmail({
                  emailAddresses: inputValue,
                  billingAccountName: vm.creditMemo.billingAccountName,
                  transactionNumber: vm.creditMemo.transactionNumber,
                  date: vm.creditMemo.transactionLocalDate,
                  currencyCode: vm.creditMemo.currencyCode,
                  amount: vm.creditMemo.outstandingAmount,
                  accountOwnerId: vm.creditMemo.accountOwnerId,
                })
                .success(() => {
                  abp.notify.info(App.localize('EmailSent'));
                  swal.close();
                });
              return false;
            }
          );
        });
    }

    function redirectToClassicView() {
      $state.go('creditMemoDetail', { transactionNumber: vm.transactionNumber });
    }

    function getDateString(date) {
      return App.getDateString(moment.utc(date));
    }

    function voidTransaction(transactionNumber) {
      swal(
        {
          title: App.localize('VoidTransaction'),
          text: App.localize('WhyVoidTransaction'),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          inputPlaceholder: App.localize('PleaseExplain'),
          showLoaderOnConfirm: false,
        },
        (inputValue) => {
          if (inputValue === false) return false;
          if (_.trim(inputValue || '') === '') {
            swal.showInputError(App.localize('InvalidInput'));
            return false;
          }

          abp.message.confirm(
            App.localize('VoidTransactionWarningMessage', transactionNumber),
            App.localize('AreYouSure'),
            (d) => {
              if (d) {
                vm.loading += 1;
                saleTransactionSvc
                  .voidSaleTransaction({
                    transactionNumber,
                    reason: inputValue
                  })
                  .success(() => {
                    abp.notify.info(App.localize('SuccessfullyVoided'));
                    swal.close();
                  })
                  .finally(() => {
                    vm.loading -= 1;
                  });
              }
            }
          );

          return true;
        });
    }
  }
})();
