(() => {
  angular.module('app').component('hostClinicsPanelBankingEditorComponent', {
    templateUrl: require('./panelBankingEditor.component.html'),
    controller: PanelBankingEditorController,
    controllerAs: 'vm',
    bindings: {
      data: '<',
      accountOwnerCountryCode: '<',
    },
    require: {
      container: '^hostClinicsPanelEditorContainerComponent',
    },
  });

  PanelBankingEditorController.$inject = [
    '$scope',
    'abp.services.app.commonLookup',
    'Hms.PaymentAccounts.PaymentAccount',
    'Hms.MultiTenancy.Tenant',
  ];

  function PanelBankingEditorController(
    $scope,
    commonLookupSvc,
    constsPaymentAccount,
    constsTenant
  ) {
    function init() {
      getBanks();
    }

    const vm = this;
    vm.constsPaymentAccount = constsPaymentAccount;
    vm.constsTenant = constsTenant;
    vm.loading = 0;
    vm.saving = 0;
    vm.isCreate = false;
    vm.isEditing = false;

    vm.hasRegionalSystemSupport = abp.setting.getBoolean('Hms.Feature.RegionalSystemSupport');

    vm.permissions = {
      editBanking: abp.auth.isGranted('Host.Clinics.Edit.Finance'),
    };

    vm.$onInit = () => {
      vm.container.addWidget(this);
    };

    vm.$onChanges = (changes) => {
      vm.isCreate = vm.data ? !vm.data.id : false;

      if (changes.accountOwnerCountryCode && changes.accountOwnerCountryCode.currentValue) {
        filterBanks(changes.accountOwnerCountryCode.currentValue);
      }
    };

    vm.isDirty = () =>
      $scope.panelCreateEditBankingForm && $scope.panelCreateEditBankingForm.$dirty;

    vm.setPristine = () => {
      $scope.panelCreateEditBankingForm.$setPristine();
    };

    function filterBanks(countryCode) {
      vm.filteredBanks = vm.hasRegionalSystemSupport
        ? _.filter(vm.banks, (b) => b.countryCode === countryCode)
        : vm.banks;
    }

    function getBanks() {
      vm.loading += 1;
      commonLookupSvc
        .getBanks()
        .success((data) => {
          vm.banks = data.items;
          filterBanks(vm.accountOwnerCountryCode);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    // Force validation on this form.
    // True if valid, otherwise, false.

    vm.validateForm = () => {
      const form = $scope.panelCreateEditBankingForm;
      App.touchFormErrors(form);
      return form.$valid;
    };

    // Generate the payload from this form to send to ClinicAppService API.

    vm.getPayload = (settings) => {
      _.extend(settings, {
        banking: vm.data,
      });
    };

    // Ask container to save this widget.

    vm.save = () => vm.container.saveWidget(vm);

    init();
  }
})();
