(() => {
  angular
    .module('app')
    .controller('common.views.profile.manageMyAccount', ManageMyAccountController);

  ManageMyAccountController.$inject = ['$uibModalInstance', 'abp.services.app.profile'];

  function ManageMyAccountController($uibModalInstance, profileSvc) {
    const vm = this;
    vm.loading = 0;
    vm.validEmailAddress = false;
    vm.userAccountSettings = null;

    vm.cancel = cancel;
    vm.save = save;
    vm.navigateToManageTwoFactorAuth = navigateToManageTwoFactorAuth;

    init();

    function init() {
      getUserAccountSettings();
    }

    function getUserAccountSettings() {
      vm.loading += 1;
      profileSvc
        .getUserAccountSettings()
        .success((data) => {
          vm.userAccountSettings = data;
          vm.validEmailAddress = data.emailAddress != null;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function navigateToManageTwoFactorAuth() {
      // TODO: Apply the actual route to be navigated during the development of HM-9434
      console.log('navigate to ManageTwoFactorAuth');
    }

    function save() {
      const { emailAddress, twoFactorAuthentication, ...payload } = vm.userAccountSettings;
      vm.loading += 1;
      profileSvc
        .saveUserAccountSettings(payload)
        .success(() => {
          abp.notify.success(App.localize('SuccessfullySaved'));
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function cancel() {
      $uibModalInstance.dismiss();
    }
  }
})();
