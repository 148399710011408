/* global __FINANCE_ENDPOINT__ */
const { timeHours } = require('d3');

(() => {
  const financeModule = angular.module('finance');

  financeModule.factory('abp.services.finance.saleTransactions', [
    '$http',
    ($http) =>
      new (function () {
        this.getSaleTransactions = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/saleTransactions/getSaleTransactions`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        this.getSaleTransactionsToExcel = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/saleTransactions/getSaleTransactionsToExcel`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        this.getAccountReceivablesToExcel = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/saleTransactions/getAccountReceivablesToExcel`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
          this.enqueueGenerateMyInvoisBatchExcelJob = (input, httpParams) =>
            $http(
              angular.extend(
                {
                  url: `${__FINANCE_ENDPOINT__}/api/app/saleTransactions/enqueueGenerateMyInvoisBatchExcelJob`,
                  method: 'GET',
                  params: input,
                },
                httpParams
              )
            );

        this.setManualPaymentInstructionNumber = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/saleTransactions/setManualPaymentInstructionNumber`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );

        this.generateMyInvoisPayload = (input, httpParams) => 
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/saleTransactions/generateMyInvoisPayload`,
                method: 'GET',
                params: input
              },
              httpParams
            )
          )

        this.voidSaleTransaction = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/saleTransactions/voidSaleTransaction`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
      })(),
  ]);

  financeModule.factory('abp.services.finance.commonLookup', [
    '$http',
    ($http) =>
      new (function () {
        this.getCommonSettings = (input, httpParams) =>
          $http(
            angular.extend({
              url: `${__FINANCE_ENDPOINT__}/api/app/commonLookup/getCommonSettings`,
              method: 'GET',
              params: input,
            }),
            httpParams
          );

        this.getCountries = (input, httpParams) =>
          $http(
            angular.extend({
              url: `${__FINANCE_ENDPOINT__}/api/app/commonLookup/getCountries`,
              method: 'GET',
              params: input,
            }),
            httpParams
          );

        this.getAccountOwners = (input, httpParams) =>
          $http(
            angular.extend({
              url: `${__FINANCE_ENDPOINT__}/api/app/commonLookup/getAccountOwners`,
              method: 'GET',
              params: input,
            }),
            httpParams
          );

        this.getBillingAccounts = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/commonLookup/getBillingAccounts`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );

        this.getBanks = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/commonLookup/getBanks`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );

        this.getTransactionCategories = (httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/commonLookup/getTransactionCategories`,
                method: 'GET',
              },
              httpParams
            )
          );

        this.getTransactionCategoriesByAccountOwner = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/commonLookup/getTransactionCategoriesByAccountOwner`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );

        this.getManualTransactionTenantTypeByBillingAccount = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/commonLookup/getManualTransactionTenantTypeByBillingAccount`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );

        this.getTenantBillingAccounts = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/commonLookup/getTenantBillingAccounts`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
      })(),
  ]);

  financeModule.factory('abp.service.finance.billingAccount', [
    '$http',
    ($http) =>
      new (function () {
        this.getBillingAccounts = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/billingAccount/getBillingAccounts`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );

        this.getNewBillingAccountCode = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/billingAccount/getNewBillingAccountCode`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        this.updateAccountCode = (input, httpParams) =>
          $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/billingAccount/updateAccountCode`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
      })(),
  ]);

  financeModule.factory('abp.services.finance.accountReceivables', [
    '$http',
    function ($http) {
      return new (function () {
        this.getExcessPayments = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/accountReceivables/getExcessPayments`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getPaymentsHistory = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/accountReceivables/getPaymentsHistory`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getListingsTotalCount = function (httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/accountReceivables/getListingsTotalCount`,
                method: 'GET',
              },
              httpParams
            )
          );
        };

        this.deletePayment = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/accountReceivables/deletePayment`,
                method: 'DELETE',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getBankAccountDeposits = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/accountReceivables/getBankAccountDeposits`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.deleteBankAccountDeposit = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/accountReceivables/deleteBankAccountDeposit`,
                method: 'DELETE',
                params: input,
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.settings', [
    '$http',
    function ($http) {
      return new (function () {
        this.getSettings = function (httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/settings/getSettings`,
                method: 'GET',
              },
              httpParams
            )
          );
        };

        this.updateSettings = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/settings/updateSettings`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.payments', [
    '$http',
    function ($http) {
      return new (function () {
        this.getPaymentDetails = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/payment/getPaymentDetails`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getInvoices = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/payment/getInvoices`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.createPaymentRecord = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/payment/createPaymentRecord`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.updatePaymentRecord = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/payment/updatePaymentRecord`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.getPaymentForView = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/payment/getPaymentForView`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.deletePayment = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/payment/deletePayment`,
                method: 'DELETE',
                params: input,
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.accountOwners', [
    '$http',
    function ($http) {
      return new (function () {
        this.updateAccountOwnerBankAccountDetails = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/accountOwners/updateAccountOwnerBankAccountDetails`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.getAccountOwnerBankAccountDetails = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/accountOwners/getAccountOwnerBankAccountDetails`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getAccountOwners = function (httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/accountOwners/getAccountOwners`,
                method: 'GET',
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.paymentTerms', [
    '$http',
    function ($http) {
      return new (function () {
        this.getPaymentTerms = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentTerms/getPaymentTerms`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getPaymentTerm = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentTerms/getPaymentTerm`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.createPaymentTerm = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentTerms/createPaymentTerm`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.updatePaymentTerm = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentTerms/updatePaymentTerm`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.deletePaymentTerm = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentTerms/deletePaymentTerm`,
                method: 'DELETE',
                params: input,
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.invoices', [
    '$http',
    function ($http) {
      return new (function () {
        this.getInvoice = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/invoices/getInvoice`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.creditMemos', [
    '$http',
    function ($http) {
      return new (function () {
        this.getCreditMemo = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/creditMemos/getCreditMemo`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getCreditInvoiceOption = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/creditMemos/getCreditInvoiceOption`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.creditInvoices = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/creditMemos/creditInvoices`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.paymentInstruction', [
    '$http',
    function ($http) {
      return new (function () {
        this.createPaymentInstructions = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentInstruction/createPaymentInstructions`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.getBatches = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentInstruction/getBatches`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getBatchesDetail = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentInstruction/getBatchesDetail`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getPaymentInstructionsByBatchNumber = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentInstruction/getPaymentInstructionsByBatchNumber`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.verifyPaymentInstructions = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentInstruction/verifyPaymentInstructions`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.approvePaymentInstructions = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentInstruction/approvePaymentInstructions`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.deletePaymentInstructions = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentInstruction/deletePaymentInstructions`,
                method: 'DELETE',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getTransferAdvices = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentInstruction/getTransferAdvices`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.debugTools', [
    '$http',
    function ($http) {
      return new (function () {
        this.createMockBankAccountDeposit = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/debugTools/createMockBankAccountDeposit`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.paymentInstructionQueue', [
    '$http',
    function ($http) {
      return new (function () {
        this.enqueueGeneratePaymentInstructionsInBatchJob = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentInstructionQueue/enqueueGeneratePaymentInstructionsInBatchJob`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.getPaymentInstructionQueue = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/paymentInstructionQueue/getPaymentInstructionQueue`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.manualBankAccount', [
    '$http',
    function ($http) {
      return new (function () {
        this.getBankAccounts = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/manualBankAccount/getBankAccounts`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getBankAccount = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/manualBankAccount/getBankAccount`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.createBankAccount = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/manualBankAccount/createBankAccount`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.updateBankAccount = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/manualBankAccount/updateBankAccount`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.deleteBankAccount = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/manualBankAccount/deleteBankAccount`,
                method: 'DELETE',
                params: input,
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.tax', [
    '$http',
    function ($http) {
      return new (function () {
        this.getAccountOwnerTaxes = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/tax/getAccountOwnerTaxes`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getAccountOwnerTax = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/tax/getAccountOwnerTax`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.createAccountOwnerTax = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/tax/createAccountOwnerTax`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.updateAccountOwnerTax = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/tax/updateAccountOwnerTax`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.deleteAccountOwnerTax = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/tax/deleteAccountOwnerTax`,
                method: 'DELETE',
                params: input,
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.termsAndConditions', [
    '$http',
    function ($http) {
      return new (function () {
        this.getTermsAndConditions = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/termsAndConditions/getTermsAndConditions`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getTermsAndConditionsById = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/termsAndConditions/getTermsAndConditionsById`,
                method: 'GET',
                params: input
              },
              httpParams
            )
          );
        };

        this.createTermsAndConditions = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/termsAndConditions/createTermsAndConditions`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.updateTermsAndConditions = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/termsAndConditions/updateTermsAndConditions`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.deleteTermsAndConditions = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/termsAndConditions/deleteTermsAndConditions`,
                method: 'DELETE',
                params: input,
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.transactionCategory', [
    '$http',
    function ($http) {
      return new (function () {
        this.getTransactionCategoriesListing = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/transactionCategory/getTransactionCategoriesListing`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getTransactionCategory = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/transactionCategory/getTransactionCategory`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.createTransactionCategory = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/transactionCategory/createTransactionCategory`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.updateTransactionCategory = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/transactionCategory/updateTransactionCategory`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.deleteTransactionCategory = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/transactionCategory/deleteTransactionCategory`,
                method: 'DELETE',
                params: input,
              },
              httpParams
            )
          );
        };
      })();
    },
  ]);

  financeModule.factory('abp.services.finance.manualTransactionRequests', [
    '$http',
    function ($http) {
      return new (function () {
        this.getManualTransactionRequests = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/manualTransactionRequests/getManualTransactionRequests`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.getManualTransactionRequest = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/manualTransactionRequests/getManualTransactionRequest`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };

        this.createManualTransactionRequest = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/manualTransactionRequests/createManualTransactionRequest`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.updateManualTransactionRequest = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/manualTransactionRequests/updateManualTransactionRequest`,
                method: 'POST',
                data: JSON.stringify(input),
              },
              httpParams
            )
          );
        };

        this.deleteManualTransactionRequest = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/manualTransactionRequests/deleteManualTransactionRequest`,
                method: 'DELETE',
                params: input,
              },
              httpParams
            )
          );
        };

        this.checkReferences = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/manualTransactionRequests/checkReferences`,
                method: 'GET',
                params: input,
              },
              httpParams
            )
          );
        };
      })();
    }
  ]);

  financeModule.factory('abp.services.finance.malaysiaTaxIdentifier', [
    '$http',
    function ($http) {
      return new (function () {
        this.bulkValidateTin = function (input, httpParams) {
          return $http (
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/malaysiaTaxIdentifier/bulkValidateTin`,
                method: 'POST',
                data: JSON.stringify(input)
              },
              httpParams
            )
          );
        }
      })();
    }
  ]);

  financeModule.factory('abp.services.finance.myInvois', [
    '$http',
    function ($http) {
      return new (function () {
        this.getRecentDocuments = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/myInvois/getRecentDocuments`,
                method: 'GET',
                params: input
              },
              httpParams
            )
          );
        };

        this.getDocuments = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/myInvois/getDocuments`,
                method: 'GET',
                params: input
              },
              httpParams
            )
          )
        }

        this.submitDocument = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/myInvois/submitDocument`,
                method: 'POST',
                data: input
              },
              httpParams
            )
          );
        };

        this.voidDocument = function (input, httpParams) {
          return $http(
            angular.extend(
              {
                url: `${__FINANCE_ENDPOINT__}/api/app/myInvois/voidDocument`,
                method: 'POST',
                data: input
              },
              httpParams
            )
          );
        };
      })();
    }
  ]);
})();
