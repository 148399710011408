(() => {
  angular
    .module('app')
    .controller(
      'host.views.corporates.masterPolicy.InsurancePlans.createEdit',
      CorporatesInsurancePlanCreateEditController
    );

  CorporatesInsurancePlanCreateEditController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$filter',
    'abp.services.app.benefitGroup',
    'abp.services.app.corporate',
    'abp.services.app.commonLookup'
  ];

  function CorporatesInsurancePlanCreateEditController(
    $scope,
    $state,
    $stateParams,
    $filter,
    benefitGroupSvc,
    corporateSvc,
    commonLookupSvc
  ) {
    const vm = this;

    const ccyFilter = $filter('currencyFormat');
    const hospitalizationDetails = [
      new TreatmentModel(7, App.localize('Hospitalization'), 'fas fa-bed', true),
    ];
    const maternityDetails = [new TreatmentModel(8, App.localize('Maternity'), 'fas fa-bed', true)];
    const isCopyAction = $stateParams.isCopy;

    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.countryCode = null;
    vm.hasScheduleOfBenefitEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
    vm.hasInpatientRegionalExpansion = abp.setting.getBoolean(
      'Hms.Feature.RegionalExpansionForInpatient'
    );

    vm.inpatientBenefitGroupId = $stateParams.insurancePlanId;
    vm.organizationId = $stateParams.organizationId;
    vm.masterPolicyId = $stateParams.masterPolicyId;
    vm.serviceType = 7;
    vm.isHospitalization = true;
    vm.loading = 0;
    vm.saving = 0;
    vm.isCreate = !vm.inpatientBenefitGroupId || isCopyAction;
    vm.buttonLabel = vm.isCreate ? App.localize('SaveAll') : App.localize('Update');
    vm.optionalExclusions = [];
    vm.categories = [];
    vm.costSharingExample = App.localize('CostSharingExample', vm.currencyCode);
    vm.showCostSharingExample = false;

    vm.beneficiary = {
      principal: 1,
      dependent: 2,
    };

    vm.submit = submit;

    /* eslint max-classes-per-file: ["error", 3] */
    function CoPay(bgModel, dto) {
      const that = this;

      that.parent = bgModel;

      // Basic properties

      that.beneficiary = 3;
      that.isPercentage = true;
      that.value = null;

      // View properties

      that.show = true;

      // Treatment type mapping

      that.principalMap = _.keyBy(bgModel.principalBenefits, 'id');
      that.dependentMap = _.keyBy(bgModel.dependentBenefits, 'id');

      // Private functions

      that.checkValid = function checkValid(id) {
        switch (that.beneficiary) {
          case 3:
            return (that.principalMap[id].valid 
              || (that.parent.hasDependentCovered === 1 && that.dependentMap[id].valid));
          case 1:
            return that.principalMap[id].valid;
          case 2:
            return that.dependentMap[id].valid;
          default:
            return false;
        }
      }

      // Public functions

      that.changed = () => {
        that.refreshTreatmentValidity();
      }

      that.refreshTreatmentValidity = () => {
        _.each(that.treatments, (e) => {
          e.treatment.valid = that.checkValid(e.treatment.id);
        });
      }

      that.toDto = function toDto() {
        if (vm.isHospitalization) {
          return {
            beneficiary: that.beneficiary,
            isPercentage: that.isPercentage,
            value: that.value,
            isAffectingAdmission: that.isAffectingAdmission,
            isAffectingPreHospitalization: that.isAffectingPreHospitalization,
            isAffectingPostHospitalization: that.isAffectingPostHospitalization,
            isAffectingSelfPayHospitalization: that.isAffectingSelfPayHospitalization,
          };
        }
        return {
          beneficiary: that.beneficiary,
          isPercentage: that.isPercentage,
          value: that.value,
          isAffectingNormalDelivery: that.isAffectingNormalDelivery,
          isAffectingCaesareanDelivery: that.isAffectingCaesareanDelivery,
          isAffectingPreNatalCheckUp: that.isAffectingPreNatalCheckUp,
          isAffectingPostNatalFollowUp: that.isAffectingPostNatalFollowUp,
          isAffectingNeoNatal: that.isAffectingNeoNatal,
        };
      }

      // Applicable treatment types

      const treatments = [];
      _.each(that.parent.principalBenefits, (e) => {
        const isValid = that.checkValid(e.id);
        treatments.push(
          new TreatmentApplyModel(new TreatmentModel(e.id, e.name, e.icon, isValid), isValid)
        );
      });
      that.treatments = treatments;

      // Initialization

      if (dto) {
        automap(dto, that);
        that.refreshTreatmentValidity();
      }
    }

    function Modifier(bgModel, dto) {
      const that = this;

      that.parent = bgModel;
      
      // Basic properties

      that.type = 0;
      that.cycle = 1;
      that.beneficiary = 3;
      that.poolMode = 0;
      that.value = null;
      that.interval = 2;
      that.treatmentTypes = [];

      // View properties

      that.show = true;
      that.description = '';

      // Treatment type mapping

      that.principalMap = _.keyBy(bgModel.principal, 'id');
      that.dependentMap = _.keyBy(bgModel.dependent, 'id');

      // Private functions

      that.checkValid = function checkValid(id) {
        switch (that.beneficiary) {
          case 3:
            return (
              that.principalMap[id].valid ||
              (that.parent.hasDependentCovered === 1 && that.dependentMap[id].valid)
            );
          case 1:
            return that.principalMap[id].valid;
          case 2:
            return that.dependentMap[id].valid;
          default:
            return false;
        }
      }

      that.validateModifierRules = function validateModifierRules() {
        // If modifier is not budget limit, cycle cannot be Per Visit.
        // Default to annually.

        if (that.type !== 0 && that.cycle === 4) {
          that.cycle = 1;
        }

        // If beneficiary is principal only, there is no pool to share.
        // If beneficiary is dependant only, split pool cannot be selected.
        // Default to individual pool.

        if (that.beneficiary === 1 || (that.beneficiary === 2 && that.poolMode === 2)) {
          that.poolMode = 1;
        }
      }

      // Public functions

      that.changed = function changed() {
        that.validateModifierRules();
        that.refreshTreatmentValidity();
        that.refreshDescription();
      }

      that.refreshTreatmentValidity = () => {
        _.each(that.treatments, (e) => {
          e.treatment.valid = that.checkValid(e.treatment.id);
        });
      }

      // Post method

      that.toDto = function toDto() {
        return {
          type: that.type,
          cycle: that.cycle,
          beneficiary: that.beneficiary,
          poolMode: that.poolMode,
          value: that.value,
          interval: that.interval,
        };
      }

      that.refreshDescription = function refreshDescription() {
        const thisType = that.type;
        const thisCycle = that.cycle;
        const thisInterval = that.interval;
        const thisBeneficiary = that.beneficiary;
        const thisPoolMode = that.poolMode;
        const thisValue = that.value;
        const thisTreatmentTypes = that.treatmentTypes;

        let desc = '';
        const ttNames = [];

        // eslint-disable-next-line default-case
        switch (thisCycle) {
          case 0:
            desc += `Every ${thisInterval} year(s)`;
            break;
          case 1:
            desc += 'Every year';
            break;
          case 2:
            desc += 'Every month';
            break;
          case 3:
            desc += 'Everyday';
            break;
          case 4:
            desc += 'Every visit';
            break;
          // no default
        }

        // eslint-disable-next-line default-case
        switch (thisType) {
          case 0:
          case 1:
            desc += ' limits ';
            break;
          case 2:
          case 3:
            desc += ' allows overdraft of ';
          // no default
        }

        // eslint-disable-next-line default-case
        switch (thisType) {
          case 0:
          case 2:
            desc += `${vm.currencyCode  } ${  thisValue}`;
            break;
          case 1:
          case 3:
            desc += `${thisValue  } visits`;
            break;
          // no default
        }

        // eslint-disable-next-line default-case
        switch (thisBeneficiary) {
          case 1:
            desc += ' for principal';
            break;
          case 2:
            desc += ' for dependants';
            break;
          case 3:
            desc += ' for principal & dependants';
            break;
          // no default
        }

        // eslint-disable-next-line default-case
        switch (thisPoolMode) {
          case 0:
            desc += ' where everyone share';
            break;
          case 1:
            desc += ' individually';
            break;
          case 2:
            desc += ' where dependents share';
            break;
          // no default
        }

        if (thisTreatmentTypes) {
          desc += ' on ';

          _.each(thisTreatmentTypes, (isApplied, idStr) => {
            const id = parseInt(idStr, 10);
            if (isApplied) {
              ttNames.push(_.find(vm.treatmentTypes, (a) => a.id === id).name);
            }
          });

          desc += ttNames.join(', ');
        }

        that.description = ttNames.length > 0 ? desc : 'Not applicable';
      }

      // Applicable treatment types

      const treatments = [];
      _.each(that.parent.principal, (e) => {
        const isValid = that.checkValid(e.id);
        treatments.push(
          new TreatmentApplyModel(new TreatmentModel(e.id, e.name, e.icon, isValid), isValid)
        );
      });
      that.treatments = treatments;

      // Initialization

      if (dto) {
        automap(dto, that);
        that.refreshTreatmentValidity();
      }

      that.refreshDescription();
    }

    function BenefitGroupModel(dto) {
      const that = this;

      that.id = 0;
      that.organizationId = vm.organizationId;
      that.masterPolicyId = vm.masterPolicyId;
      that.name = '';
      that.displayName = '';
      that.insurerId = '';
      that.insurer = {};

      // Benefits

      that.principal = vm.isHospitalization ? hospitalizationDetails : maternityDetails;
      that.dependent = vm.isHospitalization ? hospitalizationDetails : maternityDetails;

      // Benefit Modifiers

      that.modifiers = [];

      // Benefit summaries

      that.principalSummaries = [];
      that.showPrincipalFootnote = false;
      that.dependentSummaries = [];
      that.showDependentFootnote = false;

      // Functions

      that.refreshResult = function refreshResult() {
        const principalSummaries = [];
        const dependentSummaries = [];
        let showPrincipalFootnote = false;
        let showDependentFootnote = false;

        let coverDependent =
          (that.hospitalizationSetting && !!that.hospitalizationSetting.hasDependentCovered) ||
          (that.maternitySetting && !!that.maternitySetting.isSpouseCovered);
        const principal = _.map(
          _.filter(that.principal, 'valid'),
          (x) => new BenefitResult(x, true, true)
        );
        const dependent = coverDependent
          ? _.map(_.filter(that.dependent, 'valid'), (x) => new BenefitResult(x, true, true))
          : [];
        if (!dependent.length) coverDependent = false;
        const coverPrincipal = principal.length > 0;

        if (vm.isHospitalization) {
          if (coverDependent) {
            if (vm.dependentSettingPlaceHolder) {
              that.hospitalizationSetting.dependentScheduleOfBenefit = angular.copy(
                vm.dependentSettingPlaceHolder
              );
            } else if (
              vm.isCreate ||
              (!vm.isCreate && !that.hospitalizationSetting.dependentScheduleOfBenefit)
            ) {
              that.hospitalizationSetting.dependentScheduleOfBenefit = angular.copy(
                that.hospitalizationSetting.principalScheduleOfBenefit
              );
            }
          } else {
            if (that.hospitalizationSetting.dependentScheduleOfBenefit) {
              vm.dependentSettingPlaceHolder = angular.copy(
                that.hospitalizationSetting.dependentScheduleOfBenefit
              );
            }

            that.hospitalizationSetting.enableDependentSetting = 0;
            that.hospitalizationSetting.dependentScheduleOfBenefit = null;
          }
        }

        // Group result into stacks

        let modifiers = [];
        if (typeof vm.isHospitalization !== 'undefined' && vm.isHospitalization !== null) {
          modifiers = vm.isHospitalization
            ? that.hospitalizationSetting.modifiers
            : that.maternitySetting.modifiers;
        }

        _.each(modifiers, (mod) => {
          if (mod.cycle === 4 && mod.type !== 0) {
            mod.cycle = 3;
          }
        });

        _.each(
          _.groupBy(modifiers, (x) => {
            const interval = x.cycle === 0 ? x.interval : 1;
            return `${x.type}:${interval}:${x.cycle}`;
          }),
          (values) => {
            _.each(values, (modifier) => {
              const principalPortion = [];
              const dependentPortion = [];

              const beneficiary = coverDependent ? modifier.beneficiary : 1;
              let poolMode = coverDependent ? modifier.poolMode : 2;

              _.each(modifier.treatments, (t) => {
                let benefit;
                if (coverPrincipal && (beneficiary & 1) > 0) {
                  benefit = _.find(principal, (x) => x.treatment.id === t.treatment.id);
                  if (benefit) {
                    if (modifier.type === 0) {
                      benefit.unlimitedBudget = false;
                    } else if (modifier.type === 1) {
                      benefit.unlimitedVisit = false;
                    }
                    principalPortion.push(t.treatment);
                  }
                }
                if (coverDependent && (beneficiary & 2) > 0) {
                  benefit = _.find(dependent, (x) => x.treatment.id === t.treatment.id);
                  if (benefit) {
                    if (modifier.type === 0) {
                      benefit.unlimitedBudget = false;
                    } else if (modifier.type === 1) {
                      benefit.unlimitedVisit = false;
                    }
                    dependentPortion.push(t.treatment);
                  }
                }
              });

              if (principalPortion.length || dependentPortion.length) {
                const principalTreatmentNames = _.join(_.map(principalPortion, 'name'), '+');
                const dependentTreatmentNames = _.join(_.map(dependentPortion, 'name'), '+');

                if (poolMode === 0 && (!principalPortion.length || !dependentPortion.length)) {
                  poolMode = 2;
                }

                let principalSummaryRecord = null;
                if (principalTreatmentNames) {
                  principalSummaryRecord = _.find(
                    principalSummaries,
                    (x) => x.key === principalTreatmentNames
                  );

                  if (!principalSummaryRecord) {
                    principalSummaryRecord = new BenefitSummary(principalTreatmentNames);
                    principalSummaries.push(principalSummaryRecord);
                  }
                }

                let dependentSummaryRecord = null;
                if (dependentTreatmentNames) {
                  dependentSummaryRecord = _.find(
                    dependentSummaries,
                    (x) => x.key === dependentTreatmentNames
                  );

                  if (!dependentSummaryRecord) {
                    dependentSummaryRecord = new BenefitSummary(dependentTreatmentNames);
                    dependentSummaries.push(dependentSummaryRecord);
                  }
                }

                // Get modifier description

                let str = '';
                const strValue = _.isNil(modifier.value) ? '?' : modifier.value;
                // eslint-disable-next-line default-case
                switch (modifier.type) {
                  case 0:
                    str += ` ${ccyFilter(modifier.value, vm.currencyCode)}`;
                    break;
                  case 1:
                    str += ` ${strValue} ${App.localize('VisitLimit').toLowerCase()}`;
                    break;
                  case 2:
                    str += ` ${ccyFilter(modifier.value, vm.currencyCode)} (${App.localize(
                      'BudgetOverdraft'
                    ).toLowerCase()})`;
                    break;
                  case 3:
                    str += ` ${strValue}${App.localize('VisitOverdraft').toLowerCase()}`;
                    break;
                  // no default
                }

                let cycle = '';
                // eslint-disable-next-line default-case
                switch (modifier.cycle) {
                  case 0:
                    cycle = App.localize('XYears', modifier.interval).toLowerCase();
                    break;
                  case 1:
                    cycle = App.localize('Yearly').toLowerCase();
                    break;
                  case 2:
                    cycle = App.localize('PerEmploymentContract').toLowerCase();
                    break;
                  // no default
                }
                str += ` ${cycle}`;

                if (poolMode === 0) {
                  if (principalSummaryRecord) {
                    principalSummaryRecord.addItem(
                      modifier.type,
                      cycle,
                      '[1]',
                      modifier.value || 0
                    );
                    showPrincipalFootnote = true;
                  }
                  if (dependentSummaryRecord) {
                    dependentSummaryRecord.addItem(
                      modifier.type,
                      cycle,
                      '[1]',
                      modifier.value || 0
                    );
                    showDependentFootnote = true;
                  }
                } else if (poolMode === 1) {
                  if (principalSummaryRecord) {
                    principalSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                  }
                  if (dependentSummaryRecord) {
                    dependentSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                  }
                } else if (poolMode === 2) {
                  if (principalSummaryRecord) {
                    principalSummaryRecord.addItem(modifier.type, cycle, '', modifier.value || 0);
                  }
                  if (dependentSummaryRecord) {
                    dependentSummaryRecord.addItem(
                      modifier.type,
                      cycle,
                      '[2]',
                      modifier.value || 0
                    );
                    showDependentFootnote = true;
                  }
                }
              }
            });
          }
        );

        // Print summary

        principal.forEach((x) => {
          if (x.unlimitedVisit || x.unlimitedBudget) {
            let summary = _.find(principalSummaries, (y) => y.key === x.treatment.name);
            if (!summary) {
              summary = new BenefitSummary(x.treatment.name);
              principalSummaries.push(summary);
            }

            if (x.unlimitedBudget && x.unlimitedVisit) {
              summary.addText(App.localize('Unlimited'));
            } else {
              if (x.unlimitedBudget) {
                summary.addText(App.localize('UnlimitedBudget'));
              }
              if (x.unlimitedVisit) {
                summary.addText(App.localize('UnlimitedVisit'));
              }
            }
          }
        });
        that.principalSummaries = principalSummaries;
        that.showPrincipalFootnote = showPrincipalFootnote;

        dependent.forEach((x) => {
          if (x.unlimitedVisit || x.unlimitedBudget) {
            let summary = _.find(dependentSummaries, (y) => y.key === x.treatment.name);
            if (!summary) {
              summary = new BenefitSummary(x.treatment.name);
              dependentSummaries.push(summary);
            }
            if (x.unlimitedBudget && x.unlimitedVisit) {
              summary.addText(App.localize('Unlimited'));
            } else {
              if (x.unlimitedBudget) {
                summary.addText(App.localize('UnlimitedBudget'));
              }
              if (x.unlimitedVisit) {
                summary.addText(App.localize('UnlimitedVisit'));
              }
            }
          }
        });
        that.dependentSummaries = dependentSummaries;
        that.showDependentFootnote = showDependentFootnote;
      }

      that.addModifier = function addModifier() {
        const modifier = new Modifier(this);
        if (vm.isHospitalization) {
          vm.supportPlan.hospitalizationSetting.modifiers.push(modifier);
        } else {
          vm.supportPlan.maternitySetting.modifiers.push(modifier);
        }
      }

      that.removeModifier = function removeModifer(index) {
        abp.message.confirm(
          App.localize('DeleteBenefitModifierWarningMessage'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              $scope.$apply(() => {
                if (vm.isHospitalization) {
                  vm.supportPlan.hospitalizationSetting.modifiers.splice(index, 1);
                } else {
                  vm.supportPlan.maternitySetting.modifiers.splice(index, 1);
                }
                that.refreshResult();
              });
              return true;
            }

            return false;
          }
        );
      }

      that.addCoPay = function addCopay() {
        const coPay = new CoPay(this);
        if (vm.isHospitalization) {
          if (that.hospitalizationSetting.coPays == null) that.hospitalizationSetting.coPays = [];

          that.hospitalizationSetting.coPays.push(coPay);
        } else {
          if (that.maternitySetting.coPays == null) that.maternitySetting.coPays = [];

          that.maternitySetting.coPays.push(coPay);
        }
      }

      that.removeCoPay = (index) => {
        abp.message.confirm(
          App.localize('DeleteBenefitModifierWarningMessage'),
          App.localize('AreYouSure'),
          (d) => {
            if (d) {
              $scope.$apply(() => {
                if (vm.isHospitalization) {
                  that.hospitalizationSetting.coPays.splice(index, 1);
                  vm.hospitalizationCopayForm.$setDirty();
                } else {
                  that.maternitySetting.coPays.splice(index, 1);
                  vm.maternityCopayForm.$setDirty();
                }
                that.refreshResult();
              });
              return true;
            }

            return false;
          }
        );
      }

      // Post method

      that.toDto = function toDto() {
        function getOptionalExclusionIds(exclusions) {
          return _(exclusions).filter('valid').map('id').value();
        }

        function getModifierDto(modifiers) {
          return _(modifiers)
            .map((m) => m.toDto())
            .value();
        }

        function getCoPayDto(coPays) {
          return _(coPays)
            .map((m) => m.toDto())
            .value();
        }

        function getHospitalizationBenefitSettingsDto(settings) {
          let dependentSettings =
            settings.hasDependentCovered === 0 ? null : settings.dependentScheduleOfBenefit;
          if (
            vm.isCreate &&
            settings.hasDependentCovered === 1 &&
            settings.enableDependentSetting === 0
          ) {
            dependentSettings = settings.principalScheduleOfBenefit;
          }

          return {
            remarks: settings.remarks,
            hasDependentCovered:
              settings.hasDependentCovered === 1 ? settings.hasDependentCovered : 0,
            admissionWaitingAccidentDays:
              settings.isAccidentAdmission === 1 ? settings.admissionWaitingAccidentDays : 0,
            admissionWaitingIllnessDays:
              settings.isIllnessAdmission === 1 ? settings.admissionWaitingIllnessDays : 0,
            admissionWaitingPreExistingDays:
              settings.isPreExisting === 1 ? settings.admissionWaitingPreExistingDays : 0,
            admissionWaitingNonAccidentalIllnessDays:
              settings.isNonAccidentalIllness === 1
                ? settings.admissionWaitingNonAccidentalIllnessDays
                : 0,
            exclusionVersionId: settings.exclusionVersionId,
            isDefaultExclusionsCovered: settings.isDefaultExclusionsCovered === 1,
            coveredOptionalExclusions: getOptionalExclusionIds(settings.coveredOptionalExclusions),
            isCoInsurance: settings.isCoInsurance,
            isRoomAndBoardExceedEntitlement: settings.isRoomAndBoardExceedEntitlement,
            copays: getCoPayDto(settings.coPays),
            coPayExclusions: settings.coPayExclusions,
            coPayType: settings.coPayType,
            modifiers: getModifierDto(settings.modifiers),
            principalScheduleOfBenefit: settings.principalScheduleOfBenefit,
            dependentScheduleOfBenefit: dependentSettings,
          };
        }

        let hospitalizationSetting = null;
        let maternitySettings = null;
        if (vm.isHospitalization) {
          hospitalizationSetting = getHospitalizationBenefitSettingsDto(
            that.hospitalizationSetting
          );
        } else {
          maternitySettings = getMaternityBenefitSettingsDto(that.maternitySetting);
        }

        return {
          id: that.id,
          tenantId: that.tenantId,
          serviceType: that.serviceType,
          name: that.name,
          displayName: that.displayName,
          insurerId: that.insurerId || null,
          masterPolicyId: vm.masterPolicyId,
          hospitalizationSetting,
          maternitySetting: maternitySettings,
        };
      }

      // Initialization
      
      if (dto) {
          automap(dto, that);
          if (!that.insurerId) that.insurerId = '';

          // Inpatient or maternity settings.

          if (that.serviceType === 7) {
            // Basics

            that.hospitalizationSetting.hasDependentCovered = dto.hospitalizationSetting
              .hasDependentCovered
              ? 1
              : 0;
            that.hospitalizationSetting.isAccidentAdmission =
              dto.hospitalizationSetting.admissionWaitingAccidentDays > 0 ? 1 : 0;
            that.hospitalizationSetting.isIllnessAdmission =
              dto.hospitalizationSetting.admissionWaitingIllnessDays > 0 ? 1 : 0;
            that.hospitalizationSetting.isPreExisting =
              dto.hospitalizationSetting.admissionWaitingPreExistingDays > 0 ? 1 : 0;
            that.hospitalizationSetting.isNonAccidentalIllness =
              dto.hospitalizationSetting.admissionWaitingNonAccidentalIllnessDays > 0 ? 1 : 0;
            that.hospitalizationSetting.isDefaultExclusionsCovered = dto.hospitalizationSetting
              .isDefaultExclusionsCovered
              ? 1
              : 0;

            that.hospitalizationSetting.coveredOptionalExclusions = buildExclusionTypeList(
              vm.optionalExclusions,
              dto.hospitalizationSetting.coveredOptionalExclusions
            );

            // Benefits

            const clonedHospitalizationModifiers = dto.hospitalizationSetting.modifiers.slice(0);
            that.hospitalizationSetting.modifiers = [];
            _.each(clonedHospitalizationModifiers, (x) => {
              that.hospitalizationSetting.modifiers.push(new Modifier(this, x));
            });

            // Cost sharing
            that.hospitalizationSetting.isCoInsurance = dto.hospitalizationSetting.isCoInsurance;
            that.hospitalizationSetting.isRoomAndBoardExceedEntitlement =
              dto.hospitalizationSetting.isRoomAndBoardExceedEntitlement;
            that.hospitalizationSetting.coPayExclusions =
              dto.hospitalizationSetting.coPayExclusions;
            that.hospitalizationSetting.coPayType = dto.hospitalizationSetting.coPayType;
            that.hospitalizationSetting.coPayExclusionCategories = [];

            if (dto.hospitalizationSetting.coPays) {
              const clonedHospitalizationCoPays = dto.hospitalizationSetting.coPays.slice(0);
              that.hospitalizationSetting.coPays = [];
              _.each(clonedHospitalizationCoPays, (x) => {
                that.hospitalizationSetting.coPays.push(new CoPay(this, x));
              });
            }

            // Schedule of benefit

            that.hospitalizationSetting.enableDependentSetting = 0;

            that.hospitalizationSetting.principalScheduleOfBenefit =
              dto.hospitalizationSetting.principalScheduleOfBenefit;
            that.hospitalizationSetting.dependentScheduleOfBenefit =
              dto.hospitalizationSetting.dependentScheduleOfBenefit;
          } else {
            // Basics

            that.maternitySetting.isSpouseCovered = dto.maternitySetting.isSpouseCovered ? 1 : 0;
            that.maternitySetting.isHasMotherAgeLimit =
              dto.maternitySetting.motherMaxAge > 0 ? 1 : 0;
            that.maternitySetting.isMaternitybenefits =
              dto.maternitySetting.admissionWaitingDays > 0 ? 1 : 0;
            that.maternitySetting.isMaternitybenefits =
              dto.maternitySetting.admissionWaitingDays > 0 ? 1 : 0;
            that.maternitySetting.isUnlimitedDelivery =
              dto.maternitySetting.deliveryLimit === 0 ? 1 : 0;

            // Benefits

            const clonedMaternityModifiers = dto.maternitySetting.modifiers.slice(0);
            that.maternitySetting.modifiers = [];
            _.each(clonedMaternityModifiers, (x) => {
              that.maternitySetting.modifiers.push(new Modifier(this, x));
            });

            // Cost sharing

            that.maternitySetting.isCoInsurance = dto.maternitySetting.isCoInsurance;

            if (dto.maternitySetting.coPays) {
              const clonedMaternityCoPays = dto.maternitySetting.coPays.slice(0);
              that.maternitySetting.coPays = [];
              _.each(clonedMaternityCoPays, (x) => {
                that.maternitySetting.coPays.push(new CoPay(this, x));
              });
            }

            // Normal delivery

            that.maternitySetting.normalDelivery.amountType = dto.maternitySetting.normalDelivery
              .isAsCharged
              ? 0
              : 1;
            if (!that.maternitySetting.normalDelivery.isCovered) {
              that.maternitySetting.normalDelivery.amountType = 2;
            }
            that.maternitySetting.normalDelivery.maxDaysTimesType = dto.maternitySetting
              .normalDelivery.isUnlimited
              ? 1
              : 0;

            // Caesarean delivery

            that.maternitySetting.caesareanDelivery.amountType = dto.maternitySetting
              .caesareanDelivery.isAsCharged
              ? 0
              : 1;
            if (!that.maternitySetting.caesareanDelivery.isCovered) {
              that.maternitySetting.caesareanDelivery.amountType = 2;
            }
            that.maternitySetting.caesareanDelivery.maxDaysTimesType = dto.maternitySetting
              .caesareanDelivery.isUnlimited
              ? 1
              : 0;

            // Normal delivery for twins and above

            that.maternitySetting.normalDeliveryTwinsAndAbove.amountType = dto.maternitySetting
              .normalDeliveryTwinsAndAbove.isAsCharged
              ? 0
              : 1;
            if (!that.maternitySetting.normalDeliveryTwinsAndAbove.isCovered) {
              that.maternitySetting.normalDeliveryTwinsAndAbove.amountType = 2;
            }
            that.maternitySetting.normalDeliveryTwinsAndAbove.maxDaysTimesType = dto
              .maternitySetting.normalDeliveryTwinsAndAbove.isUnlimited
              ? 1
              : 0;

            // Caesarean delivery for twins and above

            that.maternitySetting.caesareanDeliveryTwinsAndAbove.amountType = dto.maternitySetting
              .caesareanDeliveryTwinsAndAbove.isAsCharged
              ? 0
              : 1;
            if (!that.maternitySetting.caesareanDeliveryTwinsAndAbove.isCovered) {
              that.maternitySetting.caesareanDeliveryTwinsAndAbove.amountType = 2;
            }
            that.maternitySetting.caesareanDeliveryTwinsAndAbove.maxDaysTimesType = dto
              .maternitySetting.caesareanDeliveryTwinsAndAbove.isUnlimited
              ? 1
              : 0;

            // Pre-natal check-up

            that.maternitySetting.preNatalCheckUp.isCovered = dto.maternitySetting.preNatalCheckUp
              .isCovered
              ? 1
              : 0;
            that.maternitySetting.preNatalCheckUp.amountType = dto.maternitySetting.preNatalCheckUp
              .isAsCharged
              ? 0
              : 1;
            that.maternitySetting.preNatalCheckUp.maxDaysTimesType = dto.maternitySetting
              .preNatalCheckUp.isUnlimited
              ? 1
              : 0;

            // Post-natal follow-up

            that.maternitySetting.postNatalFollowUp.isCovered = dto.maternitySetting
              .postNatalFollowUp.isCovered
              ? 1
              : 0;
            that.maternitySetting.postNatalFollowUp.amountType = dto.maternitySetting
              .postNatalFollowUp.isAsCharged
              ? 0
              : 1;
            that.maternitySetting.postNatalFollowUp.maxDaysTimesType = dto.maternitySetting
              .postNatalFollowUp.isUnlimited
              ? 1
              : 0;

            // Miscarriage

            that.maternitySetting.miscarriage.amountType = dto.maternitySetting.miscarriage
              .isAsCharged
              ? 0
              : 1;
            that.maternitySetting.miscarriage.maxDaysTimesType = dto.maternitySetting.miscarriage
              .isUnlimited
              ? 1
              : 0;

            // Covers pregnancy related complications

            that.maternitySetting.isOptionalExclusionsCovered =
              dto.maternitySetting.coveredOptionalExclusions &&
              dto.maternitySetting.coveredOptionalExclusions.length > 0
                ? 1
                : 0;
            that.maternitySetting.coveredOptionalExclusions = buildExclusionTypeList(
              vm.optionalExclusions,
              dto.maternitySetting.coveredOptionalExclusions
            );

            // Neo-natal

            that.maternitySetting.neoNatal.isCovered = dto.maternitySetting.neoNatal.isCovered
              ? 1
              : 0;
          }
      }

      that.refreshResult();
    }

    init();

    function init() {
      vm.loading += 1;
      corporateSvc
        .getOrganizationName({ id: vm.organizationId })
        .success((data) => {
          vm.organizationName = data.name;
        })
        .finally(() => {
          vm.loading -= 1;
        });
      if (vm.hasInpatientRegionalExpansion) getCountryAndCurrency();
      getSupportPlan();
    }

    function getCountryAndCurrency() {
      vm.loading += 1;
      commonLookupSvc
        .getCountryAndCurrencyByTenantId({ tenantId: vm.organizationId })
        .success((data) => {
          if (data.currency.code) vm.currencyCode = data.currency.code;
          if (data.countryCode) vm.countryCode = data.countryCode;
          vm.costSharingExample = App.localize('CostSharingExample', vm.currencyCode);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getSupportPlan() {
      vm.loading += 1;
      benefitGroupSvc
        .getInpatientBenefitGroupForEdit({
          id: vm.inpatientBenefitGroupId,
          organizationId: $stateParams.organizationId,
          masterPolicyId: vm.masterPolicyId,
          serviceType: 7,
        })
        .success((data) => {
          if (isCopyAction) {
            data.benefitGroup.id = 0;
            data.benefitGroup.name = null;
            data.benefitGroup.displayName = null;
            data.benefitGroup.hospitalizationSetting.remarks = null;
          }

          vm.optionalExclusions = data.optionalExclusions;
          vm.isHospitalization = data.benefitGroup.serviceType === 7;
          vm.supportPlan = new BenefitGroupModel(data.benefitGroup);
          vm.categories = data.categories;
          vm.insurer = data.newInsurer;
          if (vm.isHospitalization) {
            vm.enableCostSharingHospitalization =
              vm.supportPlan.hospitalizationSetting.isCoInsurance == null ? 0 : 1;
            vm.dependentSettingPlaceHolder = angular.copy(
              vm.supportPlan.hospitalizationSetting.dependentScheduleOfBenefit
            );

            if (vm.hasTxtFileSupport) {
              // Filter SOB claim items

              const hospitalizationItems = [
                'RoomAndBoard',
                'IcuRoomAndBoard',
                'ChildGuardianBenefit',
                'InHospitalDoctorVisit',
                'SurgicalFee',
                'AnaesthetistFee',
                'OrganTransplant',
                'DaySurgery',
                'OperatingTheatre',
                'HospitalSuppliesAndServices',
                'AmbulanceFee',
                'MedicalReport',
              ];

              vm.categories = _.filter(vm.categories, (x) =>
                hospitalizationItems.includes(x.value)
              );

              if (vm.enableCostSharingHospitalization === 1) {
                // Copy coPayExclusions to coPayExclusionCategories

                const clonedHospitalizationCoPayExclusions =
                  vm.supportPlan.hospitalizationSetting.coPayExclusions.slice(0);
                vm.supportPlan.hospitalizationSetting.coPayExclusionCategories = [];
                _.each(clonedHospitalizationCoPayExclusions, (x) => {
                  const category = _.find(vm.categories, (c) => c.value === x);
                  vm.supportPlan.hospitalizationSetting.coPayExclusionCategories.push(category);
                });

                // Set cost-sharing type if editing previous copay setting.

                if (
                  !_.isNil(vm.supportPlan.hospitalizationSetting.isCoInsurance) &&
                  _.isNil(vm.supportPlan.hospitalizationSetting.coPayType)
                )
                  vm.supportPlan.hospitalizationSetting.coPayType = vm.supportPlan
                    .hospitalizationSetting.isCoInsurance
                    ? 1
                    : 2;
              }
            }
          } else {
            vm.enableCostSharingMaternity =
              vm.supportPlan.maternitySetting.isCoInsurance == null ? 0 : 1;
          }
          vm.supportPlan.refreshResult();
          vm.supportPlan.hasMultipleVersion = data.hasMultipleVersion;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function submit() {
      if (!App.isFormValid($scope.supportPlanCreateEditForm)) {
        abp.notify.error(App.localize('GeneralInvalidFormInputError'));
        return;
      }

      if (vm.enableCostSharingHospitalization === 0) {
        vm.supportPlan.hospitalizationSetting.isCoInsurance = null;
        vm.supportPlan.hospitalizationSetting.isRoomAndBoardExceedEntitlement = null;
        vm.supportPlan.hospitalizationSetting.coPays = [];
        vm.supportPlan.hospitalizationSetting.coPayExclusions = [];
        vm.supportPlan.hospitalizationSetting.coPayType = null;
      } else if (vm.hasTxtFileSupport && vm.enableCostSharingHospitalization === 1) {
        // Copy coPayExclusionCategories to coPayExclusions

        const clonedHospitalizationCoPayExclusionCategories =
          vm.supportPlan.hospitalizationSetting.coPayExclusionCategories.slice(0);
        vm.supportPlan.hospitalizationSetting.coPayExclusions = [];
        vm.supportPlan.hospitalizationSetting.coPayExclusions = _.map(
          clonedHospitalizationCoPayExclusionCategories,
          (x) => x.value
        );

        // Set value for isRoomAndBoardExceedEntitlement if null.

        if (vm.supportPlan.hospitalizationSetting.isRoomAndBoardExceedEntitlement == null)
          vm.supportPlan.hospitalizationSetting.isRoomAndBoardExceedEntitlement = false;
      }

      if (vm.enableCostSharingMaternity === 0) {
        vm.supportPlan.maternitySetting.isCoInsurance = null;
        vm.supportPlan.maternitySetting.coPays = [];
      }

      vm.saving += 1;
      benefitGroupSvc
        .createOrUpdateInpatientBenefitGroup(vm.supportPlan.toDto())
        .success(() => {
          abp.notify.info(App.localize('SuccessfullySaved'));
          $state.go('host.corporateMasterPolicies.insurancePlans', {
            organizationId: vm.organizationId,
            masterPolicyId: vm.masterPolicyId,
          });
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function automap(source, target) {
      _.forIn(source, (v, k) => {
        target[k] = source[k];
      });
    }

    function buildExclusionTypeList(optionalExclusions, coveredOptionalExclusions) {
      const exclusions = [];
      let coveredExclusions = coveredOptionalExclusions;
      if (!coveredOptionalExclusions) coveredExclusions = [];

      _.each(optionalExclusions, (exc) => {
        exclusions.push(
          new ExclusionModel(
            exc.value,
            exc.name,
            _.indexOf(coveredExclusions, exc.value) >= 0,
            exc.isLatestVersion
          )
        );
      });

      return exclusions;
    }

    function ExclusionModel(id, name, valid, isLatestVersion) {
      this.id = id;
      this.name = name;
      this.valid = valid;
      this.isLatestVersion = isLatestVersion;
    }

    function TreatmentModel(id, name, icon, valid) {
      this.id = id;
      this.name = name;
      this.icon = icon;
      this.valid = valid;
    }

    function TreatmentApplyModel(treatment, applicable) {
      this.treatment = treatment;
      this.applicable = applicable;
    }

    function BenefitResult(treatment, unlimitedBudget, unlimitedVisit) {
      this.treatment = treatment;
      this.unlimitedBudget = unlimitedBudget;
      this.unlimitedVisit = unlimitedVisit;
    }

    function BenefitSummaryItem(type, cycle, poolMode, value) {
      this.type = type;
      this.cycle = cycle;
      this.poolMode = poolMode;
      this.value = value;

      this.toString = () => {
        let str = '';
        const strValue = _.isNil(this.value) ? '?' : this.value;
        // eslint-disable-next-line default-case
        switch (this.type) {
          case 0:
            str += `RM${strValue}`;
            break;
          case 1:
            str += `${strValue} ${this.value > 1 ? 'visits' : 'visit'}`;
            break;
          case 2:
            str += `RM${strValue} overdraft`;
            break;
          case 3:
            str += `${strValue} overdraft ${this.value > 1 ? 'visits' : 'visit'}`;
            break;
          // no default
        }

        str += ` ${this.cycle} ${this.poolMode}`;
        return str;
      };
    }

    function BenefitSummary(key, value) {
      this.items = [];
      this.texts = [];

      this.key = key;
      this.value = value;

      this.addItem = (type, cycle, poolMode, itemValue) => {
        const item = _.find(
          this.items,
          (x) => x.type === type && x.cycle === cycle && x.poolMode === poolMode
        );

        if (!item) {
          this.items.push(new BenefitSummaryItem(type, cycle, poolMode, itemValue));
        } else {
          item.value += itemValue;
        }
      };

      this.addText = (text) => {
        this.texts.push(text);
      };

      this.toString = () =>
        _.concat(
          _.map(this.items, (x) => x.toString()),
          this.texts
        ).join(', ');
    }
  }
})();