import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('common.views.postHospitalizationClaims.processClaim', ProcessClaimPageController);

  ProcessClaimPageController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    '$window',
    'abp.services.app.hostServiceRequest',
    'abp.services.app.commonLookup',
    'Hms.PatientTickets.PatientTicketItem',
    'moment',
    'appConstants',
  ];

  function ProcessClaimPageController(
    $state,
    $stateParams,
    $uibModal,
    $window,
    hostServiceRequestSvc,
    commonLookupSvc,
    constsPatientTicketItem,
    moment,
    appConstants
  ) {
    const vm = this;

    vm.isHost = App.isHost();
    vm.constsPatientTicketItem = constsPatientTicketItem;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.defaultMaxAllowance = parseFloat(abp.setting.get('Hms.General.MaxAllowanceAmountLimit'));
    vm.hasSpecialistWorkflowEnhancement = abp.setting.getBoolean(
      'Hms.Feature.SpecialistWorkflowEnhancement'
    );

    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );

    vm.coPay = {
      isCoInsurance: false,
      isPercentage: false,
      value: 0,
    };
    vm.coPayLabel = App.localize('Copay');

    vm.loading = 0;
    vm.saving = 0;
    vm.hasSupportingDocuments = 0;
    vm.hasState = 1;
    vm.requestDetailsTrigger = 0;
    vm.newGuaranteeAmount = 0;
    vm.canScheduleApproval = false;
    vm.isOpen = true;
    vm.showTopUpColumn = true;
    vm.topUpNotPossible = false;
    vm.filteredIcds = [];
    let delegateUsers = [];
    let recommendUsers = [];
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.approveChecklistItems = [
      {
        text: 'Amount correct',
        isChecked: false,
        required: true,
      },
      {
        text: 'Detailed tax invoice',
        isChecked: false,
        required: true,
      },
      {
        text: 'Patient Consent & Claim Form',
        isChecked: false,
        required: false,
      },
      {
        text: 'Diagnosis form',
        isChecked: false,
        required: false,
      },
      {
        text: 'Documents clear',
        isChecked: false,
        required: false,
      },
    ];

    vm.claimNumber = $stateParams.claimNumber;
    vm.ticket = {
      items: [],
    };

    const approveMinRequirements = [
      vm.approveChecklistItems[0].text,
      vm.approveChecklistItems[1].text,
    ];

    vm.addTreatmentCategory = addTreatmentCategory;
    vm.removeTreatmentCategory = removeTreatmentCategory;
    vm.clearTreatmentCategories = clearTreatmentCategories;
    vm.addSection = addSection;
    vm.removeSection = removeSection;

    vm.viewClaimPolicy = viewClaimPolicy;
    vm.reject = reject;
    vm.isPartialApprove = isPartialApprove;
    vm.canFullApprove = canFullApprove;
    vm.approve = approve;
    vm.issueMc = issueMc;
    vm.removeMc = removeMc;
    vm.getMcDurationDescription = getMcDurationDescription;
    vm.saveDraft = saveDraft;

    vm.getTotalBillAmount = getTotalBillAmount;
    vm.getTotalCoveredAmount = getTotalCoveredAmount;
    vm.getTotalUncoveredAmount = getTotalUncoveredAmount;
    vm.getCopaymentAmount = getCopaymentAmount;
    vm.getCoveredAmount = getCoveredAmount;
    vm.getCollectCashAmount = getCollectCashAmount;
    vm.getEntitledAmount = getEntitledAmount;
    vm.getExceededAmount = getExceededAmount;
    vm.getTopUpAmount = getTopUpAmount;
    vm.getEmployeeBalance = getEmployeeBalance;
    vm.getFinalGuaranteedAmount = getFinalGuaranteedAmount;
    vm.isTotalBillAmountValid = isTotalBillAmountValid;
    vm.hasTopUpCalculation = hasTopUpCalculation;
    vm.getPreviousCopaymentAmount = getPreviousCopaymentAmount;

    vm.showTopUpModeSelection = showTopUpModeSelection;
    vm.isActualVisitDateUpdated = isActualVisitDateUpdated;
    vm.getCurrentClaimAmount = getCurrentClaimAmount;
    vm.getCurrentUncoveredAmount = getCurrentUncoveredAmount;
    vm.getCurrentCoPay = getCurrentCoPay;
    vm.getCurrentExceededAmount = getCurrentExceededAmount;
    vm.getCurrentTotalCoveredAmount = getCurrentTotalCoveredAmount;
    vm.getCurrentCollectCashAmount = getCurrentCollectCashAmount;
    vm.getNewGlAmount = getNewGlAmount;
    vm.getNewExceededAmount = getNewExceededAmount;
    vm.getTopUpExceededAmount = getTopUpExceededAmount;
    vm.getNewTopUpAmount = getNewTopUpAmount;
    vm.getTotalPayableAmount = getTotalPayableAmount;

    vm.openApprovalModal = openApprovalModal;
    vm.openRejectionModal = openRejectionModal;
    vm.openDelegationModal = openDelegationModal;
    vm.openRecommendationModal = openRecommendationModal;
    vm.openEscalationModal = openEscalationModal;
    vm.query = query;
    vm.preview = preview;

    init();

    function init() {
      getFglRequestDetails();
    }

    function getFglRequestDetails() {
      vm.loading += 1;
      hostServiceRequestSvc
        .getPostHospitalizationFglRequestDetails({
          id: $stateParams.claimNumber,
        })
        .success((data) => {
          vm.claim = data.claim;
          vm.panel = data.panel;
          vm.patient = data.patient;
          vm.admissionRecord = data.admissionGuaranteeLetter;
          vm.organization = data.organization;
          vm.letterNumber = data.claim.letterNumber;
          vm.ticket = data.ticket;
          vm.topUpOption = data.topUpOption;
          vm.hasSupportingDocuments = !!(
            vm.claim.supportingDocuments && vm.claim.supportingDocuments.length
          );

          vm.treatmentCategories = data.specialistTreatmentCategories;
          vm.panelLocationDoctors = data.panelLocationDoctors;
          vm.isInsuranceBenefitGroup = vm.patient.isInsuranceBenefitGroup;
          vm.timelineRemarks = vm.claim.timelineRemarks;
          vm.internalRemarks = vm.claim.internalRemarks;

          if (vm.claim.approvalChecklistItems.length > 0 && vm.claim.requestType !== 6) {
            for (let i = 0; i < vm.approveChecklistItems.length; i += 1) {
              vm.approveChecklistItems[i].isChecked = vm.claim.approvalChecklistItems[i].isSelected;
            }
          }

          if (vm.ticket.items.length <= 0) {
            vm.ticket.items.push({
              doctorId: null,
              doctorName: '',
              coveredAmount: 0,
              uncoveredAmount: 0,
              treatmentCategoryItems: [],
            });
            addTreatmentCategory(vm.ticket.items[0]);
          }

          if (vm.ticket.medicalCertificate.startDate && vm.ticket.medicalCertificate.endDate) {
            vm.medicalCertificate = vm.ticket.medicalCertificate;
            vm.medicalCertificate.remarks = vm.ticket.medicalCertificate.remark;
          }

          vm.glRemarks = vm.claim.serviceRequestRemark;

          vm.showTopUpColumn = vm.hasTopUpCalculation();

          vm.topUpNotPossible =
            vm.showTopUpColumn &&
            vm.topUpOption != null &&
            vm.claim.guaranteedAmount >
              Math.min(vm.topUpOption.corporateBalance, vm.topUpOption.employeeBalance);

          vm.coPayValueDisplay = vm.claim.isCoPayPercentage
          ? `${vm.claim.coPayValue}%`
          : _.filter('currencyFormat')(vm.claim.coPayValue, vm.currencyCode);
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function addTreatmentCategory(record) {
      const r = record;

      if (!r.treatmentCategoryItems) {
        r.treatmentCategoryItems = [];
      }

      r.treatmentCategoryItems.push({
        code: r.code,
        coveredRemarks: r.coveredRemarks,
        coveredAmount: r.coveredAmount,
        uncoveredRemarks: r.uncoveredRemarks,
        uncoveredAmount: r.uncoveredAmount,
      });
    }

    function removeTreatmentCategory(record, treatmentId) {
      const r = vm.ticket.items[record];
      r.treatmentCategoryItems.splice(treatmentId, 1);
    }

    function clearTreatmentCategories(category) {
      const c = category;
      c.treatmentCategoryItems = [];
      addTreatmentCategory(c);
    }

    function addSection(type) {
      const section = {
        doctorId: type || null,
        doctorName: type || null,
        treatmentCategoryItems: [
          {
            code: type || null,
            name: type || null,
            coveredRemarks: type || null,
            coveredAmount: 0,
            uncoveredRemarks: type || null,
            uncoveredAmount: 0,
          },
        ],
      };

      vm.ticket.items.push(section);
    }

    function removeSection(section) {
      vm.ticket.items.splice(section, 1);
    }

    // To indicate if allowance or inital guaranteed amount will be used as a limit.

    function hasTopUpCalculation() {
      if (!vm.claim) return false;

      return vm.claim.topUpRequestAmount > 0;
    }

    /* Amounts Calculation */

    // Total bill amount.

    function getTotalBillAmount() {
      let totalBillAmount = 0;
      _.forEach(vm.ticket.items, (item) => {
        _.forEach(item.treatmentCategoryItems, (p) => {
          totalBillAmount += p.coveredAmount + p.uncoveredAmount;
        });

        item.totalBillAmount = App.roundAmount(totalBillAmount);
      });

      return App.roundAmount(totalBillAmount);
    }

    // Total sum of all the covered patient ticket items.
    // Use to calculate copayment purpose only.

    function getTotalCoveredAmount() {
      let amount = 0;
      _.forEach(vm.ticket.items, (item) => {
        _.forEach(item.treatmentCategoryItems, (p) => {
          amount += p.coveredAmount;
        });

        item.coveredAmount = App.roundAmount(amount);
      });
      return App.roundAmount(amount);
    }

    function getTotalPayableAmount(totalCoveredAmount, hasTopUp) {
      return getCoveredAmount() - getExceededAmount(totalCoveredAmount, hasTopUp);
    }

    // Total uncovered amount.

    function getTotalUncoveredAmount() {
      _.forEach(vm.ticket.items, (item) => {
        item.uncoveredAmount = item.totalBillAmount - item.coveredAmount;
      });

      return getTotalBillAmount() - getTotalCoveredAmount();
    }

    // Calculate copayment.
    // If copayment is percentage, copayment is calculated based on
    // total patient ticket items covered amount.

    function getCopaymentAmount() {
      if (!vm.claim || !vm.claim.coPayValue) return 0;

      let copay = vm.claim.coPayValue;
      const totalCoveredAmount = getTotalCoveredAmount();

      if (vm.claim.isCoPayPercentage) copay = (totalCoveredAmount * vm.claim.coPayValue) / 100;
      else copay = Math.min(totalCoveredAmount, copay);

      return App.roundAmount(copay);
    }

    // Get previous copayment value (only applicable for adjustment request)

    function getPreviousCopaymentAmount(previousGuaranteedAmount) {
      let copayment = 0;
      if (vm.claim.coPayValue) {
        copayment = vm.claim.isCoPayPercentage
          ? (previousGuaranteedAmount * vm.claim.coPayValue) / 100
          : vm.claim.coPayValue;
      }
      return copayment;
    }

    // Calculate the eligible claim amount.

    function getCoveredAmount() {
      const totalCoveredAmount = getTotalCoveredAmount();
      const copay = getCopaymentAmount();

      const coveredAmount = totalCoveredAmount - copay;

      return App.roundAmount(coveredAmount);
    }

    // Calculate exceeded amount if covered amount > entitlement.

    function getExceededAmount(totalCoveredAmount, hasTopUp) {
      if (!vm.claim) return 0;

      const coveredAmount = totalCoveredAmount - getCopaymentAmount();

      const entitlement = getEntitledAmount(hasTopUp);

      let exceededAmount = 0;

      if (coveredAmount > entitlement) {
        exceededAmount = coveredAmount - entitlement;
      }

      return App.roundAmount(exceededAmount);
    }

    // Calculate entitled amount.

    function getEntitledAmount(hasTopUp) {
      if (!vm.claim) return 0;

      let entitledAmount =
        vm.topUpOption != null && (hasTopUp || vm.claim.isLateSubmission)
          ? Math.max(vm.topUpOption.employeeBalance, vm.claim.guaranteedAmount)
          : vm.claim.guaranteedAmount;

      const coveredAmount = vm.getCoveredAmount(vm.hasTopUpCalculation());
      if (entitledAmount > coveredAmount) entitledAmount = coveredAmount;

      return App.roundAmount(entitledAmount);
    }

    // Calculate the collect cash amount.

    function getCollectCashAmount(hasTopUp) {
      const uncoveredAmount = getTotalUncoveredAmount();
      const exceededAmount = getExceededAmount(getTotalCoveredAmount(), hasTopUp);
      const copaymentAmount = getCopaymentAmount();
      return uncoveredAmount + exceededAmount + copaymentAmount;
    }

    // Calculate top up amount.

    function getTopUpAmount() {
      const totalCoveredAmount = getTotalCoveredAmount() || 0;
      const initialGuaranteedAmount = vm.claim.guaranteedAmount;
      const topUpAMount = totalCoveredAmount - initialGuaranteedAmount;
      return App.roundAmount(Math.max(topUpAMount, 0));
    }

    // Calculate employee balance for top-up.

    function getEmployeeBalance() {
      const corporateBalance = vm.topUpOption ? vm.topUpOption.corporateBalance : 0;
      const employeeBalance = vm.topUpOption ? vm.topUpOption.employeeBalance : 0;
      return App.roundAmount(Math.min(corporateBalance, employeeBalance));
    }

    // Calculate final guaranteed amount.

    function getFinalGuaranteedAmount(limit, hasTopUp) {
      let guaranteedAmount = getCoveredAmount(hasTopUp) || 0;
      if (guaranteedAmount > limit) guaranteedAmount = limit;
      return App.roundAmount(guaranteedAmount);
    }

    function areFieldsValid() {
      let isValid = true;
      _.forEach(vm.ticket.items, (item) => {
        _.forEach(item.treatmentCategoryItems, (d) => {
          if (
            typeof d.coveredAmount === 'undefined' ||
            d.coveredAmount === null ||
            typeof d.uncoveredAmount === 'undefined' ||
            d.uncoveredAmount === null
          ) {
            isValid = false;
          }
        });
      });
      return isValid;
    }

    function isTotalBillAmountValid() {
      if (!areFieldsValid()) return false;

      const billAmount =
        vm.claim.requestType === 6 && vm.claim.adjustedFinalBillAmount
          ? vm.claim.adjustedFinalBillAmount
          : vm.claim.billAmount;

      return vm.getTotalBillAmount() === billAmount;
    }

    /* Approval Checklist */

    function showTopUpModeSelection() {
      if (vm.claim) {
        return (
          vm.showTopUpColumn &&
          getFinalGuaranteedAmount(getEmployeeBalance(), true) > vm.claim.guaranteedAmount &&
          !vm.claim.isLateSubmission &&
          vm.claim.requestType !== 6
        );
      }

      return false;
    }

    function isMinRequirement(target) {
      return _.some(approveMinRequirements, (d) => d === target);
    }

    function isPartialApprove() {
      let requireChecked = 0;
      _.each(vm.approveChecklistItems, (d) => {
        if (isMinRequirement(d.text) && d.isChecked) {
          requireChecked += 1;
        }
      });
      return approveMinRequirements.length === requireChecked;
    }

    function canFullApprove() {
      const cnt = _.filter(vm.approveChecklistItems, (d) => d.isChecked).length;
      return cnt === vm.approveChecklistItems.length;
    }

    function isActualVisitDateUpdated() {
      return (
        moment(vm.ticket.ticketDate).format('L') !== moment(vm.ticket.actualVisitDate).format('L')
      );
    }

    /* End of Approval Checklist */

    /* Medical certificate */

    function issueMc() {
      const firstDate = moment(vm.ticket.checkedInTime).startOf('day');
      vm.medicalCertificate = {
        startDate: firstDate,
        endDate: firstDate,
      };
      vm.isMcStartDateAlsoCheckedInDate = 1;
    }

    function removeMc() {
      vm.medicalCertificate = null;
    }

    function getMcDurationDescription() {
      if (vm.medicalCertificate) {
        const startDate = moment(vm.medicalCertificate.startDate);
        const endDate = moment(vm.medicalCertificate.endDate);
        const duration = endDate.diff(startDate, 'days') + 1;
        return App.localize('XDays', duration);
      }
      return '';
    }

    /* End of Medical certificate */

    function viewClaimPolicy() {
      $uibModal.open({
        templateUrl: require('./claimPolicy.modal.html'),
        controller: 'common.views.specialistClaim.processClaim.claimPolicyModal as vm',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          memberId() {
            return vm.patient.id;
          },
        },
      });
    }

    /* Calculation functions */
    function calcExceededAmount(amount) {
      if (!vm.claim) return 0;
      const coveredAmount = amount - calcCoPay(amount);
      return App.roundAmount(Math.max(coveredAmount - vm.claim.guaranteedAmount, 0));
    }

    function calcCoPay(amount) {
      if (!vm.claim || !vm.claim.coPayValue) return 0;
      let coPay = vm.claim.coPayValue;
      if (vm.claim.isCoPayPercentage) {
        coPay = (amount * vm.claim.coPayValue) / 100;
      } else {
        coPay = Math.min(amount, coPay);
      }
      return App.roundAmount(coPay);
    }

    function getCurrentClaimAmount() {
      return getTotalCoveredAmount();
    }

    function getCurrentUncoveredAmount() {
      return getTotalUncoveredAmount();
    }

    function getCurrentCoPay() {
      return getCopaymentAmount();
    }

    function getCurrentExceededAmount() {
      return calcExceededAmount(getTotalCoveredAmount());
    }

    function getCurrentTotalCoveredAmount() {
      if (!vm.claim) return 0;
      return App.roundAmount(vm.getTotalBillAmount() - vm.getCurrentCollectCashAmount());
    }

    function getCurrentCollectCashAmount() {
      if (!vm.claim) return 0;

      return App.roundAmount(
        vm.getCurrentCoPay() + vm.getCurrentUncoveredAmount() + vm.getNewExceededAmount()
      );
    }

    function getNewGlAmount() {
      if (!vm.showTopUpColumn && vm.claim.requestType !== 6) return 0;

      return App.roundAmount(
        vm.getTotalBillAmount() - vm.getCurrentCollectCashAmount() + vm.getNewTopUpAmount()
      );
    }

    function getNewExceededAmount() {
      let exceedAmount = 0;
      if (vm.claim) {
        exceedAmount =
          vm.getCurrentClaimAmount() - vm.getCurrentCoPay() - vm.claim.guaranteedAmount;
      }

      return App.roundAmount(Math.max(exceedAmount, 0));
    }

    function getTopUpExceededAmount() {
      const newGuaranteeAmount = vm.claim.guaranteedAmount + vm.getNewTopUpAmount();
      const exceededAmount = vm.getCurrentClaimAmount() - vm.getCurrentCoPay() - newGuaranteeAmount;
      return App.roundAmount(Math.max(exceededAmount, 0));
    }

    function getNewTopUpAmount() {
      const amt = vm.getCurrentClaimAmount() || 0;
      return App.roundAmount(
        Math.max(
          Math.min(
            amt - vm.getCurrentCoPay() - vm.claim.guaranteedAmount,
            vm.claim.panelExceededAmount
          ),
          0
        )
      );
    }

    /* End of calculation functions */

    function navigateToListing() {
      $state.go('postHospitalizationGuaranteeLetters');
    }

    function promptWarning(warning) {
      abp.message.error(App.localize(warning));
    }

    function preview() {
      window.open(vm.printUrl);
    }

    function saveDraft() {
      if (vm.saving) return;

      approve(false, false, '');
    }

    function openApprovalModal() {
      if (!App.isFormValid(vm.editForm)) {
        promptWarning('OneOrMoreInputValueIsInvalid');
        return;
      }

      if (!isPartialApprove()) {
        promptWarning('CheckApprovalChecklist');
      } else if (!vm.isEdit) {
        $uibModal
          .open({
            templateUrl: require('../../../modals/remarks/remarks.modal.html'),
            controller: 'common.views.modals.remarksModal as vm',
            backdrop: 'static',
            resolve: {
              title() {
                if (vm.topUpMode === 2)
                  return App.localize('RejectTopUpAndApproveXConfirmMessage', vm.claimNumber);

                if (canFullApprove()) return App.localize('ApprovalConfirmation');

                return App.localize('PartialApprovalConfirmation');
              },
              existingRemarks() {
                return vm.escalationRemarks;
              },
              remarksMaxLength() {
                return appConstants.serviceRequestMaxLength;
              },
            },
          })
          .result.then((remarks) => {
            approve(true, canFullApprove(), remarks);
          });
      } else {
        promptWarning('SaveOrCancelChangesNarrative');
      }
    }

    function approve(isApprove, isFullApprove, internalRemarks) {
      if (vm.saving) return;

      let documents = [];
      if (vm.claim.supportingDocuments) {
        documents = _.map(vm.claim.supportingDocuments, (d) => ({
          externalObjectId: d.externalObjectId,
          applied: d.applied,
        }));
      }

      let approvalChecklistItems = [];
      approvalChecklistItems = _.map(vm.approveChecklistItems, (i) => ({
        text: i.text,
        isSelected: i.isChecked,
        isMandatory: i.required,
      }));

      const billAmount =
        vm.claim.requestType === 6 && vm.claim.adjustedFinalBillAmount
          ? vm.claim.adjustedFinalBillAmount
          : vm.claim.billAmount;

      const input = {
        id: vm.claim.serviceRequestId,
        isApprove,
        isFullApprove,
        letterNumber: vm.letterNumber,
        actualVisitDate: vm.ticket.actualVisitDate,
        remarks: vm.glRemarks,
        timelineRemarks: vm.timelineRemarks,
        internalRemarks,
        request: {
          finalBillAmount: billAmount,
          ticketItems: vm.ticket.items,
          documents,
          approvalChecklistItems,
          medicalCertificate: vm.medicalCertificate,
          topUpRequest: null,
        },
        isLateClaimSubmission: vm.claim.isLateSubmission,
      };

      if (vm.showTopUpModeSelection()) {
        input.topUpRequest = {
          topUpIfPossible: vm.topUpMode === 1,
        };
      }

      vm.saving += 1;
      hostServiceRequestSvc
        .processPostHospitalizationClaimRequest(input)
        .success(() => {
          if (isApprove) {
            successApproveModal(input.scheduleApproval, isFullApprove);
          } else {
            abp.notify.success(App.localize('SaveDraft'));
            navigateToListing();
          }
        })
        .error((data) => {
          /* Error code 1 == Insufficient allowance */
          if (data.code === 1) {
            /* Insufficient employee balance for request amount */
            swal(
              {
                title: App.localize('InvalidAmount'),
                text: `${App.localize('InsufficientEmployeeBalanceNarrative')}\n${App.localize(
                  'RefreshThePageAndEditTheAmount'
                )}`,
                confirmButtonColor: '#1AB394',
                confirmButtonText: App.localize('RefreshNow'),
              },
              (isConfirm) => {
                if (isConfirm) {
                  $window.reload();
                }
              }
            );
          }
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function successApproveModal(hasScheduleApproval, isFullApproval) {
      let titleText;
      if (hasScheduleApproval) titleText = App.localize('SuccessfullyScheduled');
      else if (isFullApproval) titleText = App.localize('SuccessfullyApproved');
      else titleText = App.localize('SuccessfullyPartialApproved');

      const claimNumberHtml = `<strong>${vm.claimNumber}</strong>`;

      let bodyText;
      if (!hasScheduleApproval) bodyText = App.localize('ClaimApprovedNarrative');
      else if (isFullApproval)
        bodyText = App.localize('ClaimScheduledFullApproveNarrative', claimNumberHtml);
      else bodyText = App.localize('ClaimScheduledPartialApproveNarrative', claimNumberHtml);

      swal(
        {
          title: `${titleText}!`,
          text: bodyText,
          type: 'success',
          showCancelButton: true,
          closeOnConfirm: false,
          confirmButtonColor: '#1ab394',
          confirmButtonText: App.localize('ViewClaim'),
          cancelButtonText: App.localize('ReturnToClaimsListing'),
          html: true,
        },
        // eslint-disable-next-line prefer-arrow-callback, func-names
        function (isViewClaim) {
          if (isViewClaim) {
            $state.go('postHospitalizationClaimDetail', {
              claimNumber: vm.claimNumber,
              timeZoneId: vm.timeZoneId,
            });
          } else {
            $state.go('host.inpatientClaims');
          }
          swal.close();
        }
      );
    }

    function openRejectionModal() {
      if (!vm.isEdit) {
        $uibModal
          .open({
            templateUrl: require('../../../modals/remarks/remarks.modal.html'),
            controller: 'common.views.modals.remarksModal as vm',
            backdrop: 'static',
            resolve: {
              title() {
                return App.localize('RejectionConfirmation');
              },
              existingRemarks() {
                return vm.escalationRemarks;
              },
              remarksMaxLength() {
                return appConstants.serviceRequestMaxLength;
              },
            },
          })
          .result.then((remarks) => {
            reject(remarks);
          });
      } else {
        promptWarning('SaveOrCancelChangesNarrative');
      }
    }

    function reject(remarks) {
      if (vm.saving) return;

      const input = {
        id: vm.claim.serviceRequestId,
        remarks,
      };

      vm.saving += 1;
      hostServiceRequestSvc
        .rejectRequest(input)
        .success(() => {
          showSuccessRejectModal();
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function showSuccessRejectModal() {
      swal(
        {
          title: `${App.localize('SuccessfullyRejected')}!`,
          text: App.localize('SuccessfullyRejectedSpecialistClaimNarrative'),
          html: true,
          type: 'success',
          confirmButtonColor: '#23c6c8',
          confirmButtonText: App.localize('ViewClaim'),
          cancelButtonText: App.localize('ReturnToClaimList'),
          showCancelButton: true,
          closeOnConfirm: false,
        },
        (claimDetail) => {
          swal.close();

          if (claimDetail) {
            $state.go('postHospitalizationClaimDetail', {
              claimNumber: vm.claimNumber,
              timeZoneId: vm.timeZoneId,
            });
          } else {
            $state.go('p');
          }
        }
      );
    }

    function getServiceRequestClaimProcessors(_callback) {
      vm.loading += 1;

      commonLookupSvc
        .getServiceRequestClaimProcessor()
        .success((data) => {
          // Filter away current user
          delegateUsers = _.remove(
            data.items,
            (n) => n.value.operatorUserId !== abp.session.userId
          );
        })
        .finally(() => {
          vm.loading -= 1;
          _callback();
        });
    }

    function openDelegationModal() {
      getServiceRequestClaimProcessors(() => {
        if (!vm.isEdit) {
          $uibModal
            .open({
              templateUrl: require('../../../modals/notifyRecipient/notifyRecipient.modal.html'),
              controller: 'common.views.modals.notifyRecipientModal as vm',
              backdrop: 'static',
              resolve: {
                users() {
                  return delegateUsers;
                },
                title() {
                  return App.localize('DelegationConfirmation');
                },
                message() {
                  return App.localize('DelegationWarningMessage');
                },
              },
            })
            .result.then((result) => {
              delegate(result.recipient, result.remarks);
            });
        } else {
          promptWarning('SaveOrCancelChangesNarrative');
        }
      });
    }

    function delegate(recipient, remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .delegateRequest({
          processorUserId: recipient.operatorUserId,
          serviceRequestId: vm.claim.serviceRequestId,
          letterNumber: vm.letterNumber,
          emailAddress: recipient.operatorEmailAddress,
          remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('ServiceRequestDelegated'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getServiceRequestOverridingApprovers(_callback) {
      vm.loading += 1;

      commonLookupSvc
        .getServiceRequestOverridingApprover()
        .success((data) => {
          // Filter away current user
          recommendUsers = _.remove(
            data.items,
            (n) => n.value.operatorUserId !== abp.session.userId
          );
        })
        .finally(() => {
          vm.loading -= 1;
          _callback();
        });
    }

    function openRecommendationModal() {
      getServiceRequestOverridingApprovers(() => {
        if (!vm.isEdit) {
          $uibModal
            .open({
              templateUrl: require('../../../modals/notifyRecipient/notifyRecipient.modal.html'),
              controller: 'common.views.modals.notifyRecipientModal as vm',
              backdrop: 'static',
              resolve: {
                users() {
                  return recommendUsers;
                },
                title() {
                  return App.localize('RecommendationConfirmation');
                },
                message() {
                  return App.localize('RecommendationWarningMessage');
                },
              },
            })
            .result.then((result) => {
              recommend(result.recipient, result.remarks);
            });
        } else {
          promptWarning('SaveOrCancelChangesNarrative');
        }
      });
    }

    function recommend(recipient, remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .recommendRequest({
          processorUserId: recipient.operatorUserId,
          serviceRequestId: vm.serviceRequestId,
          letterNumber: vm.guaranteeLetterNumber,
          emailAddress: recipient.operatorEmailAddress,
          remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('ServiceRequestRecommended'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function openEscalationModal() {
      if (!vm.isEdit) {
        $uibModal
          .open({
            templateUrl: require('../../../modals/remarks/remarks.modal.html'),
            controller: 'common.views.modals.remarksModal as vm',
            backdrop: 'static',
            resolve: {
              title() {
                return App.localize('EscalationConfirmation');
              },
              existingRemarks() {
                return vm.claim.serviceRequestRemark;
              },
              remarksMaxLength() {
                return appConstants.serviceRequestMaxLength;
              },
            },
          })
          .result.then((remarks) => {
            escalate(remarks);
          });
      } else {
        promptWarning('SaveOrCancelChangesNarrative');
      }
    }

    function escalate(remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .escalateGuaranteeLetterRequest({
          id: vm.claim.serviceRequestId,
          isResolve: true,
          escalationRemark: remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('GlEscalated'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function query() {
      if (vm.claim.hasUnresolvedQuery) {
        $state.go('postHospitalizationClaimDetail', {
          claimNumber: vm.claimNumber,
          letterNumber: vm.letterNumber,
          timeZoneId: vm.timeZoneId,
          activeTab: 3,
        });
      } else {
        $state.go('createPostHospitalizationQuery', {
          letterNumber: vm.letterNumber,
          timeZoneId: vm.timeZoneId,
        });
      }
    }
  }
})();
